import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Input, message } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../axios';
import jwt from 'jsonwebtoken';
import Context from '../../context';
import { RoutePaths, TenantProperties } from '../../constants';
import * as config from '../../config';
import { getPagesRolesLinksByRole, passwordReset, forgotPassword } from '../../services';
import { Footer } from '../../shared';
import { findFirstPermissionedRoute } from '../../utils';
import s from './index.module.less';

const Login = () => {
  const history = useHistory();
  const params = useParams();
  const [context, setContext] = useContext(Context);
  const [resetPassword, setResetPassword] = useState(context.isReset);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    context.isAuth && history.push(findFirstPermissionedRoute(context.pagePermissions));
    if (params.id === '1') {
      message.warning('Session expired, please login again.');
    }
    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    setContext({
      ...context,
      isLoading: true,
    });
    axios
      .post('/user/login', values)
      .then(async (res) => {
        if (res.data.resetPassword) {
          setContext({
            ...context,
            isLoading: false,
            isAuth: false,
          });
          setResetPassword(true);
        }
        const pagePermissions = [];
        const getPermissions = async () => {
          for (const role of res.data.roles) {
            const resPage = await getPagesRolesLinksByRole(role);
            pagePermissions.push(...resPage.data.pagePermissions);
          }
        };
        localStorage.setItem('token', res.data.token);
        localStorage.setItem(
          'profile',
          JSON.stringify({
            username: res.data.userId,
            tenantId: res.data.tenantId,
            roles: res.data.roles,
          })
        );
        await getPermissions();
        localStorage.setItem('pagePermissions', JSON.stringify(pagePermissions));
        const decode = jwt.decode(res.data.token);
        setContext((state) => {
          return {
            ...state,
            isAuth: !res.data.resetPassword,
            token: decode,
            profile: { username: res.data.userId, tenantId: res.data.tenantId, roles: res.data.roles },
            pagePermissions,
            isLoading: false,
          };
        });
        !res.data.resetPassword
          ? history.push(findFirstPermissionedRoute(pagePermissions))
          : history.push(RoutePaths.LOGIN);
      })
      .catch((e) => {
        console.log(e);
        setContext({
          ...context,
          isLoading: false,
        });
        message.error('Failed to login, please check your credentials.');
      });
  };

  const submitResetForm = async (val) => {
    const values = { userId: val.username, oldPassword: val.password, password: val.newPassword };
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    try {
      await passwordReset(values);
      await message.success('Password has been changed successfully!');
    } catch (e) {
      console.log(e);
      message.error('Unable to reset password, try again later');
    } finally {
      setResetPassword(false);
      form.resetFields();
      setContext({
        ...context,
        isLoading: false,
      });
    }
  };

  const onClickForgotPassword = async (val) => {
    const values = { userId: val.username, emailId: val.emailId };
    setContext({
      ...context,
      isLoading: true,
    });
    try {
      await forgotPassword(values);
      await message.success('Password has sent to Email Id');
      setForgotPasswordForm(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to send password, try again later');
    } finally {
      form.resetFields();
      setContext({
        ...context,
        isLoading: false,
      });
    }
  };

  const DefaultLogin = () => {
    const getLogoDetails = () => {
      const tenantProperties = TenantProperties[config.REACT_APP_CUSTOM_HOST];
      let details = { url: `/${tenantProperties.logo}`, alt: TenantProperties.alt };
      return details;
    };

    return (
      <>
        <div className={s.particleContainer}>
          {[...Array(100)].map((_, index) => (
            <div key={index} className={s.particle}></div>
          ))}
        </div>
        <Row className={s.loginContainer} justify="center" align="middle">
          <Col
            xs={{ span: 20, offset: 0 }}
            sm={{ span: 18, offset: 0 }}
            md={{ span: 15, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            className={context.isCompact && s.loginForm}
          >
            <div className={s.logoContainer}>
              <img src={getLogoDetails()?.url} alt={getLogoDetails()?.alt} style={{ maxWidth: 300 }} />
            </div>
            <Form
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 13 }}
              form={form}
              initialValues={{ remember: true }}
              onFinish={forgotPasswordForm ? onClickForgotPassword : resetPassword ? submitResetForm : onFinish}
              autoComplete="off"
            >
              {/* To stop browser from auto filling */}
              <input autoComplete={'new-password'} style={{ display: 'none' }} />
              <input autoComplete={'new-password'} type="password" style={{ display: 'none' }} />
              <input autoComplete={'new-password'} type="newPassword" style={{ display: 'none' }} />
              <Form.Item
                hasFeedback
                label="Username"
                name="username"
                initialValue={context.profile.username}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: `Please input your Username!`,
                    min: 4,
                    max: 200,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {!forgotPasswordForm && (
                <Form.Item
                  hasFeedback
                  label={resetPassword ? 'Old Password' : 'Password'}
                  name="password"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: `Please input your ${resetPassword ? 'Old Password' : 'Password'}!`,
                      min: 4,
                      max: 200,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              )}
              {resetPassword && (
                <Form.Item
                  hasFeedback
                  label="New Password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input your old password!',
                      min: 4,
                      max: 200,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              )}
              {forgotPasswordForm && (
                <Form.Item
                  hasFeedback
                  label="Email ID"
                  name="emailId"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: `Please input your Email ID!`,
                      min: 4,
                      max: 200,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Row>
                <Col xs={{ offset: 0 }} sm={{ offset: 6 }} md={{ offset: 6 }} lg={{ offset: 6 }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  {(resetPassword || forgotPasswordForm) && (
                    <Button
                      type="link"
                      onClick={() => {
                        setResetPassword(false);
                        setForgotPasswordForm(false);
                      }}
                    >
                      Login
                    </Button>
                  )}
                </Col>
                <Col>
                  {!forgotPasswordForm && !resetPassword && (
                    <Button
                      type="link"
                      onClick={() => {
                        setForgotPasswordForm(true);
                      }}
                    >
                      Forgot Password
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className={s.footerContainer}>
          <Footer />
        </Row>
      </>
    );
  };

  const TaggitLogin = () => {
    return (
      <Row
        justify="center"
        align="middle"
        className={`${context.isCompact ? s.taggitLoginPageBg : s.taggitLoginMainContainer} promptFontFamily`}
      >
        <Col xs={24} md={20} lg={22} xl={18}>
          <Row justify="center" align="middle">
            <Col xs={24} md={20} lg={22} xl={20}>
              <div className={`${s.taggitLoginPage} p-2`}>
                <Row justify="center" align="middle">
                  <Col lg={10}>
                    <Row justify="center" align="middle">
                      <Col span={20}>
                        <img src="/taggit.svg" alt="taggit-logo" className={`${s.taggitLogo} pb-3`} />
                      </Col>
                      <Col span={20}>
                        <h2>
                          <strong>Sign in </strong>
                        </h2>
                        <h4 className="promptFontFamily">to access your app</h4>
                      </Col>
                      <Col span={20}>
                        <Form
                          name="basic"
                          className={`${s.taggitForm} promptFontFamily`}
                          form={form}
                          initialValues={{ remember: false }}
                          onFinish={
                            forgotPasswordForm ? onClickForgotPassword : resetPassword ? submitResetForm : onFinish
                          }
                          autoComplete="off"
                        >
                          {/* To stop browser from auto filling */}
                          <input autoComplete={'new-password'} style={{ display: 'none' }} />
                          <input autoComplete={'new-password'} type="password" style={{ display: 'none' }} />
                          <input autoComplete={'new-password'} type="newPassword" style={{ display: 'none' }} />
                          <Form.Item
                            hasFeedback
                            name="username"
                            initialValue={context.profile.username}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: `Please input your Username!`,
                                min: 4,
                                max: 200,
                              },
                            ]}
                          >
                            <Input placeholder="User Name" className={`${s.formField} promptFontFamily`} />
                          </Form.Item>
                          {!forgotPasswordForm && (
                            <Form.Item
                              hasFeedback
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: 'Please input your new password!',
                                  min: 4,
                                  max: 200,
                                },
                              ]}
                            >
                              <Input.Password
                                placeholder={resetPassword ? 'Old Password' : 'Password'}
                                className={s.formField}
                              />
                            </Form.Item>
                          )}
                          {resetPassword && (
                            <Form.Item
                              hasFeedback
                              name="newPassword"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `Please input your ${resetPassword ? 'New Password' : 'Password'}!`,
                                  min: 4,
                                  max: 200,
                                },
                              ]}
                            >
                              <Input.Password
                                placeholder={resetPassword ? 'New Password' : 'Password'}
                                className={`${s.formField} promptFontFamily`}
                              />
                            </Form.Item>
                          )}
                          {forgotPasswordForm && (
                            <Form.Item
                              hasFeedback
                              label="Email ID"
                              name="emailId"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `Please input your Email ID!`,
                                  min: 4,
                                  max: 200,
                                },
                              ]}
                            >
                              <Input placeholder="Email" className={s.formField} />
                            </Form.Item>
                          )}

                          <Row justify="space-between">
                            <Col>
                              <Form.Item>
                                <Button htmlType="submit" className={`${s.taggitSignInBtn} promptFontFamily mb-2`}>
                                  Sign In
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              {(resetPassword || forgotPasswordForm) && (
                                <Button
                                  type="link"
                                  onClick={() => {
                                    setResetPassword(false);
                                    setForgotPasswordForm(false);
                                  }}
                                >
                                  Login
                                </Button>
                              )}
                            </Col>

                            <Col>
                              {!forgotPasswordForm && !resetPassword && (
                                <Button
                                  className={`${s.taggitForgotPassword} promptFontFamily`}
                                  type="link"
                                  onClick={() => {
                                    setForgotPasswordForm(true);
                                  }}
                                >
                                  Forgot Password?
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col className={`${context.isCompact ? '' : s.taggitDisc} promptFontFamily  m-lg-5 `}></Col>
                  <Col lg={10}>
                    <Row justify="center" align="middle">
                      <Col>
                        <h4 className={`${s.taggitDiscLine} promptFontFamily`}>Track your Workforce,</h4>
                        <h4 className={`${s.taggitDiscLine} promptFontFamily`}>
                          Assets, Employee attendance realtime with
                        </h4>
                        <h4 className={`${s.taggitDiscTitle} promptFontFamily`}>
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          TAGGIT <span className={s.taggitCaption}>"THINGS"</span>
                        </h4>
                        <img src="/taggit-1.png" alt="taggit-logo" className={`${s.taggitImg} pb-5`} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Row justify="space-evenly" align="middle" className={`${s.taggitFooterContainer} promptFontFamily`}>
          <Col className={`${s.taggitFooterDisc}`}>
            Powered by <img src="/logo.png" alt="spidex-logo" width={80}></img>
          </Col>
          <Col className={`${s.taggitFooterDisc} promptFontFamily`}>
            © {new Date().getFullYear()} ATN Global Networks Pvt. Ltd. All Rights Reserved.
          </Col>
          <Col className={`${s.taggitFooterDisc} promptFontFamily`}>
            <Link to="/privacy" className={s.footerLinText}>
              Privacy Policy
            </Link>
          </Col>
          <Col className={`${s.taggitFooterDisc} promptFontFamily`}>
            <Link to="/privacy" className={s.footerLinText}>
              Cookie Statement
            </Link>
          </Col>
        </Row>
      </Row>
    );
  };

  const renderLogin = () => {
    let login = <DefaultLogin />;
    if (config.REACT_APP_CUSTOM_HOST === 'taggit') {
      login = <TaggitLogin />;
    }
    return login;
  };

  return <div>{renderLogin()}</div>;
};

export default Login;
