import jwt from 'jsonwebtoken';

//When login is not working , using the dummy permissions
const localToken = localStorage.getItem('token');

export const dummyInitialData = {
  isAuth: localToken ? true : false,
  isLoading: false,
  isReset: false,
  pagePermissions: [{ pageName: 'vehiclediagnostics-campaign', permissions: ['add', 'view', 'update', 'delete'] }],
  token: localToken ? jwt.decode(localToken) : {},
};
