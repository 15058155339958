import axios from '../axios';

const BRANCH_URI = '/branch/';
const LOCATION_URI = '/location/';
const AREA_URI = '/area/';
const TAG_URI = '/tag/';
const TGASSET_TAG_URI = '/tags/';
const ASSET_URI = '/asset/';
const TGASSET_ASSET_URI = '/assets/';
const TAGGED_ASSET_URI = '/tgasset/';
const GATEWAY_URI = '/gateway/';
const GATEWAY_TRANSIT_URI = '/transit/';

export const getAllBranches = (tenantId) => {
  return axios.get(`${BRANCH_URI}all`, { params: { tenantId } });
};

export const addBranch = (branch) => {
  return axios.post(`${BRANCH_URI}`, branch);
};

export const updateBranch = (branch) => {
  return axios.put(`${BRANCH_URI}`, branch);
};

export const deleteBranch = (branchId) => {
  return axios.delete(`${BRANCH_URI}${branchId}`);
};

export const getAllLocations = (tenantId) => {
  return axios.get(`${LOCATION_URI}tId/`, { params: { tenantId } });
};

export const addLocation = (location) => {
  return axios.post(`${LOCATION_URI}`, location);
};

export const updateLocation = (location) => {
  return axios.put(`${LOCATION_URI}`, location);
};

export const deleteLocation = (locationId) => {
  return axios.delete(`${LOCATION_URI}${locationId}`);
};

export const getAllAreas = (tenantId) => {
  return axios.get(`${AREA_URI}tId/`, { params: { tenantId } });
};

export const addArea = (area) => {
  return axios.post(`${AREA_URI}`, area);
};

export const updateArea = (area) => {
  return axios.put(`${AREA_URI}`, area);
};

export const deleteArea = (areaId) => {
  return axios.delete(`${AREA_URI}${areaId}`);
};

export const getAllTags = (tenantId) => {
  return axios.get(`${TAG_URI}all/`, { params: { tenantId } });
};

export const getAllTagsByPagination = (tenantId, params) => {
  return axios.get(`${TAG_URI}allby`, { params: { tenantId, ...params } });
};

export const addTag = (tag) => {
  return axios.post(`${TAG_URI}`, tag);
};

export const addAllTags = (tag) => {
  return axios.post(`${TAG_URI}all`, tag);
};

export const updateTag = (tag) => {
  return axios.put(`${TAG_URI}`, tag);
};

export const deleteTag = (tagId) => {
  return axios.delete(`${TAG_URI}${tagId}`);
};

export const getAllAssets = (tenantId) => {
  return axios.get(`${ASSET_URI}all`, { params: { tenantId } });
};

export const getAllAssetsByPagination = (tenantId, params) => {
  return axios.get(`${ASSET_URI}allby`, { params: { tenantId, ...params } });
};

export const addAsset = (asset) => {
  return axios.post(`${ASSET_URI}`, asset);
};

export const updateAsset = (asset) => {
  return axios.put(`${ASSET_URI}`, asset);
};

export const deleteAsset = (assetId) => {
  return axios.delete(`${ASSET_URI}${assetId}`);
};

export const getAssetInfo = (assetExternalId) => {
  return axios.get(`${ASSET_URI}${assetExternalId}`);
};

export const getAllTaggedAssets = (tenantId) => {
  return axios.get(`${TAGGED_ASSET_URI}all`, { params: { tenantId } });
};

export const getAllTaggedAssetsByPagination = (tenantId, params) => {
  return axios.get(`${TAGGED_ASSET_URI}allby`, { params: { tenantId, ...params } });
};

export const addTaggedAsset = (taggedAsset) => {
  return axios.post(`${TAGGED_ASSET_URI}`, taggedAsset);
};

export const updateTaggedAsset = (taggedAsset) => {
  return axios.put(`${TAGGED_ASSET_URI}`, taggedAsset);
};

export const deleteTaggedAsset = (taggedAssetId) => {
  return axios.delete(`${TAGGED_ASSET_URI}${taggedAssetId}`);
};

export const getAllGateways = (tenantId) => {
  return axios.get(`${GATEWAY_URI}all`, { params: { tenantId } });
};

export const addGateway = (gateway) => {
  return axios.post(`${GATEWAY_URI}`, gateway);
};

export const updateGateway = (gateway) => {
  return axios.put(`${GATEWAY_URI}`, gateway);
};

export const deleteGateway = (gatewayId) => {
  return axios.delete(`${GATEWAY_URI}${gatewayId}`);
};

export const getAllTransitGateways = (tenantId) => {
  return axios.get(`${GATEWAY_TRANSIT_URI}all/${tenantId}/`);
};

export const addTransitGateway = (transitGateway) => {
  return axios.post(`${GATEWAY_TRANSIT_URI}`, transitGateway);
};

export const updateTransitGateway = (transitGateway) => {
  return axios.put(`${GATEWAY_TRANSIT_URI}`, transitGateway);
};

export const deleteTransitGateway = (gatewayId) => {
  return axios.delete(`${GATEWAY_TRANSIT_URI}${gatewayId}`);
};

export const getAllTgassetAssets = (tenantId) => {
  return axios.get(`/tgasset${TGASSET_ASSET_URI}`, { params: { tenantId } });
};

export const getAllTgassetTags = (tenantId) => {
  return axios.get(`/tgasset${TGASSET_TAG_URI}`, { params: { tenantId } });
};
