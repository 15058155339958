import React, { useContext } from 'react';
import { List, Skeleton, Popover, Button } from '../../components';
import Context from '../../context';
import { BellOutlined } from '@ant-design/icons';
import s from './index.module.less';

const Notifications = () => {
  // eslint-disable-next-line
  const [context, _] = useContext(Context);

  const tempList = [
    {
      title: 'Example Notification 1',
      description: 'Description regarding the notification',
      actions: [{ name: 'Visit', link: '/' }],
    },
    {
      title: 'Example Notification 2',
      description: 'Description regarding the notification',
      actions: [{ name: 'Take Action', link: '/' }],
    },
    {
      title: 'Example Notification 3',
      description: 'Description regarding the notification',
      actions: [{ name: 'Visit', link: '/' }],
    },
  ];
  return (
    <div className={s.notificationsContainer}>
      <Popover
        trigger={['click']}
        title="Notifications"
        content={
          <List
            itemLayout="horizontal"
            dataSource={tempList}
            renderItem={(item) => (
              <List.Item
                actions={
                  context.isCompact
                    ? []
                    : [
                        ...item.actions.map((action) => (
                          <a href={action.link} key={action.name}>
                            {action.name}
                          </a>
                        )),
                        <a href={'/'} key="mark">
                          Mark as Read
                        </a>,
                      ]
                }
              >
                <Skeleton title={false} loading={false} active>
                  <List.Item.Meta
                    title={item.title}
                    description={
                      <>
                        {context.isCompact ? (
                          <>
                            {item.description}
                            <div>
                              {item.actions.map((action) => (
                                <a href={action.link} key={action.name}>
                                  {action.name}
                                </a>
                              ))}{' '}
                              <span style={{ padding: '0 5px 0 5px' }}>|</span>
                              <a href={'/'} key="mark">
                                Mark as Read
                              </a>
                            </div>
                          </>
                        ) : (
                          <>{item.description}</>
                        )}
                      </>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        }
        placement="bottom"
      >
        <Button type="link" icon={<BellOutlined />} />
      </Popover>
    </div>
  );
};

export default Notifications;
