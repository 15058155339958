import axios from '../axios';

const USER_URI = '/user/';

export const getAllAccounts = (pageNumber = 1, size = 100) => {
  return axios.get(`${USER_URI}all`, { params: { pageNumber, size } });
};

export const getAccount = (userId) => {
  return axios.get(`${USER_URI}${userId}`);
};

export const addAccount = (account) => {
  return axios.post(`${USER_URI}create`, account);
};

export const updateAccount = (account) => {
  return axios.put(`${USER_URI}`, account);
};

export const deleteAccount = (userId) => {
  return axios.delete(`${USER_URI}${userId}`);
};

export const passwordReset = (values) => {
  return axios.put(`${USER_URI}reset`, values);
};

export const forgotPassword = (values) => {
  return axios.post(`${USER_URI}forgotPassword`, values);
};
