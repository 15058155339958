import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import Context from '../../../context';
import { DateFormats, ModuleNames, Pages } from '../../../constants';
import { BreadcrumbList } from '../../../shared';
import { Spin, Table, Statistic, Row, Col, message } from '../../../components';
import { getRfidReport, getVendorsCount, getWorkersCount, getVehiclesCount } from '../../../services';
import { get } from 'lodash';
import s from './index.module.less';

const AssetDashboard = () => {
  const [context, setContext] = useContext(Context);
  const [reportData, setReportData] = useState([]);
  const [counts, setCounts] = useState({ vendors: 0, vehicles: 0, workers: 0 });

  useEffect(() => {
    const timeInterval = moment.duration('00:02:00');
    const initInterval = setInterval(() => {
      init(moment().subtract(timeInterval).format(DateFormats.TYPE_1), moment().format(DateFormats.TYPE_1));
    }, 120000);
    return () => {
      clearInterval(initInterval);
    };
    //eslint-disable-next-line
  }, []);

  const timeReduction = moment.duration('04:00:00');
  const init = async (
    fromDate = moment().subtract(timeReduction).format(DateFormats.TYPE_1),
    toDate = moment().format(DateFormats.TYPE_1)
  ) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    try {
      const rfidRes = await getRfidReport(context.profile.tenantId, fromDate, toDate);
      const tempRes = rfidRes.data.map((x) => ({
        ...x,
        epoch: x.epoch,
      }));
      setReportData((state) => [...state, ...tempRes]);
      message.info('Updated report');
    } catch (e) {
      console.log(e);
      message.error('Unable to get report');
    } finally {
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
    }
    getVehiclesCount(context.profile.tenantId)
      .then((res) => {
        setCounts((state) => {
          return {
            ...state,
            vehicles: res.data.count,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Unable to get Vehicles count');
      });
    getWorkersCount(context.profile.tenantId)
      .then((res) => {
        setCounts((state) => {
          return {
            ...state,
            workers: res.data.count,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Unable to get Workers count');
      });
    getVendorsCount(context.profile.tenantId)
      .then((res) => {
        setCounts((state) => {
          return {
            ...state,
            vendors: res.data.count,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Unable to get Vendors count');
      });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const tableCols = [
    { title: <strong> Worker Name </strong>, dataIndex: 'assetName', key: 'assetName' },
    { title: <strong> Tag ID </strong>, dataIndex: 'deviceId', key: 'deviceId' },
    { title: <strong> Vendor Name </strong>, dataIndex: 'vendorName', key: 'vendorName' },
    { title: <strong> Time </strong>, dataIndex: 'epoch', key: 'epoch' },
    { title: <strong> Mode </strong>, dataIndex: 'antennaMode', key: 'antennaMode' },
  ];

  const peopleTrackingBreadcrumbsName = get(
    context.tenantProfile,
    `dashboardNames[${Pages.PEOPLE_TRACKING_DASHBOARD}].displayName`,
    ModuleNames.PEOPLE_TRACKING
  );

  return (
    <Spin spinning={context.isLoading}>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={[peopleTrackingBreadcrumbsName]} />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <h1 className={s.reportHeading}>
            <strong> {context.tenantProfile?.reportHeadingText || 'Report'}</strong>
          </h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <h3>{context.tenantProfile?.reportSubHeadingText || ''}</h3>
        </Col>
      </Row>
      <Row justify="end">
        <Col className={s.stat}>
          <Statistic
            className={s.customStatisticFontSize}
            title="Vendors"
            value={counts.vendors}
            prefix={<img src="/assets/icons/vendor.svg" alt="vendor" className={s.statisticSvg} />}
          />
        </Col>
        <Col className={s.stat}>
          <Statistic
            className={s.customStatisticFontSize}
            title="Workers"
            value={counts.workers}
            prefix={<img src="/assets/icons/worker.svg" alt="worker" className={s.statisticSvg} />}
          />
        </Col>
        <Col className={s.stat}>
          <Statistic
            className={s.customStatisticFontSize}
            title="Vehicles"
            value={counts.vehicles}
            prefix={<img src="/assets/icons/vehicle.svg" alt="vehicle" className={s.statisticSvg} />}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className={s.tableDiv}>
          <Table
            size="small"
            scroll={{ x: true }}
            rowKey="id"
            loading={context.isLoading}
            columns={tableCols}
            dataSource={reportData}
            rowClassName={(record) => record.deleted && 'rowInactive'}
            pagination={false}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default AssetDashboard;
