import axios from '../axios';

const TENANT_URI = '/tenant/';

export const getTenant = (tenantId) => {
  return axios.get(`${TENANT_URI}${tenantId}`, { data: {}, params: {} });
};

export const getAllTenants = (pageNumber = 1, size = 100) => {
  return axios.get(`${TENANT_URI}all`, { params: { pageNumber, size } });
};

export const addTenant = (tenant) => {
  return axios.post(`${TENANT_URI}`, tenant);
};

export const updateTenant = (tenant) => {
  return axios.put(`${TENANT_URI}`, tenant);
};

export const deleteTenant = (tenantId) => {
  return axios.delete(`${TENANT_URI}${tenantId}`);
};
