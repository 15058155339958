import { useContext } from 'react';
import { Breadcrumb, Tag } from '../../components';
import Context from '.././../context';
import * as config from '../../config';

const BreadcrumbList = ({ list }) => {
  const [context] = useContext(Context);

  if (context?.tenantProfile?.breadcrumbsVisible === false) {
    return <></>;
  }

  return (
    <>
      {context.isCompact ? (
        <Breadcrumb separator=">">
          {list
            .filter((_x, index) => index === list.length - 1)
            .map((i) => (
              <Breadcrumb.Item key={i}>
                <Tag color={context.tenantProfile?.buttonColor || config.REACT_APP_THEME_PRIMARY_COLOR}>{i}</Tag>
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
      ) : (
        <Breadcrumb separator=">">
          {list.map((i, index) => (
            <Breadcrumb.Item key={i}>
              {list.length - 1 === index ? (
                <Tag color={context.tenantProfile?.buttonColor || config.REACT_APP_THEME_PRIMARY_COLOR}>{i}</Tag>
              ) : (
                <>{i}</>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </>
  );
};

export default BreadcrumbList;
