import { Col, Row } from '../../../components';
import React from 'react';
import { BreadcrumbList } from '../../../shared';
import { ModuleNames } from '../../../constants';

const Reports = () => {
  return (
    <Row justify="start">
      <Col>
        <BreadcrumbList list={[ModuleNames.REPORT]} />
      </Col>
    </Row>
  );
};

export default Reports;
