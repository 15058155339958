import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  LeftOutlined,
  SelectOutlined,
  RightOutlined,
  SearchOutlined,
  EyeTwoTone,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import {
  Button,
  Tag,
  Card,
  Input,
  Table,
  Spin,
  Tabs,
  Form,
  Select,
  Statistic,
  Checkbox,
  Tooltip,
  Col,
  Row,
  message,
  Radio,
  Timeline,
  Collapse,
  DatePicker,
  Badge,
} from '../../components';
import Context from '../../context';
import {
  getAllAreas,
  getAllGateways,
  getAllLocations,
  getAllTaggedAssets,
  getTempSensorInitialData,
  getHumiditySensorInitialData,
  getAmblightSensorInitialData,
  getPressureSensorInitialData,
  getSensorInitialData,
  getGatewaySensorInitialData,
  getTempSensorMetrics,
  getHumiditySensorMetrics,
  getAmblightSensorMetrics,
  getPressureSensorMetrics,
  getSensorProximityByDate,
  getSensorProximityInitialByLocation,
  getSensorHealthByDate,
  getTempSensorByDate,
  getPressureSensorByDate,
  getHumidSensorByDate,
  getAmblSensorByDate,
} from '../../services';
import { BreadcrumbList, PermissionContainer } from '../../shared';
import * as config from '../../config';
import { Link } from 'react-router-dom';
import { Line, LineChart, XAxis, YAxis, Tooltip as ReTooltip, BarChart, Bar, Legend } from 'recharts';
import { CRUD, DateFormats, ModuleNames, Pages, SensorTypes } from '../../constants';
import { lengthOftheBarChartLine } from '../../utils';
import { capitalize, get } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import s from './index.module.less';

const { TabPane } = Tabs;
const { Option } = Select;

const AssetTracking = () => {
  const [context, setContext] = useContext(Context);
  const [assetorAreaView, setAssetorAreaView] = useState(true);
  const [gateway, setGateway] = useState(null);
  const [gateways, setGateways] = useState([]);
  const [tgAssets, setTgAssets] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locGateways, setLocGateways] = useState([]);

  const currentTableDataInitial = { 0: [] };
  const [currentTableData, setCurrentTableData] = useState({ ...currentTableDataInitial });
  const [currentZoneData, setCurrentZoneData] = useState({ ...currentTableDataInitial });
  const [sliderData, setSliderData] = useState([]);
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [isMinReached, setIsMinReached] = useState(true);
  const slidesBreakpoint = 3;
  const sliderIndex = useRef(0);
  const [tableState, setTableState] = useState(currentTableData[gateway?.id] || 0);
  const [tableStateLocation, setTableStateLocation] = useState([]);
  const [showDevice, setShowDevice] = useState(false);
  const [showAsset, setShowAssets] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [lineChartTitle, setLineChartTitle] = useState(capitalize(SensorTypes.TEMPERATURE));
  const sensorInitial = {
    [SensorTypes.BATTERY]: { value: 0, loading: true, selected: false, lineChartData: [] },
    [SensorTypes.TEMPERATURE]: { value: 0, loading: true, selected: true, lineChartData: [] },
    [SensorTypes.HUMIDITY]: { value: 0, loading: true, selected: false, lineChartData: [] },
    [SensorTypes.AMBLIGHT]: { value: 0, loading: true, selected: false, lineChartData: [] },
    [SensorTypes.PRESSURE]: { value: 0, loading: true, selected: false, lineChartData: [] },
  };
  const [sensorData, setSensorData] = useState({ 0: { ...sensorInitial } });
  const sensorMetricInitial = {
    max: { value: 0, loading: false },
    min: { value: 0, loading: false },
    avg: { value: 0, loading: false },
  };
  const [selectedSensorMetrics, setSelectedSensorMetrics] = useState({ ...sensorMetricInitial });
  const [currAssets, setCurrAssets] = useState([]);
  const [dataTemp, setDataTemp] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(SensorTypes.TEMPERATURE);
  const searchRef = useRef('');
  const [isVisible, setIsVisible] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [gps, setGps] = useState({ latitude: 0, longitude: 0 });
  const [allAreas, setAllAreas] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [dataTempForTempSensor, setDataTempForTempSensor] = useState([]);
  const [dataTempForDateRange, setDataTempForDateRange] = useState([]);

  const mapLoaderRef = useRef(null);
  const stompClient = useRef(null);
  const subsGateways = useRef({});
  const subs = useRef([]);
  const { Panel } = Collapse;

  //Slider-next
  const nextSlide = () => {
    sliderIndex.current += slidesBreakpoint;
    setSliderData(locGateways.slice(sliderIndex.current, sliderIndex.current + slidesBreakpoint));
    if (sliderIndex.current + slidesBreakpoint >= locGateways.length) {
      setIsMaxReached(true);
      setIsMinReached(false);
    } else {
      setIsMaxReached(false);
      setIsMinReached(false);
    }
  };

  //Slider-previous
  const prevSlide = () => {
    sliderIndex.current -= slidesBreakpoint;
    setSliderData(locGateways.slice(sliderIndex.current, sliderIndex.current + slidesBreakpoint));
    if (sliderIndex.current < slidesBreakpoint) {
      setIsMinReached(true);
      setIsMaxReached(false);
    } else {
      setIsMinReached(false);
      setIsMaxReached(false);
    }
  };

  //Sensor promises(temerature,amblight,humidity,pressure,battery)
  //From promises response updating the sensorData state[value,loading,selected-type]
  useEffect(() => {
    const initData = () => {
      const tenantId = context.profile.tenantId;
      let sensorPromises = [
        getAmblightSensorInitialData(tenantId, deviceInfo.deviceLogId),
        getTempSensorInitialData(tenantId, deviceInfo.deviceLogId),
        getHumiditySensorInitialData(tenantId, deviceInfo.deviceLogId),
        getPressureSensorInitialData(tenantId, deviceInfo.deviceLogId),
        getSensorInitialData(tenantId, deviceInfo.deviceLogId, SensorTypes.BATTERY),
      ];
      Promise.allSettled(sensorPromises)
        .then((res) => {
          const formatValue = (data) => {
            return data.attributeType === 'float'
              ? parseFloat(data.attributeValue).toFixed(2)
              : data.attributeValue || 'NA';
          };
          setSensorData((state) => {
            const temp = { ...state };
            temp[deviceInfo?.deviceLogId || 0] = {
              ...temp[deviceInfo?.deviceLogId || 0],
              [SensorTypes.TEMPERATURE]: {
                value: formatValue(res[1].value.data),
                loading: false,
                selected: selectedSensor === SensorTypes.TEMPERATURE,
                lineChartData: [],
              },
              [SensorTypes.HUMIDITY]: {
                value: formatValue(res[2].value.data),
                loading: false,
                selected: selectedSensor === SensorTypes.HUMIDITY,
                lineChartData: [],
              },
              [SensorTypes.AMBLIGHT]: {
                value: formatValue(res[0].value.data),
                loading: false,
                selected: selectedSensor === SensorTypes.AMBLIGHT,
                lineChartData: [],
              },
              [SensorTypes.PRESSURE]: {
                value: formatValue(res[3].value.data),
                loading: false,
                selected: selectedSensor === SensorTypes.PRESSURE,
                lineChartData: [],
              },
              [SensorTypes.BATTERY]: {
                value: formatValue(res[4].value.data),
                loading: false,
                selected: selectedSensor === SensorTypes.BATTERY,
                lineChartData: [],
              },
            };
            return temp;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    //Min,Max,Avg initial metrics for all sensors(temerature,amblight,humidity,pressure,battery))
    const initMetrics = async () => {
      const tenantId = context.profile?.tenantId;
      let promiseToCall = null;
      let args = [tenantId, deviceInfo?.deviceLogId || '0'];
      const sensorToCall = lineChartTitle.toLowerCase();
      if (sensorToCall === SensorTypes.AMBLIGHT.toLowerCase()) {
        promiseToCall = getAmblightSensorMetrics;
      }
      if (sensorToCall === SensorTypes.TEMPERATURE.toLowerCase()) {
        promiseToCall = getTempSensorMetrics;
      }
      if (sensorToCall === SensorTypes.HUMIDITY.toLowerCase()) {
        promiseToCall = getHumiditySensorMetrics;
      }
      if (sensorToCall === SensorTypes.PRESSURE.toLowerCase()) {
        promiseToCall = getPressureSensorMetrics;
      }
      if (sensorToCall === SensorTypes.BATTERY.toLowerCase()) {
        promiseToCall = getGatewaySensorInitialData;
        args = [...args, SensorTypes.BATTERY];
      }
      setSelectedSensorMetrics({
        avg: { value: 0, loading: true },
        min: { value: 0, loading: true },
        max: { value: 0, loading: true },
      });
      try {
        const res = await promiseToCall(...args);
        const data = res.data;
        setSelectedSensorMetrics({
          avg: { value: data.avg?.toFixed(2), loading: false },
          min: { value: data.min?.toFixed(2), loading: false },
          max: { value: data.max?.toFixed(2), loading: false },
        });
      } catch (e) {
        console.log(e);
      }
    };
    if (showDevice || showAsset) {
      initData();
      initMetrics();
    }
    // eslint-disable-next-line
  }, [showDevice, showAsset, lineChartTitle]);

  //If tab is changed to history ,calling onHistoryClick() function
  //updating date-range sensors (temerature,amblight,humidity,pressure) to empty
  const onTabChange = (e) => {
    setDataTempForTempSensor([]);
    setDataTempForDateRange([]);
    if (e === '4') onSearchHistoryData();
  };

  //dwell Time in hours,minutes,seconds for history tab table data
  const dwellReadable = (previousTime, currentTime) => {
    const absValue = Math.abs(currentTime - previousTime);
    if (absValue < 61) {
      return `${absValue} seconds`;
    }
    if (absValue < 3601) {
      return `${Math.floor(absValue / 60)} minutes`;
    }
    return `${Math.floor(absValue / 3600)} hours`;
  };

  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    setCurrAssets(Object.values(currentTableData).flat());
  }, [currentTableData]);

  //Promises data for all gateways,all locations,all tagged assets, all areas
  useEffect(() => {
    const init = async () => {
      setContext((state) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      try {
        const resGateways = await getAllGateways(context.profile.tenantId);
        const gatewayActive = resGateways.data.filter((x) => x.deleted === false);
        const resLocations = await getAllLocations(context.profile.tenantId);
        const locationActive = resLocations.data.filter((x) => x.deleted === false);
        const resTgAsset = await getAllTaggedAssets(context.profile.tenantId);
        const resAreas = await getAllAreas(context.profile.tenantId);
        setAllAreas(resAreas.data);
        gatewayActive.forEach((gateway) => {
          gateway.area = resAreas.data.find((x) => x.id === gateway.areaId)?.name || 'NA';
        });

        setLocations(locationActive);
        setTgAssets(resTgAsset.data);
        setGateways(gatewayActive);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const gatewayNav = gatewayActive.find((x) => x.id === params.gateway);
        const newGateways = [...gatewayActive];
        let socket = new SockJS(`${config.REACT_APP_SPIDEX_SOCKET_URI}/spdxsocket`);
        stompClient.current = Stomp.over(socket);
        stompClient.current.connect(
          (err) => {
            console.log(err);
            message.error('Unable to get the data, try again later');
          },
          () => {
            if (params.gateway && gatewayNav?.id) {
              newGateways.forEach((item, i) => {
                if (item.id === gatewayNav?.id) {
                  newGateways.splice(i, 1);
                  newGateways.unshift(item);
                }
              });
              const locationGateways = gatewayActive.filter((gateway) => gatewayNav?.locId === gateway.locId);
              setLocGateways(locationGateways);
              setSelectedLocationId(gatewayNav?.locId);
            } else {
              const locationGateways = gatewayActive.filter((gateway) => locationActive[0]?.id === gateway.locId);
              setLocGateways(locationGateways);
              setSelectedLocationId(locationActive[0]?.id || '');
            }
            if (gatewayNav) setGateway(gatewayNav);
          }
        );
      } catch (e) {
        console.log(e);
        message.error('Unable to get Gateway details, try again later');
      } finally {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  //Updating zone view table data ,based on location selection
  useEffect(() => {
    setCurrentZoneData((state) => {
      const tempData = { ...state };
      const gatewayAreasZones = [];
      gateways
        .filter((x) => x.locId === selectedLocationId)
        .forEach((gateway) => {
          const item = {
            area: gateway.areaName || '',
            areaId: gateway.areaId || '',
            type: gateway.categoryType || '',
            assets: [],
          };
          if (gateway.categoryType === 'lint') {
            item.children = [];
            gateway?.gatewayLint?.forEach((lint) => {
              const lintItem = {
                area: lint.areaName || '',
                areaId: lint.areaId || '',
                gatLintId: lint.gatLintId || '',
                type: 'lint',
                assets: [],
              };
              item.children.push(lintItem);
            });
          }
          gatewayAreasZones.push(item);
        });
      tempData[selectedLocationId] = gatewayAreasZones;
      return tempData;
    });
    // eslint-disable-next-line
  }, [selectedLocationId]);

  //On locations changing updating slider cards data
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const gatewayNav = gateways.find((x) => x.id === params.gateway);
    if (gatewayNav) {
      setGateway(gatewayNav);
    }
    setSliderData(locGateways?.slice(0, slidesBreakpoint));
    // eslint-disable-next-line
  }, [locGateways]);

  //Calling & updating the asset view cards information ,based on gateways change
  useEffect(() => {
    for (const gateway of sliderData) {
      onClickCard(gateway);
    }
    // eslint-disable-next-line
  }, [sliderData]);

  useEffect(() => {
    const tempSub = subs.current;
    return () => {
      tempSub.forEach((sub) => {
        try {
          sub?.unsubscribe();
        } catch (e) {
          console.log(e);
        }
      });
      stompClient?.current?.disconnect();
    };
  }, []);

  //On clicking on slider cards in Asset view
  // calling UpdateTableData() function with gateway id & json data as props
  //updating gateway data
  const onClickCard = (gateway) => {
    const subscribeFn = () => {
      const sub = stompClient.current.subscribe(`/data/proximity/${gateway.id}`, (message) => {
        const jsonData = JSON.parse(message.body);
        updateTableData(gateway.id, jsonData, true);
      });
      subs.current.push(sub);
    };
    if (stompClient?.current?.connected && gateway?.id) {
      if (subsGateways.current[gateway.id] !== true) subscribeFn();
      subsGateways.current[gateway.id] = true;
    }
    setGateway(gateway);
  };

  //On locations selector changing, slider cards data is updating for asset view & zone view
  const selectedLocation = (location) => {
    const locationGateways = gateways.filter((gateway) => gateway.locId === location);
    setLocGateways(locationGateways);
    setSliderData(locationGateways?.slice(0, slidesBreakpoint));
    setSelectedLocationId(location);
  };

  //web socket sensors data
  const webSocketData = (devLogId, sensors) => {
    for (let sensor in sensors) {
      const sub = stompClient.current.subscribe(`/data/${sensors[sensor]}/${devLogId}`, (message) => {
        const jsonData = JSON.parse(message.body);
        setSensorData((state) => {
          const tempState = { ...state };
          tempState[devLogId][sensors[sensor]].value =
            jsonData.attributeType === 'float'
              ? parseFloat(jsonData.attributeValue).toFixed(2)
              : jsonData.attributeValue || 'NA';
          tempState[devLogId][sensors[sensor]].loading = false;
          tempState[devLogId][sensors[sensor]].date = moment(jsonData.eventTime * 1000)
            .format(DateFormats.TYPE_4)
            .toString();
          tempState[devLogId][sensors[sensor]].lineChartData = [
            ...tempState[devLogId][sensors[sensor]].lineChartData,
            {
              value:
                jsonData.attributeType === 'float'
                  ? parseFloat(jsonData.attributeValue).toFixed(2)
                  : jsonData.attributeValue || 'NA',
              time: moment(jsonData.eventTime * 1000)
                .format(DateFormats.TYPE_5)
                .toString(),
            },
          ];
          return tempState;
        });
      });
      subs.current.push(sub);
    }
  };

  const isInitialProximityLoaded = useRef(false);
  useEffect(() => {
    const init = async () => {
      if (!selectedLocationId) return;
      try {
        const proximityRes = await getSensorProximityInitialByLocation(context.profile.tenantId, selectedLocationId);
        proximityRes.data.forEach((asset) => {
          updateTableData(asset.sourceId, asset, false);
        });
        isInitialProximityLoaded.current = true;
      } catch {
        message.error('Unable to get Sensor Proximity details, try again later');
      }
    };
    init();
    // eslint-disable-next-line
  }, [selectedLocationId]);

  //Updating table with assets information
  const updateTableData = (gatewayId, data, filterCurrent = false) => {
    let deviceKey = 'devicePhyId';
    if (!filterCurrent) deviceKey = 'devPhysicalId';
    if (filterCurrent && !isInitialProximityLoaded.current) return;
    setCurrentTableData((state) => {
      const tempDataReturn = { ...state };
      Object.keys(state).forEach((gateway) => {
        const foundAsset = state[gateway].find((x) => x.devicePhyId === data[deviceKey]);
        if (foundAsset) {
          tempDataReturn[gateway] = [...tempDataReturn[gateway].filter((x) => x.devicePhyId !== data[deviceKey])];
        }
      });
      const tempData = [...(state[gatewayId] || [])];
      const filteredTemp = [...tempData.filter((x) => x.devicePhyId !== data[deviceKey])];
      const tgAssetLink = tgAssets.find((x) => x.id === data.deviceLogId);
      if (!tgAssetLink) return state;
      const newAsset = { ...data };
      newAsset.devicePhyId = data[deviceKey];
      newAsset.key = `${data[deviceKey]} ${Math.floor(Date.now() / 1000)}`;
      newAsset.macId = tgAssetLink?.taggedAssetInfo?.assetExternalId;
      newAsset.deviceId = tgAssetLink?.taggedAssetInfo?.tagExternalId;
      newAsset.tagName = tgAssetLink?.taggedAssetInfo?.tagName;
      newAsset.name = tgAssetLink?.taggedAssetInfo?.assetName;
      newAsset.tgAssetLink = { ...tgAssetLink };
      newAsset.areaName = tableArea(newAsset);
      newAsset.zone = tableZone(newAsset);
      filteredTemp.push(newAsset);
      filteredTemp.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      const tempDataReturnWithNew = { ...tempDataReturn, [gatewayId]: filteredTemp };
      return tempDataReturnWithNew;
    });
    setCurrentZoneData((state) => {
      const tempDataReturn = { ...state };
      const tgAssetLink = tgAssets.find((x) => x.id === data.deviceLogId);
      if (!tgAssetLink) return state;
      const newAsset = { ...data };
      newAsset.devicePhyId = data[deviceKey];
      newAsset.key = `${data[deviceKey]} ${Math.floor(Date.now() / 1000)}`;
      newAsset.macId = tgAssetLink?.taggedAssetInfo?.assetExternalId;
      newAsset.deviceId = tgAssetLink?.taggedAssetInfo?.tagExternalId;
      newAsset.tagName = tgAssetLink?.taggedAssetInfo?.tagName;
      newAsset.name = tgAssetLink?.taggedAssetInfo?.assetName;
      newAsset.tgAssetLink = { ...tgAssetLink };
      newAsset.areaName = tableArea(newAsset);
      newAsset.zone = tableZone(newAsset);
      let zone = tempDataReturn[selectedLocationId];
      const gatewayLint = data.sourceId !== data.lintLogId;
      const foundZone = gatewayLint
        ? zone.find((x) => x.area === newAsset.areaName).children
        : zone.find((x) => x.area === newAsset.areaName);
      zone.forEach((z) => {
        const foundAsset = z?.assets?.find((x) => x.devicePhyId === data[deviceKey]);
        if (foundAsset) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          z.assets = [...z?.assets?.filter((x) => x.devicePhyId !== data[deviceKey])];
        }
      });
      if (gatewayLint) {
        const lintZone = foundZone.find((x) => x.area === newAsset.zone);
        foundZone.forEach((l) => {
          const foundAsset = l?.assets?.find((x) => x.devicePhyId === data[deviceKey]);
          if (foundAsset) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            l.assets = [...l?.assets?.filter((x) => x.devicePhyId !== data[deviceKey])];
          }
        });
        lintZone.assets = [...(lintZone?.assets || []), newAsset];
      } else {
        foundZone.assets = [...(foundZone?.assets || []), newAsset];
      }
      return tempDataReturn;
    });
  };

  //onClicking on all area checkbox getting all areas information in table
  const onClickAllAreas = () => {
    const gid = locGateways.filter((x) => x.locId === selectedLocationId);
    const data = gid.map((x) => currentTableData[x.id]);
    setTableStateLocation(() => {
      let tempData = data.flat();
      tempData.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return tempData.filter(
        (x) =>
          x?.name?.toLowerCase().includes(searchRef.current.toLowerCase()) ||
          x?.macId?.toLowerCase().includes(searchRef.current.toLowerCase()) ||
          x?.deviceId?.toLowerCase().includes(searchRef.current.toLowerCase())
      );
    });
  };

  //Function call for all areas check box is selected
  useEffect(() => {
    onClickAllAreas();
    // eslint-disable-next-line
  }, [currentTableData[gateway?.id]]);

  useEffect(() => {
    if (gateway?.id) {
      setTableState(() => {
        let tempData = [...(currentTableData[gateway.id] || [])];
        tempData.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        return tempData.filter(
          (x) =>
            x.name?.toLowerCase().includes(searchRef.current.toLowerCase()) ||
            x.macId?.toLowerCase().includes(searchRef.current.toLowerCase())
        );
      });
    }
    // eslint-disable-next-line
  }, [currentTableData[gateway?.id]]);

  // WIP searching asset information by asset-name or mac-ID or device-ID
  const onSearch = (e) => {
    searchRef.current = e.target.value;
    setTableState(() => {
      let tempData = [...currentTableData[gateway.id]];
      tempData.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return tempData.filter(
        (x) =>
          x.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          x.macId?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          x.deviceId?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
  };

  //sensors for asset
  const assetSensors = [
    SensorTypes.TEMPERATURE,
    SensorTypes.HUMIDITY,
    SensorTypes.AMBLIGHT,
    SensorTypes.PROXIMITY,
    SensorTypes.PRESSURE,
  ];

  //On clicking asset name, sensor type temperature set to line chart title
  //updating device information and sensors information
  const onClickAssetName = async (e) => {
    setLineChartTitle(capitalize(SensorTypes.TEMPERATURE));
    setDeviceInfo(e);
    setSensorData((state) => {
      const tempData = { ...state };
      tempData[e.deviceLogId] = { ...sensorInitial };
      return tempData;
    });
    webSocketData(e.deviceLogId, assetSensors);
  };
  //sensors for device id
  const deviceSensors = [SensorTypes.BATTERY, SensorTypes.TEMPERATURE, SensorTypes.HUMIDITY, SensorTypes.AMBLIGHT];

  //On clicking device id ,sensor type temperature is set to line chart title
  //updating to sensors data
  const onDeviceIdClick = (val) => {
    setLineChartTitle(capitalize(SensorTypes.TEMPERATURE));
    setSensorData((state) => {
      const tempData = { ...state };
      tempData[val.deviceLogId] = { ...sensorInitial };
      return tempData;
    });
    setDeviceInfo(val);
    setShowDevice(true);
    webSocketData(val.deviceLogId, deviceSensors);
  };

  //updating web socket data
  useEffect(() => {
    const list = sensorData[deviceInfo?.deviceLogId || 0][selectedSensor]?.lineChartData;
    list.length >= 101 && list.shift();
    setDataTemp(list);
    // eslint-disable-next-line
  }, [sensorData]);

  //On clicking sensors button updating date-range sensors data to empty
  //updating line-Chart-Title state  to selected sensors title
  const sensBtnClick = (sensor) => {
    setDataTempForTempSensor([]);
    setDataTempForDateRange([]);
    setSelectedSensor(sensor);
    setLineChartTitle(capitalize(sensor));
  };

  //on clicking on the sensors button (temperature,humidity,abmlight,pressure)
  //Based on selected sensor types , updating the sensors data
  const onClickSensorButton = (sensor) => {
    setDataTempForTempSensor([]);
    setSensorData((state) => {
      const tempData = { ...state };
      tempData[deviceInfo?.deviceLogId || 0] = {
        ...tempData[deviceInfo?.deviceLogId || 0],
        [SensorTypes.BATTERY]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.BATTERY],
          selected: sensor === SensorTypes.BATTERY,
        },
        [SensorTypes.TEMPERATURE]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.TEMPERATURE],
          selected: sensor === SensorTypes.TEMPERATURE,
        },
        [SensorTypes.HUMIDITY]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.HUMIDITY],
          selected: sensor === SensorTypes.HUMIDITY,
        },
        [SensorTypes.AMBLIGHT]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.AMBLIGHT],
          selected: sensor === SensorTypes.AMBLIGHT,
        },
        [SensorTypes.PRESSURE]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.PRESSURE],
          selected: sensor === SensorTypes.PRESSURE,
        },
        [SensorTypes.PROXIMITY]: {
          ...tempData[deviceInfo?.deviceLogId][SensorTypes.PROXIMITY],
          selected: sensor === SensorTypes.PROXIMITY,
        },
      };
      return tempData;
    });
    sensBtnClick(sensor);
  };

  const [operation, setOperation] = useState('');
  const onChangeCommandOperation = (e) => setOperation(e);

  const onFinishCommandForm = () => {}; //-WIP

  const tableArea = (value) => {
    const gateway = gateways.find((x) => x.id === value?.sourceId);
    return gateway?.areaName || `Area-${value.sourceId}`;
  };

  const tableGps = (value) => {
    const gatewayFound = gateways.find((x) => x.id === value?.sourceId);
    if (!gatewayFound) return null;
    const area = allAreas.find((x) => x.id === gatewayFound.areaId);
    return area?.gpsPoint || null;
  };

  const tableZone = (value) => {
    if (value.sourceId === value.lintLogId) {
      return tableArea(value);
    } else {
      const gateway = gateways.find((x) => x.id === value?.sourceId);
      const zone = gateway?.gatewayLint?.find((x) => x.gatLintId === value?.lintLogId);
      return zone?.areaName || `Zone-${value.sourceId}`;
    }
  };

  //Asset view table data
  const columns = [
    {
      title: <strong> Asset Name </strong>,
      render: (val) => (
        <Button
          type="link"
          onClick={() => {
            onClickAssetName(val);
            setShowAssets(true);
          }}
        >
          {val?.name}
        </Button>
      ),
    },
    {
      title: <strong> Asset ID </strong>,
      dataIndex: 'macId',
      key: 'macId',
    },
    {
      title: <strong> Device ID </strong>,
      render: (val) => (
        <Button
          type="link"
          onClick={() => {
            onDeviceIdClick(val);
          }}
        >
          {val.deviceId}
        </Button>
      ),
    },
    {
      title: <strong> Area </strong>,
      dataIndex: 'areaName',
      key: 'areaName',
    },
    {
      title: <strong> Zone </strong>,
      dataIndex: 'zone',
      key: 'zone',
    },
    {
      title: <strong> Proximity </strong>,
      render: (record) => {
        if (record.attributeType === 'float') return <span>{record.attributeValue}</span>;
        if (record.attributeType === 'int') return <span>{record.proximity}</span>;
        return <span>{record.proximity}</span>;
      },
    },
    {
      title: <strong> Last Seen </strong>,
      dataIndex: 'eventTime',
      render: (value) => <span>{`${moment(new Date(value * 1000)?.toISOString()).format(DateFormats.TYPE_2)}`}</span>,
    },
  ];

  //Zone view table data
  const zoneColumns = [
    {
      title: <strong> Zone </strong>,
      dataIndex: 'area',
      key: 'area',
    },
    {
      title: <strong> Type </strong>,
      dataIndex: 'type',
      render: (value) => capitalize(value),
    },
    {
      title: <strong> Count </strong>,
      dataIndex: 'assets',
      render: (value) => value.length || 0,
    },
  ];

  // Disable the all areas checkbox in zone view based on view changes
  const onViewChange = (e) => {
    setAssetorAreaView(e.target.value === 'asset');
    e.target.value === 'area' ? setIsVisible(false) : setIsVisible(true);
    e.target.value === 'area' ? setIsDisable(true) : setIsDisable(false);
  };

  const [selectedHistoryAsset, setSelectedHistoryAsset] = useState(0);
  //On clicking on the card in history tab the map will render
  const updateMap = (asset) => {
    setSelectedHistoryAsset(asset.reportedTime);
    if (asset.isGps) {
      return setGps({ latitude: asset.gps.latitude, longitude: asset.gps.longitude });
    } else {
      message.error('Location details unavailable for selected Area');
    }
  };
  //On Date Range Search for history tab On click history tab getting time-line cards(entry,exit,dwell-time)
  const onSearchHistoryData = async (_, date) => {
    const fromDate = date ? date[0] : moment().subtract(1, 'hours').format(DateFormats.TYPE_7);
    const toDate = date ? date[1] : moment().format(DateFormats.TYPE_7);
    const tenantId = context.profile?.tenantId;
    if (fromDate === '' && toDate === '') return;
    try {
      const res = await getSensorProximityByDate(tenantId, deviceInfo.deviceLogId, fromDate, toDate);
      let firstAreaTime;
      const tempData = [...res.data];
      if (tempData.length === 0) {
        message.error('No history found for device.');
        return;
      }
      tempData.forEach((entry, index) => {
        entry.dataEventTime = moment(new Date(entry.eventTime * 1000)).format(DateFormats.TYPE_6);
        entry.dataArea = tableZone(entry) || 'Temporary Area';
        entry.gps = tableGps(entry);
        entry.isGps = entry.gps ? true : false;
        if (index !== 0) {
          const previousArea = tempData[index - 1].dataArea;
          //const previousTime = tempData[index - 1].eventTime;
          //const previousDataEventTime = tempData[index - 1].dataEventTime;
          if (entry.dataArea === previousArea) {
            tempData[index - 1].render = false;
            entry.render = true;
          //  entry.dataEventTime = previousDataEventTime;
          } else {
            entry.render = true;
			firstAreaTime = entry.eventTime;
          }
          entry.dwell = dwellReadable(firstAreaTime, entry.eventTime);
        } else {
          entry.dwell = '0 seconds';
          entry.render = true;
          firstAreaTime = entry.eventTime;
        }
        entry.reportedDataTime = moment(new Date(entry.reportedTime * 1000)).format(DateFormats.TYPE_6);
        entry.exitEventTime = moment(new Date(entry.eventTime * 1000))
          .add(entry.aggregateTime, 'seconds')
          .format(DateFormats.TYPE_6);
      });
      const finalData = tempData.filter((x) => x.render);
      setHistoryData(finalData);
      updateMap(finalData[0]);
    } catch (e) {
      console.log(e);
      message.error('Unable to get history for device.');
      return;
    }
  };
  const onClearHistoryData = () => {
    setHistoryData([]);
  };
  //GPS map loader
  useEffect(() => {
    const loader = new Loader({
      apiKey: config.REACT_APP_MAP_GOOGLE_API_KEY,
      libraries: ['places'],
      version: 'weekly',
    });
    mapLoaderRef.current = loader;
  }, []);

  //Updating lat,long values from gps state
  useEffect(() => {
    if (gateway?.id && gps?.latitude && gps?.longitude) {
      mapLoaderRef.current?.load().then((google) => {
        const pos = { lat: parseFloat(gps.latitude), lng: parseFloat(gps.longitude) };
        const element = document.getElementById(`google-map-${gateway?.id}`);
        if (!element) return;
        let map = new google.maps.Map(element, {
          center: pos,
          zoom: 10,
        });
        new google.maps.Marker({
          position: pos,
          map,
        });
      });
    }
    // eslint-disable-next-line
  }, [gps]);

  const [dateTempRangeInitialValues, setDateTempRangeInitialValues] = useState([]);
  //Temperature,Ambilight,humidity,pressure Sensor data
  const onDateChangeForSensors = (_, date) => {
    try {
      if (!date[0] && !date[1]) return;
    } catch (e) {
      return;
    }
    setDateTempRangeInitialValues([moment(date[0], DateFormats.TYPE_7), moment(date[1], DateFormats.TYPE_7)]);
  };

  useEffect(() => {
    setDateTempRangeInitialValues([moment().subtract(5, 'hours'), moment()]);
  }, [gateway, selectedSensor]);

  //For Assets On date-range change, getting (Temperature,Ambilight,humidity,pressure) Sensors data
  useEffect(() => {
    const init = async () => {
      try {
        if (!dateTempRangeInitialValues[0] && !dateTempRangeInitialValues[1]) return;
      } catch (e) {
        return;
      }
      const fromDate = dateTempRangeInitialValues[0].format(DateFormats.TYPE_7);
      const toDate = dateTempRangeInitialValues[1].format(DateFormats.TYPE_7);
      const tenantId = context.profile?.tenantId;
      if (!gateway?.id || !tenantId) return;
      let promiseToCall = null;
      const sensorToCall = lineChartTitle.toLowerCase();
      if (sensorToCall === SensorTypes.AMBLIGHT.toLowerCase()) {
        promiseToCall = getAmblSensorByDate;
      }
      if (sensorToCall === SensorTypes.TEMPERATURE.toLowerCase()) {
        promiseToCall = getTempSensorByDate;
      }
      if (sensorToCall === SensorTypes.HUMIDITY.toLowerCase()) {
        promiseToCall = getHumidSensorByDate;
      }
      if (sensorToCall === SensorTypes.PRESSURE.toLowerCase()) {
        promiseToCall = getPressureSensorByDate;
      }

      try {
        const res = await promiseToCall(tenantId, toDate, fromDate, gateway.id);
        const data = res.data;
        data.forEach((d) => {
          d.time = new Date(d.reportedTime * 1000).toLocaleTimeString();
        });
        setDataTempForTempSensor(data);
      } catch (e) {
        console.log(e);
        return;
      }
    };
    init();
    // eslint-disable-next-line
  }, [dateTempRangeInitialValues]);

  //Clear button for clearng the sensors date-picker data
  const clearTempSensorData = () => {
    setDataTempForTempSensor([]);
  };

  const [dateRangeInitialValues, setDateRangeInitialValues] = useState([]);
  //For healthsensors date picker wise graph
  const onDateChange = (_, date) => {
    try {
      if (!date[0] && !date[1]) return;
    } catch (e) {
      return;
    }
    setDateRangeInitialValues([moment(date[0], DateFormats.TYPE_7), moment(date[1], DateFormats.TYPE_7)]);
  };

  useEffect(() => {
    setDateRangeInitialValues([moment().subtract(5, 'hours'), moment()]);
    // eslint-disable-next-line
  }, [gateway, selectedSensor]);

  //For healthsensors date-picker wise graph
  useEffect(() => {
    const init = async () => {
      try {
        if (!dateRangeInitialValues[0] && !dateRangeInitialValues[1]) return;
      } catch (e) {
        return;
      }
      const fromDate = dateRangeInitialValues[0].format(DateFormats.TYPE_7);
      const toDate = dateRangeInitialValues[1].format(DateFormats.TYPE_7);
      const tenantId = context.profile?.tenantId;
      if (!gateway?.id || !tenantId) return;
      let promiseToCall = null;
      const sensorToCall = lineChartTitle.toLowerCase();
      if (sensorToCall === SensorTypes.AMBLIGHT.toLowerCase()) {
        promiseToCall = getAmblSensorByDate;
      }
      if (sensorToCall === SensorTypes.TEMPERATURE.toLowerCase()) {
        promiseToCall = getTempSensorByDate;
      }
      if (sensorToCall === SensorTypes.HUMIDITY.toLowerCase()) {
        promiseToCall = getHumidSensorByDate;
      }
      if (sensorToCall === SensorTypes.PRESSURE.toLowerCase()) {
        promiseToCall = getPressureSensorByDate;
      }
      if (sensorToCall === SensorTypes.BATTERY.toLowerCase()) {
        promiseToCall = getSensorHealthByDate;
      }
      try {
        const res =
          sensorToCall !== 'battery'
            ? await promiseToCall(tenantId, toDate, fromDate, gateway.id)
            : await promiseToCall(tenantId, selectedSensor, toDate, fromDate, gateway.id);
        const data = res.data;
        data.forEach((d) => {
          d.time = new Date(d.reportedTime * 1000).toLocaleTimeString();
        });
        setDataTempForDateRange(data);
      } catch (e) {
        console.log(e);
        return;
      }
    };
    init();
    // eslint-disable-next-line
  }, [dateRangeInitialValues]);

  //clearing datepicker wise graph data for healthsensors sensors
  const clearData = () => {
    setDataTempForDateRange([]);
  };

  //BreadcrumbList name
  const assetTrackingBreadcrumbsName = get(
    context.tenantProfile,
    `dashboardNames[${Pages.ASSET_TRACKING_DASHBOARD}].displayName`,
    ModuleNames.ASSET_TRACKING
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <BreadcrumbList list={[assetTrackingBreadcrumbsName]} />
        </Col>
        <Col>
          <h3>
            <Tag color="#FFCC56">Total Areas: {gateways?.length} </Tag>
            <Tag color="#87D068">Total Assets: {currAssets?.length}</Tag>
          </h3>
        </Col>
      </Row>
      {!showAsset && !showDevice ? (
        <>
          {context.isCompact ? (
            <>
              <div className={s.sliderContainerCompact}>
                {locGateways?.map((slide, index) => (
                  <Card
                    key={slide.id}
                    onClick={() => onClickCard(slide)}
                    tabIndex={index}
                    title={slide.area}
                    className={slide.id === gateway?.id ? s.selectedCard : s.card}
                    extra={
                      <PermissionContainer page={Pages.DEVICE_DASHBOARD_PAGE} permission={CRUD.VIEW}>
                        <Link to={`/dashboard/device?gateway=${slide.id}`}>
                          <SelectOutlined />
                        </Link>
                      </PermissionContainer>
                    }
                  >
                    <p>Gateway: {slide.name}</p>
                    <>
                      <Row justify="center">
                        <Col>
                          <Tag color="#87D068">Assets: {currentTableData[slide.id]?.length || 0}</Tag>
                        </Col>
                      </Row>
                    </>
                  </Card>
                ))}
              </div>
              <Table
                size="small"
                columns={columns}
                dataSource={isVisible ? tableState : tableStateLocation}
                className={s.table}
              />
            </>
          ) : (
            <>
              <Row className={s.searchInput} justify="space-between">
                <Col>
                  <Row gutter={20} align="middle">
                    <Col>
                      <strong> Locations </strong>
                    </Col>
                    <Col>
                      {locations.length > 0 && (
                        <Select
                          className={s.locationSelection}
                          value={selectedLocationId}
                          onChange={selectedLocation}
                          allowClear={false}
                        >
                          {locations.map((loc) => (
                            <Option key={loc.id} value={loc.id}>
                              {loc.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Col>
                    <Col>
                      <Checkbox
                        checked={!isVisible}
                        disabled={isDisable}
                        onClick={() => {
                          onClickAllAreas(locGateways[0]?.locId);
                          setIsVisible(!isVisible);
                        }}
                      >
                        All Areas
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Radio.Group value={assetorAreaView ? 'asset' : 'area'} onChange={onViewChange}>
                    <Radio.Button value="asset">Asset View</Radio.Button>
                    <Radio.Button value="area">Zone View</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={10}>
                  <Input
                    placeholder="Search by Asset Name or Device Id"
                    onChange={onSearch}
                    prefix={<SearchOutlined />}
                  />
                </Col>
              </Row>
              {isVisible && (
                <Row className={s.sliderContainer} justify="space-between" align="middle">
                  <Col>
                    <Button
                      type="text"
                      icon={<LeftOutlined />}
                      className={s.navBtn}
                      onClick={prevSlide}
                      disabled={isMinReached}
                    />
                  </Col>
                  <Col>
                    <Row className={s.cards}>
                      {sliderData?.map((slide) => (
                        <Col
                          key={slide.id}
                          onClick={() => onClickCard(slide)}
                          className={slide.id === gateway?.id ? s.selectedCard : s.card}
                        >
                          <Row justify="space-between">
                            <Col>
                              <Tooltip title={slide.name}>{slide.area}</Tooltip>
                            </Col>
                            <Col>
                              <PermissionContainer page={Pages.DEVICE_DASHBOARD_PAGE} permission={CRUD.VIEW}>
                                <Link to={`/dashboard/device?gateway=${slide.id}`}>
                                  <SelectOutlined />
                                </Link>
                              </PermissionContainer>
                            </Col>
                          </Row>
                          <Row justify="center" className={s.assetsNumbers}>
                            <Col>
                              <Tag color="#87D068">Assets: {currentTableData[slide.id]?.length || 0}</Tag>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<RightOutlined />}
                      onClick={nextSlide}
                      className={s.navBtn}
                      disabled={locGateways?.length <= slidesBreakpoint ? true : isMaxReached}
                    />
                  </Col>
                </Row>
              )}
              {assetorAreaView ? (
                <Table
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                  }}
                  size="small"
                  columns={columns}
                  dataSource={isVisible ? tableState : tableStateLocation}
                  className={s.table}
                />
              ) : (
                <Table
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                  }}
                  defaultExpandAllRows
                  size="small"
                  rowKey="area"
                  columns={zoneColumns}
                  dataSource={currentZoneData[selectedLocationId] || []}
                  className={s.table}
                />
              )}
            </>
          )}
        </>
      ) : showAsset ? (
        <>
          <Row className={s.deviceInfoContainer} gutter={[10, 0]}>
            <Col>
              <Button
                onClick={() => {
                  setShowAssets(false);
                  setDataTempForDateRange([]);
                  setDataTempForTempSensor([]);
                }}
                size="small"
                className="backBtn"
              >
                <ArrowLeftOutlined />
              </Button>
            </Col>
            <Col>
              <Collapse>
                <Panel header={deviceInfo.name} className={s.collapse}>
                  <h1>{`Mac ID : ${deviceInfo.tgAssetLink.taggedAssetInfo.assetExternalId}`}</h1>
                  <h1>{`Device ID : ${deviceInfo.tgAssetLink.taggedAssetInfo.tagExternalId}`}</h1>
                  <h1>{`Proximity : ${deviceInfo.proximity}`} </h1>
                  <h1>{`Area : ${deviceInfo.areaName}`}</h1>
                  <h1>{`Zone : ${deviceInfo.zone}`}</h1>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1" centered onChange={onTabChange}>
            <TabPane tab="Sensor" key="1">
              <Row justify="space-around" align="middle" gutter={[4, 16]} className="mt-5">
                <Col>
                  <h3 className="customH3">
                    <img src="/assets/icons/temperature.svg" alt="Temperature" className={s.sensorIconStyles} />
                    Temperature
                    <Button
                      className="buttonMargin"
                      onClick={() => onClickSensorButton(SensorTypes.TEMPERATURE)}
                      type={
                        sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE].selected ? 'default' : 'text'
                      }
                    >
                      <EyeTwoTone />
                    </Button>
                  </h3>
                  <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE]?.loading}>
                    <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE]?.value || 0} />
                  </Spin>
                </Col>
                <Col>
                  <h3 className="customH3">
                    <img src="/assets/icons/humidity.svg" alt="Humidity" className={s.sensorIconStyles} /> Humidity
                    <Button
                      className="buttonMargin"
                      onClick={() => onClickSensorButton(SensorTypes.HUMIDITY)}
                      type={
                        sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY].selected ? 'default' : 'text'
                      }
                    >
                      <EyeTwoTone />
                    </Button>
                  </h3>
                  <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY]?.loading}>
                    <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY]?.value || 0} />
                  </Spin>
                </Col>
                <Col>
                  <h3 className="customH3">
                    <img src="/assets/icons/amblight.svg" alt="Abmlight" className={s.sensorIconStyles} /> Abmlight
                    <Button
                      className="buttonMargin"
                      onClick={() => onClickSensorButton(SensorTypes.AMBLIGHT)}
                      type={
                        sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT].selected ? 'default' : 'text'
                      }
                    >
                      <EyeTwoTone />
                    </Button>
                  </h3>
                  <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.loading}>
                    <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.value || 0} />
                  </Spin>
                </Col>
                <Col>
                  <h3 className="customH3">
                    <img src="/assets/icons/pressure.svg" alt="Pressure" className={s.sensorIconStyles} /> Pressure
                    <Button
                      className="buttonMargin"
                      onClick={() => onClickSensorButton(SensorTypes.PRESSURE)}
                      type={
                        sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.PRESSURE].selected ? 'default' : 'text'
                      }
                    >
                      <EyeTwoTone />
                    </Button>
                  </h3>
                  <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.PRESSURE]?.loading}>
                    <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.PRESSURE]?.value || 0} />
                  </Spin>
                </Col>
              </Row>
              <Row justify="center" align="middle" gutter={[4, 16]} className="graphContainer p-3 m-4">
                <Col span={2} offset={1}>
                  <div className="d-flex align-items-center">
                    <Row>
                      <Col span={24}>
                        <Spin spinning={selectedSensorMetrics.min.loading}>
                          <Statistic title="Min" value={selectedSensorMetrics.min.value} />
                        </Spin>
                      </Col>
                      <Col span={24}>
                        <Spin spinning={selectedSensorMetrics.max.loading}>
                          <Statistic title="Max" value={selectedSensorMetrics.max.value} />
                        </Spin>
                      </Col>
                      <Col span={24}>
                        <Spin spinning={selectedSensorMetrics.avg.loading}>
                          <Statistic title="Avg" value={selectedSensorMetrics.avg.value} />
                        </Spin>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} lg={{ span: 19, offset: 1 }}>
                  <Row justify="start" gutter={16}>
                    <Col>
                      <h3 className="ml-5">
                        Timeline <Tag color="default">{lineChartTitle}</Tag>
                      </h3>
                    </Col>
                    <Col>
                      <h3>
                        Date{' '}
                        <Tag color="default">
                          {sensorData[deviceInfo?.deviceLogId || 0][selectedSensor]?.date || '----'}
                        </Tag>
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="lineChartContainer">
                        <LineChart
                          width={context.isCompact ? 800 : document.body.clientWidth * 0.6}
                          height={240}
                          data={dataTemp}
                          margin={{ bottom: 40, right: 40, top: 20 }}
                          textAnchor="end"
                        >
                          <XAxis dataKey="time" angle={35} interval="preserveEnd" dy={20} dx={15} />
                          <YAxis interval="preserveEnd" />
                          <ReTooltip />
                          <Line
                            isAnimationActive={false}
                            type="monotone"
                            dataKey="value"
                            stroke="#3e98c7"
                            activeDot={{ r: 8 }}
                          />
                        </LineChart>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="start" align="middle" className="graphContainer p-3 m-4">
                <Col xs={24} lg={{ span: 22, offset: 1 }}>
                  <Row justify="start" gutter={16}>
                    <Col className="ml-5">
                      <DatePicker.RangePicker
                        showTime={{
                          format: 'HH:mm',
                        }}
                        format={DateFormats.TYPE_7}
                        defaultValue={dateTempRangeInitialValues}
                        onChange={onDateChangeForSensors}
                      />
                      <Button type="link" danger onClick={clearTempSensorData}>
                        Clear
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="lineChartContainer">
                        <BarChart
                          width={lengthOftheBarChartLine(dataTempForTempSensor)}
                          height={240}
                          data={dataTempForTempSensor}
                          margin={{ bottom: 40, right: 40, top: 20 }}
                          textAnchor="end"
                        >
                          <XAxis dataKey="time" angle={55} interval="preserveEnd" dy={20} dx={15} />
                          <YAxis interval="preserveEnd" />
                          <ReTooltip cursor={{ fill: 'transparent' }} />
                          <Legend layout="vertical" align="left" verticalAlign="top" />
                          <Bar dataKey="min" stackId="a" fill="#8884d8" />
                          <Bar dataKey="avg" stackId="a" fill="#82ca9d" />
                          <Bar dataKey="max" stackId="a" fill="#3e98c7" />
                        </BarChart>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Command" key="2">
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 5 }}
                layout="horizontal"
                initialValues={{ size: 'componentSize' }}
                onFinish={onFinishCommandForm}
                className={s.commandForm}
              >
                <Form.Item label="Model Name" name="modelName" initialValue="Model Name">
                  <Input disabled placeholder="Model Name" />
                </Form.Item>
                <Form.Item label="Attributes">
                  <Select placeholder="Select">
                    <Select.Option value="attr1">attr1</Select.Option>
                    <Select.Option value="attr2">attr2</Select.Option>
                    <Select.Option value="attr3">attr3</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Operations" name="operation">
                  <Select placeholder="Select" onChange={onChangeCommandOperation}>
                    <Select.Option value="read">Read</Select.Option>
                    <Select.Option value="write">Write</Select.Option>
                    <Select.Option value="notification">Notification</Select.Option>
                  </Select>
                </Form.Item>

                {operation && (
                  <Form.Item label="Handle" name="handle" initialValue="Handle">
                    <Input disabled placeholder="Handle" />
                  </Form.Item>
                )}
                {operation === 'write' && (
                  <Form.Item label="Data" name="textData">
                    <Input />
                  </Form.Item>
                )}
                {operation === 'notification' && (
                  <>
                    <Form.Item label="Reporting Interval" name="reportingInterval">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Connection Life Time" name="connectionLifeTime">
                      <Input />
                    </Form.Item>
                  </>
                )}
                <Row>
                  <Col xs={{ offset: 0 }} sm={{ offset: 4 }}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab="Configure" key="3">
              <h2>Configure</h2>
            </TabPane>
            <TabPane tab="History" key="4">
              <Row justify="start" gutter={32}>
                <Col>
                  <h3 className="m-1 text-center">Events - {historyData.length}</h3>
                </Col>
                <Col>
                  <DatePicker.RangePicker
                    showTime={{
                      format: 'HH:mm',
                    }}
                    format={DateFormats.TYPE_7}
                    onChange={onSearchHistoryData}
                  />
                </Col>
                <Button type="link" danger onClick={onClearHistoryData}>
                  Clear
                </Button>
              </Row>
              <Row>
                <Col xs={24} md={14}>
                  <div className={`${context.isCompact ? 'mt-3' : 'm-4'}`}>
                    <Timeline mode="alternate">
                      {historyData.map((item, index) => (
                        <Timeline.Item key={item.eventTime}>
                          <Badge.Ribbon
                            text={selectedHistoryAsset === item.reportedTime ? 'Selected' : ''}
                            placement={index % 2 === 0 ? 'end' : 'start'}
                            color={selectedHistoryAsset === item.reportedTime ? 'blue' : 'grey'}
                          >
                            <Card
                              onClick={() => updateMap(item)}
                              title={item.dataArea}
                              size="small"
                              extra={<></>}
                              className={`${context.isCompact ? s.historyCardCompactView : s.historyCard}`}
                            >
                              {/* eslint-disable-next-line */}
                              <a id={`${item.reportedTime}`} />
                              <Row className="m-1" justify={index % 2 === 0 ? 'start' : 'end'}>
                                <Col>
                                  <Tag color="green">
                                    Entry <strong>{item.dataEventTime}</strong>
                                  </Tag>
                                </Col>
                              </Row>
                              <Row className="m-1" justify={index % 2 === 0 ? 'start' : 'end'}>
                                <Col>
                                  <Tag color="blue">
                                    Dwell Time <strong>{item.dwell}</strong>
                                  </Tag>
                                </Col>
                              </Row>
                            </Card>
                          </Badge.Ribbon>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </Col>
                <Col xs={24} md={10}>
                  <div className={s.map} id={`google-map-${gateway?.id}`} />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </>
      ) : (
        showDevice && (
          <>
            <Row className={s.deviceInfoContainer} gutter={[10, 0]}>
              <Col>
                <Button
                  onClick={() => {
                    setShowDevice(false);
                    setDataTempForDateRange([]);
                    setDataTempForTempSensor([]);
                  }}
                  size="small"
                  className="backBtn"
                >
                  <ArrowLeftOutlined />
                </Button>
              </Col>
              <Col>
                <Collapse>
                  <Panel header={deviceInfo.tagName} className={s.collapse}>
                    <h1>{`Model ID : 1`}</h1>
                    <h1>{`Model Name : NPROX`}</h1>
                    <h1>{`Device Type : Tag`} </h1>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            <Row justify="space-around" align="middle" gutter={[4, 16]} className="mt-5">
              <Col>
                <h3 className="customH3">
                  <img src="/assets/icons/battery.svg" alt="vehicle" className={s.sensorIconStyles} /> Battery
                  <Button
                    className="buttonMargin"
                    onClick={() => onClickSensorButton(SensorTypes.BATTERY)}
                    type={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.BATTERY]?.selected ? 'default' : 'text'}
                  >
                    <EyeTwoTone />
                  </Button>
                </h3>
                <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.BATTERY]?.loading}>
                  <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.BATTERY]?.value || 0} />
                </Spin>
              </Col>
              <Col>
                <h3 className="customH3">
                  <img src="/assets/icons/temperature.svg" alt="Temperature" className={s.sensorIconStyles} />{' '}
                  Temperature
                  <Button
                    className="buttonMargin"
                    onClick={() => onClickSensorButton(SensorTypes.TEMPERATURE)}
                    type={
                      sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE]?.selected ? 'default' : 'text'
                    }
                  >
                    <EyeTwoTone />
                  </Button>
                </h3>
                <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE]?.loading}>
                  <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.TEMPERATURE]?.value || 0} />
                </Spin>
              </Col>
              <Col>
                <h3 className="customH3">
                  <img src="/assets/icons/humidity.svg" alt="Humidity" className={s.sensorIconStyles} /> Humidity
                  <Button
                    className="buttonMargin"
                    onClick={() => onClickSensorButton(SensorTypes.HUMIDITY)}
                    type={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY]?.selected ? 'default' : 'text'}
                  >
                    <EyeTwoTone />
                  </Button>
                </h3>
                <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY]?.loading}>
                  <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.HUMIDITY]?.value || 0} />
                </Spin>
              </Col>
              <Col>
                <h3 className="customH3">
                  <img src="/assets/icons/amblight.svg" alt="Abmlight" className={s.sensorIconStyles} /> Abmlight
                  <Button
                    className="buttonMargin"
                    onClick={() => onClickSensorButton(SensorTypes.AMBLIGHT)}
                    type={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.selected ? 'default' : 'text'}
                  >
                    <EyeTwoTone />
                  </Button>
                </h3>
                <Spin spinning={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.loading}>
                  <Statistic value={sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.value || 0} />
                </Spin>
              </Col>
            </Row>
            <Row justify="space-around" align="middle" gutter={[4, 16]} className="graphContainer p-3 m-4">
              <Col span={2} offset={1}>
                <div className="d-flex align-items-center">
                  <Row>
                    <Col span={24}>
                      <Spin spinning={selectedSensorMetrics.min.loading}>
                        <Statistic title="Min" value={selectedSensorMetrics.min.value} />
                      </Spin>
                    </Col>
                    <Col span={24}>
                      <Spin spinning={selectedSensorMetrics.max.loading}>
                        <Statistic title="Max" value={selectedSensorMetrics.max.value} />
                      </Spin>
                    </Col>
                    <Col span={24}>
                      <Spin spinning={selectedSensorMetrics.avg.loading}>
                        <Statistic title="Avg" value={selectedSensorMetrics.avg.value} />
                      </Spin>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24} lg={{ span: 19, offset: 1 }}>
                <Row justify="start" gutter={16}>
                  <Col>
                    <h3 className="ml-5">
                      Timeline <Tag color="default">{lineChartTitle}</Tag>
                    </h3>
                  </Col>
                  <Col>
                    <h3>
                      Date{' '}
                      <Tag color="default">
                        {sensorData[deviceInfo?.deviceLogId || 0][SensorTypes.AMBLIGHT]?.date || '----'}
                      </Tag>
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="lineChartContainer">
                      <LineChart
                        width={context.isCompact ? 800 : document.body.clientWidth * 0.6}
                        height={240}
                        data={dataTemp}
                        margin={{ bottom: 40, right: 40, top: 20 }}
                        textAnchor="end"
                      >
                        <XAxis dataKey="time" angle={35} interval="preserveEnd" dy={20} dx={15} />
                        <YAxis interval="preserveEnd" />
                        <ReTooltip />
                        <Line
                          isAnimationActive={false}
                          type="monotone"
                          dataKey="value"
                          stroke="#3e98c7"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="start" align="middle" className="graphContainer p-3 m-4">
              <Col xs={24} lg={{ span: 22, offset: 1 }}>
                <Row justify="start" gutter={16}>
                  <Col className="ml-5">
                    <DatePicker.RangePicker
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format={DateFormats.TYPE_7}
                      defaultValue={dateRangeInitialValues}
                      onChange={onDateChange}
                    />
                    <Button type="link" danger onClick={clearData}>
                      Clear
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="lineChartContainer">
                      <BarChart
                        width={lengthOftheBarChartLine(dataTempForDateRange)}
                        height={240}
                        data={dataTempForDateRange}
                        margin={{ bottom: 40, right: 40, top: 20 }}
                        textAnchor="end"
                      >
                        <XAxis dataKey="time" angle={55} interval="preserveEnd" dy={20} dx={15} />
                        <YAxis interval="preserveEnd" />
                        <ReTooltip cursor={{ fill: 'transparent' }} />
                        <Legend layout="vertical" align="left" verticalAlign="top" />
                        <Bar dataKey="min" stackId="a" fill="#8884d8" />
                        <Bar dataKey="avg" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="max" stackId="a" fill="#3e98c7" />
                      </BarChart>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )
      )}
    </>
  );
};

export default AssetTracking;
