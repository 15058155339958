import React, { useContext } from 'react';
import Context from '../../context';

const PermissionContainer = (props) => {
  const { page, permission } = props;
  const [context, ,] = useContext(Context);
  const permissions = context.pagePermissions;

  const permit =
    permissions.filter((p) => p.pageName === page && p.permissions.filter((crud) => crud === permission).length > 0)
      .length > 0;

  return permit ? <> {props.children} </> : <></>;
};

export default PermissionContainer;
