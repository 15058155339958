import {
  Col,
  Row,
  Button,
  Table,
  CommonCompactView,
  Drawer,
  Select,
  Upload,
  Form,
  Typography,
  message,
  Checkbox,
  Input,
  Pagination,
  Tooltip,
  Tag,
} from '../../../components';
import React, { useState, useContext, useEffect } from 'react';
import { PlusOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import Context from '../../../context';
import { calcDrawerWidth, buildCommonApiValues } from '../../../utils';
import { Link } from 'react-router-dom';
import {
  addBulkVinEcus,
  getAllVehicleModel,
  getAllVehicleModelsForCampaign,
  getAllCampaignsByPagination,
} from '../../../services';
import { BreadcrumbList, PermissionContainer } from '../../../shared';
import { ModuleNames, Pages, CRUD, VehicleManufacturers, MastersPageSizeDefault } from '../../../constants';
import s from './index.module.less';

const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;

const Campaign = () => {
  const [context, setContext] = useContext(Context);
  const [totalCampaignsInfo, setTotalCampaignsInfo] = useState({
    items: 0,
    current: 1,
    pageSize: MastersPageSizeDefault,
  });
  const [visibleBulk, setVisibleBulk] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const [csvArray, setCsvArray] = useState([]);
  const [selectedEcusInfo, setSelectedEcusInfo] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedEcus, setSelectedEcus] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignDataOriginal, setCampaignDataOriginal] = useState([]);
  const csvData = csvArray.map((x) => x.vinNumber);

  const [form] = Form.useForm();
  const [formEcu] = Form.useForm();

  const formInitValues = {};

  const openAdd = async () => {
    setVisibleBulk(true);
  };

  //On closing Bulk VIN ECUs
  const closeAddBulk = () => {
    setVisibleBulk(false);
    form.resetFields();
  };

  //Process CSV
  const [isTrue, setIsTrue] = useState(true);
  const processCSV = (str) => {
    let isValid = true;
    const allTextLines = str.split(/\r\n|\n/);
    const headers = allTextLines[0].split(',');
    const headersReq = ['VINNumber'];
    const valid = JSON.stringify(headersReq).toLowerCase() === JSON.stringify(headers).toLowerCase();
    if (!valid) {
      setWarningMsg('Headers do not match please refer the sample file.');
      setCsvArray([]);
      isValid = false;
    }
    allTextLines.forEach((x) => {
      x.split(',').forEach((y) => {
        if (y === '') {
          setWarningMsg('Missing values in file please check again.');
          isValid = false;
        }
      });
    });
    if (!isValid) {
      setCsvArray([]);
    } else {
      const rows = str.slice(str.indexOf('\n') + 1).split('\n');
      const newArray = rows.map((row) => {
        const values = row.split(',');
        const eachObject = headers.reduce((obj, header, i) => {
          obj[header] = values[i].replace(/[\r]/, '');
          return obj;
        }, {});
        return eachObject;
      });

      setCsvArray(newArray);
    }
    setIsTrue(isValid);
  };

  //Save action for add bulk VIN ECUs
  const saveAllVinsAction = async (vinEcu) => {
    const commonValues = buildCommonApiValues(context.profile);
    const values = {
      createdTime: commonValues.createdTime,
      modifiedTime: commonValues.modifiedTime,
      modifiedBy: commonValues.modifiedBy,
      createdBy: commonValues.createdBy,
    };
    const ecuObjects = [];
    selectedEcus.forEach((ecuId) => {
      const foundEcu = selectedEcusInfo.find((x) => x.id === ecuId);
      if (foundEcu) ecuObjects.push(foundEcu);
    });

    vinEcu.ecus = ecuObjects;
    const listOfVinNumbers = csvArray.map((x) => x.vinNumber);
    const formData = form.getFieldsValue();
    const modelFound = vehicleModels.find((x) => x.id === parseInt(autoSearchId));
    const data = {
      updateRequired: true,
      updateStatus: 'SCHEDULED',
      vinNumbers: listOfVinNumbers,
      campaignType: formData.campaignType,
      vinEcusSchedules: ecuObjects,
      modelId: parseInt(autoSearchId),
      ecuCount: selectedEcus.length,
      tenantId: context.profile.tenantId,
      modelName: modelFound.model + '-' + modelFound.variant,
      ...values,
    };
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    addBulkVinEcus(data)
      .then((res) => {
        setCampaignData((state) => [res.data, ...state]);
        setVisibleBulk(false);
        message.success('Succesfully Added VIN ECUs');
      })
      .catch((e) => {
        console.log(e);
        message.error('Unable to add VIN ECUs, try again later');
      })
      .finally(() => {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      });
  };

  //get all campaigns data by pagination
  const onPaginationChange = (e) => {
    init(+e - 1);
  };

  const init = async (page = 0) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    const pagination = {
      size: totalCampaignsInfo.pageSize,
      page: page,
    };

    try {
      const campaignRes = await getAllCampaignsByPagination(pagination);
      const getCampaignData = campaignRes.data?.content || [];
      const modelRes = await getAllVehicleModel();
      const modelData = modelRes.data.filter((x) => x.deleted === false);
      setCampaignData(getCampaignData);
      setCampaignDataOriginal(getCampaignData);
      setVehicleModels(modelData);
      setTotalCampaignsInfo((ps) => ({ ...ps, current: page, items: campaignRes.data.totalElements }));
    } catch (e) {
      console.log(e);
      message.error('Unable to get campaign details, try again later');
    } finally {
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  //For ecu data edit
  const finishAddForEcus = async (type) => {
    if (type === 'save') {
      const data = await formEcu.validateFields();
      setSelectedEcusInfo((ps) => {
        let temp = [...ps];
        let foundEcu = temp.find((ecu) => ecu.id === data.id);
        foundEcu = { ...foundEcu, ...data };
        temp = [...temp.filter((ecu) => ecu.id !== data.id)];
        temp = [...temp, foundEcu];
        return temp;
      });
      onSelectEcu(data, true);
    }
    setChildDrawer(false);
  };

  //Edit ecus
  const [childDrawer, setChildDrawer] = useState(false);
  const editEcuAction = (ecu) => {
    formEcu.setFieldsValue({ ...ecu });
    setChildDrawer(true);
  };

  //onClose edit ecus child drawer
  const onChildDrawerClose = () => {
    setChildDrawer(false);
  };

  //Clear form Data
  const clearForm = () => {
    formEcu.resetFields();
  };

  //Child drawer close
  const closeAddEcu = () => {
    setChildDrawer(false);
    form.resetFields();
  };

  //Selecting the ECUs with checkbox
  const onSelectEcu = (ecu, alwaysAdd = false) => {
    setSelectedEcus((ps) => {
      let temp = [...ps];
      if (temp.includes(ecu.id) && !alwaysAdd) temp = [...temp.filter((x) => x !== ecu.id)];
      else temp = [...temp, ecu.id];
      return temp;
    });
  };

  //Based on vehicle model selected ,getting the list of ECUs
  const [autoSearchId, setAutoSearchId] = useState(0);
  const onSelect = async (val, option) => {
    setAutoSearchId(option.key.split('-')[0]);
    const vehicleModelRes = await getAllVehicleModelsForCampaign(option.key.split('-')[0]);
    const vehicleModelData = vehicleModelRes.data?.ecus;
    setSelectedEcusInfo(vehicleModelData);
  };

  //onSearch campaign data with model name
  const [tableModeNormal, setTableModeNormal] = useState(true);
  const onSearchModelName = async (e) => {
    if (!e.target.value) {
      setCampaignData(campaignDataOriginal);
      setTableModeNormal(true);
    } else {
      try {
        setCampaignData(() => {
          const tempData = [...campaignData];
          return tempData
            .filter((x) => x.modelName.split('-')[0].toLowerCase().includes(e.target.value.toLowerCase()))
            .filter((x) => x.deleted === false);
        });
        setTableModeNormal(false);
      } catch {
        message.error('Could not retrieve model name');
      }
    }
  };

  //Campagin table columns
  const tableCols = [
    { title: <strong>Campaign </strong>, key: 'campaignType', dataIndex: 'campaignType' },
    {
      title: <strong>Model</strong>,
      key: 'modelName',
      dataIndex: 'modelName',
    },
    {
      title: <strong>VINs </strong>,
      render: (record) => (
        <Tooltip placement="right" title={record.vinNumbers.join(',')}>
          {record.vinNumbers.slice(0, 2).join(',')}
          {record.vinNumbers.length > 2 ? (
            <Tag color="blue" className="ml-1">
              {`+${record.vinNumbers.length - 2} more`}
            </Tag>
          ) : (
            ''
          )}
        </Tooltip>
      ),
    },
    { title: <strong>Ecus</strong> },
  ];

  //Table for bulk uploading VIN ECUs
  const bulkUploadVinEcuCols = [
    {
      title: '',
      render: (ecu) => <Checkbox onChange={() => onSelectEcu(ecu)} checked={selectedEcus.includes(ecu.id)}></Checkbox>,
    },
    { title: <strong>ECU Name</strong>, render: (record) => record.ecuName },
    { title: <strong>ECU Version</strong>, render: (record) => record.ecuVersion },
    {
      title: <strong> Actions </strong>,
      key: 'Actions',
      render: (record) => (
        <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN} permission={CRUD.UPDATE}>
          <Button type="link" className="actionButton" onClick={() => editEcuAction(record)}>
            Edit
          </Button>
        </PermissionContainer>
      ),
    },
  ];

  //Table column for VIN numbers from excel
  const bulkUploadVinNumberCol = [
    {
      title: <strong>VIN Number</strong>,
      render: (record) => (
        <Tooltip placement="right" title={record.join(',')}>
          {record.slice(0, 4).join(',')}
          {record.length > 2 ? (
            <>
              <Tag color="blue" className="ml-1">{`+${record.length - 2} more`}</Tag>
            </>
          ) : (
            ''
          )}
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={[ModuleNames.CAMPAIGN]} />
        </Col>
        <Col>
          <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN} permission={CRUD.ADD}>
            <Button type="link" className="commonAddButton actionButton" onClick={openAdd} icon={<PlusOutlined />}>
              Add Campaign
            </Button>
          </PermissionContainer>
        </Col>
      </Row>
      <Row className="searchDiv" justify="end">
        <Col xs={24} lg={6}>
          <Search placeholder="Enter Model Variant" allowClear enterButton onChange={onSearchModelName} />
        </Col>
      </Row>
      <>
        {!context.isCompact ? (
          <>
            {tableModeNormal ? (
              <>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  rowKey="vinCampTransactionId"
                  columns={tableCols}
                  dataSource={campaignData}
                  rowClassName={(record) => record.deleted && 'rowInactive'}
                  pagination={false}
                />
                <Row justify="end">
                  <Col>
                    <div className="m-2">
                      <Pagination
                        onChange={onPaginationChange}
                        current={totalCampaignsInfo.current + 1}
                        total={totalCampaignsInfo.items}
                        defaultPageSize={totalCampaignsInfo.pageSize}
                        showSizeChanger={false}
                        showQuickJumper={false}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Table
                size="small"
                scroll={{ x: true }}
                rowKey="vinCampTransactionId"
                columns={tableCols}
                dataSource={campaignData}
                rowClassName={(record) => record.deleted && 'rowInactive'}
                pagination={false}
              />
            )}
          </>
        ) : (
          <>
            <CommonCompactView
              data={campaignData}
              permissions={[
                { pageName: Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN, permission: CRUD.UPDATE, label: 'Edit' },
                { pageName: Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN, permission: CRUD.DELETE, label: 'Delete' },
              ]}
              title="vinNumber"
              dataList={[{ label: 'VIN Number', value: 'vinNumbers', type: 'join' }]}
            />
            <Row justify="end">
              <Col>
                <div className="m-2">
                  <Pagination
                    onChange={onPaginationChange}
                    current={totalCampaignsInfo.current + 1}
                    total={totalCampaignsInfo.items}
                    defaultPageSize={totalCampaignsInfo.pageSize}
                    showSizeChanger={false}
                    showQuickJumper={false}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
      <Drawer
        width={calcDrawerWidth(true)}
        className="commonDrawer"
        onClose={closeAddBulk}
        bodyStyle={{ padding: 0 }}
        visible={visibleBulk}
        getContainer={false}
      >
        <Title className="title">Bulk Campaigns</Title>
        <Row justify="start" gutter={6} className="content">
          <Form
            initialValues={formInitValues}
            scrollToFirstError={true}
            autoComplete={'new-password'}
            layout="horizontal"
            form={form}
            className="d-flex"
          >
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Vehicle Model"
              name="vehicleModel"
              rules={[
                {
                  required: true,
                  message: 'Please input vehicle model!',
                },
              ]}
            >
              <Select onSelect={(val, option) => onSelect(val, option)} placeholder="Vehicle Model">
                {vehicleModels.map((a) => (
                  <Option key={`${a.id}-${a.vehicleManufacturer}`} value={`${a.model}-${a.variant}`}>
                    <Tooltip placement="right" title={`${a.model} - ${a.variant}`}>
                      {a.model} - {a.variant}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Campaign"
              name="campaignType"
              className="pl-3"
              rules={[
                {
                  required: true,
                  message: 'Please input campaign type!',
                },
              ]}
            >
              <Input placeholder="Campaign Type" />
            </Form.Item>
          </Form>
          <Col>
            <Link to="/sample_format_for_vin_ecus_upload.csv" target="_blank" download>
              <Button type="link">
                Sample File
                <DownloadOutlined />
              </Button>
            </Link>
          </Col>
          <Col>
            <Upload
              accept=".csv"
              multiple={false}
              maxCount={1}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                  processCSV(e.target.result);
                };
                reader.readAsText(file);
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}> Upload</Button>
            </Upload>
          </Col>
          <Col>
            <h4 className={s.warningInfo}>{!isTrue && warningMsg}</h4>
          </Col>
        </Row>
        <div className={s.tableContainer}>
          <Table
            size="small"
            scroll={{ x: true }}
            rowKey="vinEcuTransactionId"
            columns={bulkUploadVinEcuCols}
            dataSource={selectedEcusInfo}
            rowClassName={(record) => record.deleted && 'rowInactive'}
            pagination={false}
            className="m-3"
          />
          <div>
            <Table
              size="small"
              scroll={{ x: true }}
              rowKey="vinCampTransactionId"
              loading={context.isLoading}
              columns={bulkUploadVinNumberCol}
              dataSource={[csvData]}
              rowClassName={(record) => record.deleted && 'rowInactive'}
              className="m-4"
              pagination={false}
            />
          </div>

          <Row gutter={10} className={`${s.btnContainer} m-3`}>
            <Col lg={{ span: 4 }}>
              <Button block type="primary" onClick={saveAllVinsAction} disabled={selectedEcus.length === 0}>
                Create Bulk VINs
              </Button>
            </Col>
            <Col lg={{ span: 4 }}>
              <Button block danger type="primary" onClick={closeAddBulk}>
                Close
              </Button>
            </Col>
          </Row>
        </div>
        <Drawer
          title={<Title className={s.title}>Ecu</Title>}
          width={context.isCompact ? 400 : 550}
          visible={childDrawer}
          onClose={onChildDrawerClose}
          className={s.drawer}
        >
          <Form
            initialValues={formInitValues}
            scrollToFirstError={true}
            autoComplete={'new-password'}
            layout="horizontal"
            form={formEcu}
            wrapperCol={{ span: 16 }}
            labelCol={{ span: 8 }}
          >
            <Form.Item hidden={true} name="id" />
            <Form.Item shouldUpdate={true} hasFeedback label="Name" name="ecuName">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="External ID" name="ecuExtID">
              <Input placeholder="External ID" />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Model" name="ecuModel">
              <Input placeholder="Model" />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Make" name="ecuMake">
              <Input placeholder="Make" />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Vehicle Manufacturer" name="vehicleManufacturer">
              <Select placeholder="Vehicle Manufacturer">
                {VehicleManufacturers.map((m) => (
                  <Option title={m} key={m} value={m}>
                    {m}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Software Version" name="ecuVersion">
              <Input placeholder="Software Version" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Name"
              name="fileName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file name!',
                },
              ]}
            >
              <Input placeholder="File Name" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Type"
              name="fileType"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file type!',
                },
              ]}
            >
              <Input placeholder="File Tye" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Location"
              name="fileLocation"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file location!',
                },
              ]}
            >
              <Input placeholder="File Location" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="User Name"
              name="userName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input user name!',
                },
              ]}
            >
              <Input placeholder="User Name" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input password!',
                },
              ]}
            >
              <Input placeholder="Password" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Sftp Url"
              name="sftpUrl"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input sftpUrl!',
                },
              ]}
            >
              <Input placeholder="Sftp Url" />
            </Form.Item>
            <Row gutter={6}>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
                <Button block className="commonSaveButton formButton" onClick={() => finishAddForEcus('save')}>
                  Save
                </Button>
              </Col>
            </Row>
            <Row className="footer" gutter={6}>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
                <Button
                  block
                  className={['clearButton', !context.isCompact ? 'clear' : null]}
                  onClick={() => clearForm()}
                >
                  Clear
                </Button>
              </Col>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 0, span: 9 }}>
                <Button block danger type="primary" onClick={closeAddEcu}>
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Drawer>
    </>
  );
};

export default Campaign;
