import axios from '../axios';
import axiosAlternate from '../axiosAlternate';

const SENSOR_HEALTH_URI = '/sensor/health/';
const SENSOR_PROXIMITY_URI = '/sensor/proximity/';
const SENSOR_TEMP = '/sensor/temp/';
const SENSOR_HUMIDITY = '/sensor/humid/';
const SENSOR_AMBLIGHT = '/sensor/ambl/';
const SENSOR_PRESSURE = '/sensor/pressure/';
const RFID_URI = '/report/rfid/';

export const getSensorInitialData = (tenantId, devLogId, attrName, params) => {
  return axios.get(`${SENSOR_HEALTH_URI}byType/${tenantId}/${devLogId}/${attrName}`, params);
};

export const getGatewaySensorInitialData = (tenantId, deviceId, attributeName, params = {}) => {
  return axios.post(`${SENSOR_HEALTH_URI}initial/aggr`, { tenantId, deviceId, attributeName, ...params });
};

export const getSensorDataByDate = (data) => {
  return axios.post(`${SENSOR_HEALTH_URI}date`, data);
};

export const getSensorProximityInitial = (sourceId) => {
  return axios.get(`${SENSOR_PROXIMITY_URI}initial/${sourceId}`);
};

export const getSensorProximityInitialByLocation = (tenantId, locationId) => {
  return axios.get(`${SENSOR_PROXIMITY_URI}initial/${tenantId}/${locationId}`);
};

export const getSensorProximityByDate = (tenantId, deviceId, fromDate, toDate) => {
  return axios.post(`${SENSOR_PROXIMITY_URI}date`, { tenantId, deviceId, fromDate, toDate });
};

export const getTempSensorInitialData = (tenantId, devLogId) => {
  return axios.get(`${SENSOR_TEMP}initial/${tenantId}/${devLogId}`);
};

export const getHumiditySensorInitialData = (tenantId, devLogId) => {
  return axios.get(`${SENSOR_HUMIDITY}initial/${tenantId}/${devLogId}`);
};

export const getAmblightSensorInitialData = (tenantId, devLogId) => {
  return axios.get(`${SENSOR_AMBLIGHT}initial/${tenantId}/${devLogId}`);
};

export const getPressureSensorInitialData = (tenantId, devLogId) => {
  return axios.get(`${SENSOR_PRESSURE}initial/${tenantId}/${devLogId}`);
};

export const getTempSensorMetrics = (tenantId, deviceId) => {
  return axios.post(`${SENSOR_TEMP}initial/aggr/`, { tenantId, deviceId });
};

export const getHumiditySensorMetrics = (tenantId, deviceId) => {
  return axios.post(`${SENSOR_HUMIDITY}initial/aggr/`, { tenantId, deviceId });
};

export const getAmblightSensorMetrics = (tenantId, deviceId) => {
  return axios.post(`${SENSOR_AMBLIGHT}initial/aggr/`, { tenantId, deviceId });
};

export const getPressureSensorMetrics = (tenantId, deviceId) => {
  return axios.post(`${SENSOR_PRESSURE}initial/aggr/`, { tenantId, deviceId });
};

export const getRfidReport = (tenantId, fromDate, toDate) => {
  return axiosAlternate.get(`${RFID_URI}${tenantId}/${fromDate}/${toDate}`);
};
export const getSensorHealthByDate = (tenantId, attributeName, toDate, fromDate, deviceId) => {
  return axios.post(`${SENSOR_HEALTH_URI}date/aggr`, { tenantId, attributeName, toDate, fromDate, deviceId });
};
export const getTempSensorByDate = (tenantId, toDate, fromDate, deviceId) => {
  return axios.post(`${SENSOR_TEMP}date/aggr`, { tenantId, toDate, fromDate, deviceId });
};
export const getPressureSensorByDate = (tenantId, toDate, fromDate, deviceId) => {
  return axios.post(`${SENSOR_PRESSURE}date/aggr`, { tenantId, toDate, fromDate, deviceId });
};
export const getHumidSensorByDate = (tenantId, toDate, fromDate, deviceId) => {
  return axios.post(`${SENSOR_HUMIDITY}date/aggr`, { tenantId, toDate, fromDate, deviceId });
};
export const getAmblSensorByDate = (tenantId, toDate, fromDate, deviceId) => {
  return axios.post(`${SENSOR_AMBLIGHT}date/aggr`, { tenantId, toDate, fromDate, deviceId });
};
