import React from 'react';
import { Row, Col } from '../../components';
import { Link } from 'react-router-dom';
import s from './index.module.less';

const Footer = () => {
  return (
    <Row className={s.footerContainer} align="middle" justify="center" gutter={10}>
      <Col>
        <Link to="/privacy"> Privacy Policy </Link>
      </Col>
      <Col>
        <Link to="/privacy"> Cookies Policy </Link>
      </Col>
      <Col> © {new Date().getFullYear()} Spidex. All Rights Reserved. </Col>
    </Row>
  );
};

export default Footer;
