import { useEffect, useState, useRef } from 'react';
import { Row, Col, message } from '../../components';
import { Drawer, Spin, Typography, Button } from '../index';
import { calcDrawerWidth } from '../../utils';
import { Loader } from '@googlemaps/js-api-loader';
import * as config from '../../config';
import s from './index.module.less';

const { Title } = Typography;

const GoogleMapW3W = ({ setIsMaps, onCloseUpdate }) => {
  const [mapLoader, setMapLoader] = useState(null);
  const currentMarker = useRef(null);
  const w3Marker = useRef(null);
  const [allowClose, setAllowClose] = useState(true);

  const addMarker = (google, location, map) => {
    const marker = new google.maps.Marker({
      position: location,
      map: map,
    });
    window.what3words.api
      .convertTo3wa({ lat: location.lat(), lng: location.lng() }, 'en')
      .then((res) => {
        onCloseUpdate({
          geoJson: res.words,
          gpsPoint: {
            latitude: res.coordinates.lat,
            longitude: res.coordinates.lng,
          },
          latitude: res.coordinates.lat,
          longitude: res.coordinates.lng,
        });
        setAllowClose(false);
      })
      .catch((e) => {
        console.log('e', e);
      });
    currentMarker.current = marker;
    if (w3Marker.current) w3Marker.current.setMap(null);
  };

  const removeMarker = () => {
    if (currentMarker.current) {
      currentMarker.current.setMap(null);
    }
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.REACT_APP_MAP_GOOGLE_API_KEY,
      libraries: ['places'],
      version: 'weekly',
    });
    setMapLoader(loader);
  }, []);

  useEffect(() => {
    let map;
    let gridData;
    if (mapLoader) {
      mapLoader?.load().then((google) => {
        let currentPos = JSON.parse(localStorage.getItem('storedGoogleLocation') || '{"lat": -34.397, "lng": 150.644}');
        map = new google.maps.Map(document.getElementById('google-map'), {
          center: currentPos,
          zoom: 12,
        });
        const input = document.getElementById('google-map-input');
        const searchBox = new google.maps.places.SearchBox(input);

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length === 0) {
            return;
          }
          // For each place, get the icon, name and location.
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log('Returned place contains no geometry');
              return;
            }

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });
        google.maps.event.addListener(map, 'click', (event) => {
          removeMarker();
          addMarker(google, event.latLng, map);
        });

        const locationButton = document.createElement('button');
        locationButton.textContent = 'Pan to Current Location';
        locationButton.classList.add('custom-map-control-button');
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
        locationButton.addEventListener('click', () => {
          // Try HTML5 geolocation.
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                map.setCenter(pos);
                localStorage.setItem('storedGoogleLocation', JSON.stringify(pos));
              },
              () => {
                message.error('Location denied');
              }
            );
          } else {
            // Browser doesn't support Geolocation
            message.error('Browser does not support location API');
          }
        });
        map.addListener('bounds_changed', function () {
          const zoom = map.getZoom();
          const loadFeatures = zoom > 17;

          if (loadFeatures) {
            var ne = map.getBounds().getNorthEast();
            var sw = map.getBounds().getSouthWest();
            window.what3words.api
              .gridSectionGeoJson({
                southwest: {
                  lat: sw.lat(),
                  lng: sw.lng(),
                },
                northeast: {
                  lat: ne.lat(),
                  lng: ne.lng(),
                },
              })
              .then(function (data) {
                if (gridData !== undefined) {
                  for (var i = 0; i < gridData.length; i++) {
                    map.data.remove(gridData[i]);
                  }
                }
                gridData = map.data.addGeoJson(data);
              })
              .catch(console.error);
          }
          map.data.setStyle({
            visible: loadFeatures,
            strokeColor: '#777',
            strokeWeight: 0.5,
            clickable: false,
          });
        });
        const autosuggest = document.getElementById('w3w-autosuggest');
        autosuggest.addEventListener('selected_suggestion', (value) => {
          window.what3words.api.convertToCoordinates(value.detail.suggestion.words).then((response) => {
            let w3pos = { lat: response.coordinates.lat, lng: response.coordinates.lng };
            map.setCenter(w3pos);
            map.setZoom(20);
            w3Marker.current = new google.maps.Marker({
              position: w3pos,
              map: map,
            });
          });
        });
      });
    }
    // eslint-disable-next-line
  }, [mapLoader]);

  return (
    <Drawer
      width={calcDrawerWidth(true)}
      className={s.drawer}
      onClose={() => setIsMaps(false)}
      bodyStyle={{ padding: 0 }}
      visible={true}
    >
      <Spin spinning={false}>
        <Title className={s.title}>Get Geo Info - Google Maps - What 3 Words</Title>
        <Row className={s.mapHeader} justify="start">
          <Col xs={12}>
            <input id="google-map-input" placeholder="Search Google"></input>
          </Col>
          <Col xs={12}>
            <what3words-autosuggest id="w3w-autosuggest" api_key={config.REACT_APP_W3W_API_KEY}>
              <input type="text" />
            </what3words-autosuggest>
          </Col>
        </Row>
        <Row className={s.googleRow}>
          <Col className={s.googleCol}>
            <div id="google-map"></div>
          </Col>
        </Row>
        <Button className={s.saveButton} type="primary" onClick={() => setIsMaps(false)} disabled={allowClose}>
          Save & Close
        </Button>
      </Spin>
    </Drawer>
  );
};

export default GoogleMapW3W;
