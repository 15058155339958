import axiosAlternate from '../axiosAlternate';
import axios from '../axios';

const VENDOR_URI = '/vendor/';
const WORKER_URI = '/worker/';
const VEHICLE_URI = '/vechile/';
const REPORT_URI = '/report/';
const WASHROOM_URI = '/washroom/';
const ECU_URI = '/ecu/';
const VIN_URI = '/vin/';
const VEHICLE_MODEL_URI = '/vehiclemodel/';
const MODEL_ECU_URI = '/modelecu/';
const VIN_ECUS_URI = '/vinecu/';

export const getAllVendors = (tenantId) => {
  return axiosAlternate.get(`${VENDOR_URI}all`, { params: { tenantId } });
};

export const getVendorsCount = (tenantId) => {
  return axiosAlternate.get(`${VENDOR_URI}total/${tenantId}`);
};

export const getAllVendorsByPagination = (tenantId, paginationFilters) => {
  return axios.get(`${VENDOR_URI}allBy`, { params: { tenantId, ...paginationFilters } });
};

export const getSearchVendors = (tenantId, search) => {
  return axiosAlternate.get(`${VENDOR_URI}search`, { params: { text: search, tenantId } });
};

export const getVendor = (vendorId) => {
  return axiosAlternate.get(`${VENDOR_URI}${vendorId}`);
};

export const addVendor = (vendor) => {
  return axiosAlternate.post(`${VENDOR_URI}`, vendor);
};

export const updateVendor = (vendor) => {
  return axiosAlternate.put(`${VENDOR_URI}`, vendor);
};

export const deleteVendor = (vendorId) => {
  return axiosAlternate.delete(`${VENDOR_URI}${vendorId}`);
};

export const getAllWorkers = (tenantId) => {
  return axiosAlternate.get(`${WORKER_URI}all`, { params: { tenantId } });
};

export const getWorkersCount = (tenantId) => {
  return axiosAlternate.get(`${WORKER_URI}total/${tenantId}`);
};

export const getAllWorkersByPagination = (tenantId, paginationFilters, vendorId = null) => {
  let params = { tenantId, ...paginationFilters };
  if (vendorId) params.vendorId = vendorId;
  return axios.get(`${WORKER_URI}allBy`, { params: params });
};

export const getSearchWorkers = (tenantId, search) => {
  return axiosAlternate.get(`${WORKER_URI}search`, { params: { text: search, tenantId } });
};

export const getWorker = (workerId) => {
  return axiosAlternate.get(`${WORKER_URI}${workerId}`);
};

export const addWorker = (worker) => {
  return axiosAlternate.post(`${WORKER_URI}`, worker);
};

export const updateWorker = (worker) => {
  return axiosAlternate.put(`${WORKER_URI}`, worker);
};

export const deleteWorker = (workerId) => {
  return axiosAlternate.delete(`${WORKER_URI}${workerId}`);
};

export const getAllVehicles = (tenantId) => {
  return axiosAlternate.get(`${VEHICLE_URI}all`, { params: { tenantId } });
};

export const getVehiclesCount = (tenantId) => {
  return axiosAlternate.get(`${VEHICLE_URI}total/${tenantId}`);
};

export const getAllVehiclesByPagination = (tenantId, paginationFilters, vendorId) => {
  let params = { tenantId, ...paginationFilters };
  if (vendorId) params.vendorId = vendorId;
  return axios.get(`${VEHICLE_URI}allBy`, { params: params });
};

export const getVehicle = (vehicleId) => {
  return axiosAlternate.get(`${VEHICLE_URI}${vehicleId}`);
};

export const getSearchVehicles = (tenantId, search) => {
  return axiosAlternate.get(`${VEHICLE_URI}search`, { params: { text: search, tenantId } });
};

export const addVehicle = (vehicle) => {
  return axiosAlternate.post(`${VEHICLE_URI}`, vehicle);
};

export const updateVehicle = (vehicle) => {
  return axiosAlternate.put(`${VEHICLE_URI}`, vehicle);
};

export const deleteVehicle = (vehicleId) => {
  return axiosAlternate.delete(`${VEHICLE_URI}${vehicleId}`);
};

export const vendorWiseReport = (tenantId, date, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}vendors/${tenantId}/${date}/${vendorId}`);
};

export const workerVendorWiseReport = (tenantId, date, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}workers/${tenantId}/${date}/${vendorId}`);
};

export const vendorWiseReportInRange = (tenantId, fromDate, toDate, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}vendorDaywise/${tenantId}/${fromDate}/${toDate}/${vendorId}`);
};

export const vehicleWiseReport = (tenantId, date, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}vehicle/${tenantId}/${date}/${vendorId}`);
};

export const areaWiseReport = (tenantId, date, areaId) => {
  return axiosAlternate.get(`${REPORT_URI}worker/area/${tenantId}/${date}/${areaId}`);
};

export const monthlyBillingReport = (tenantId, fromDate, toDate, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}worker/monthly/${tenantId}/${fromDate}/${toDate}/${vendorId}`);
};

export const dayBillingReport = (tenantId, fromDate, toDate, vendorId) => {
  return axiosAlternate.get(`${REPORT_URI}worker/daywise/${tenantId}/${fromDate}/${toDate}/${vendorId}`);
};

export const washroomsGetAll = () => {
  return axios.get(`${WASHROOM_URI}all`);
};

export const washroomGetOne = (id) => {
  return axios.get(`${WASHROOM_URI}${id}`);
};

export const washroomPost = (data) => {
  return axios.post(`${WASHROOM_URI}`, data);
};
export const getEcu = (ecuId) => {
  return axiosAlternate.get(`${ECU_URI}${ecuId}`);
};
export const getAllEcu = () => {
  return axiosAlternate.get(`${ECU_URI}all`);
};
export const getAllEcuByPagination = (paginationFilters, status) => {
  const params = { ...paginationFilters };
  if (status === true) params.status = 'both';
  else params.status = 'active';
  return axios.get(`${ECU_URI}allby`, { params: params });
};
export const addEcu = (ecu) => {
  return axiosAlternate.post(`${ECU_URI}`, ecu);
};

export const updateEcu = (ecu) => {
  return axiosAlternate.put(`${ECU_URI}`, ecu);
};

export const deleteEcu = (ecuId) => {
  return axiosAlternate.delete(`${ECU_URI}${ecuId}`);
};
export const getVin = (vinId) => {
  return axiosAlternate.get(`${VIN_URI}${vinId}`);
};
export const getAllVin = () => {
  return axiosAlternate.get(`${VIN_URI}all`);
};
export const getVinInfoByVinNumber = (vinNumber) => {
  return axiosAlternate.get(`${VIN_URI}info/${vinNumber}`);
};
export const getAllVinByPagination = (paginationFilters, status) => {
  const params = { ...paginationFilters };
  if (status === true) params.status = 'both';
  else params.status = 'active';
  return axios.get(`${VIN_URI}allby`, { params: params });
};
export const addVin = (vin) => {
  return axiosAlternate.post(`${VIN_URI}`, vin);
};
export const addAllVin = () => {
  return axiosAlternate.post(`${VIN_URI}/all`);
};

export const updateVin = (vin) => {
  return axiosAlternate.put(`${VIN_URI}`, vin);
};

export const deleteVin = (vinId) => {
  return axiosAlternate.delete(`${VIN_URI}${vinId}`);
};
export const getVehicleModel = (VehicleModelId) => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}${VehicleModelId}`);
};
export const getAllVehicleModel = () => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}all`);
};
export const getAllVehicleModelByPagination = (paginationFilters, status) => {
  const params = { ...paginationFilters };
  if (status === true) params.status = 'both';
  else params.status = 'active';
  return axios.get(`${VEHICLE_MODEL_URI}allby`, { params: params });
};
export const addVehicleModel = (vehicleModel) => {
  return axiosAlternate.post(`${VEHICLE_MODEL_URI}`, vehicleModel);
};

export const updateVehicleModel = (vehicleModel) => {
  return axiosAlternate.put(`${VEHICLE_MODEL_URI}`, vehicleModel);
};

export const deleteVehicleModel = (VehicleModelId) => {
  return axiosAlternate.delete(`${VEHICLE_MODEL_URI}${VehicleModelId}`);
};
export const getSearchVehicleManufacturer = (manufacturer) => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}search/${manufacturer}`);
};
export const getSearchVehicleModel = (model) => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}search/model/${model}`);
};
export const getModelEcu = (modelecuId) => {
  return axiosAlternate.get(`${MODEL_ECU_URI}${modelecuId}`);
};
export const getAllModelEcu = () => {
  return axiosAlternate.get(`${MODEL_ECU_URI}all`);
};
export const getAllModelEcuByPagination = (paginationFilters, status) => {
  const params = { ...paginationFilters };
  if (status === true) params.status = 'both';
  else params.status = 'active';
  return axios.get(`${MODEL_ECU_URI}allby`, { params: params });
};
export const addModelEcu = (modelecu) => {
  return axiosAlternate.post(`${MODEL_ECU_URI}`, modelecu);
};

export const updateModelEcu = (modelecu) => {
  return axiosAlternate.put(`${MODEL_ECU_URI}`, modelecu);
};

export const deleteModelEcu = (modelecuId) => {
  return axiosAlternate.delete(`${MODEL_ECU_URI}${modelecuId}`);
};
export const getAllEcuData = (text) => {
  return axiosAlternate.get(`${ECU_URI}/manufacturer/${text}`);
};
export const getVinEcu = (vinId) => {
  return axiosAlternate.get(`${VIN_ECUS_URI}${vinId}`);
};
export const getAllVinEcu = () => {
  return axiosAlternate.get(`${VIN_ECUS_URI}all`);
};
export const getAllVinEcuByPagination = (paginationFilters, vin, model, status = 'SCHEDULED') => {
  return axios.get(`${VIN_ECUS_URI}allby`, { params: { ...paginationFilters, vin, model, status } });
};
export const getVinEcuByVinNumberAndModel = (vinNumber, model) => {
  return axiosAlternate.get(`${VIN_ECUS_URI}${vinNumber}/${model}/create`);
};
export const getVinEcusByDate = (fromDate, toDate, status) => {
  return axiosAlternate.get(`${VIN_ECUS_URI}count/date`, {
    params: { status, fromDate, toDate },
  });
};
export const addVinEcu = (vinecu) => {
  return axiosAlternate.post(`${VIN_ECUS_URI}`, vinecu);
};
export const updateVinEcu = (vinecu) => {
  return axiosAlternate.put(`${VIN_ECUS_URI}`, vinecu);
};
export const deleteVinEcu = (id) => {
  return axiosAlternate.delete(`${VIN_ECUS_URI}${id}`);
};
export const addAllVins = (vin) => {
  return axiosAlternate.post(`${VIN_URI}all`, vin);
};
export const addBulkVinEcus = (model) => {
  return axiosAlternate.post(`${VIN_ECUS_URI}model/`, model);
};
export const getSchedulesUpdateCall = (vinNumber, model) => {
  return axiosAlternate.get(`${VIN_ECUS_URI}${vinNumber}/${model}/update`);
};
export const getAllVehicleModelsForCampaign = (id) => {
  return axiosAlternate.get(`${MODEL_ECU_URI}model/${id}`);
};
export const getAllCampaignsByPagination = (paginationFilters, model, status = 'SCHEDULED') => {
  return axiosAlternate.get(`${VIN_ECUS_URI}campaign/allby`, { params: { ...paginationFilters, model, status } });
};
export const getVinCountByManufacture = (vehicleManufacture) => {
  return axiosAlternate.get(`${VIN_URI}count/${vehicleManufacture}`);
};
export const getModelCountByManufacture = (vehicleManufacture) => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}count/${vehicleManufacture}`);
};
export const getEcusCountByManufacture = (vehicleManufacture) => {
  return axiosAlternate.get(`${ECU_URI}count/${vehicleManufacture}`);
};
export const getvehiclemodelVariantByManufacture = (vehicleManufacture) => {
  return axiosAlternate.get(`${VEHICLE_MODEL_URI}count/variant/${vehicleManufacture}`);
};
