import { useEffect, useState, useContext } from 'react';
import {
  Input,
  Checkbox,
  Table,
  Button,
  Form,
  Popconfirm,
  CommonDrawer,
  CommonCompactView,
  message,
  Row,
  Col,
  Pagination,
  Drawer,
  Typography,
  Select,
  Tooltip,
  Tag,
} from '../../../components';
import {
  getAllVinEcuByPagination,
  addVinEcu,
  getVinEcuByVinNumberAndModel,
  getSchedulesUpdateCall,
  updateVinEcu,
  deleteVinEcu,
  getAllVin,
  getAllVinEcu,
} from '../../../services';
import Context from '../../../context';
import { PlusOutlined } from '@ant-design/icons';
import { BreadcrumbList, PermissionContainer } from '../../../shared';
import { buildCommonApiValues } from '../../../utils';
import { CRUD, Pages, ModuleNames, MastersPageSizeDefault } from '../../../constants';
import s from './index.module.less';

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Schedules = () => {
  const [context, setContext] = useContext(Context);
  const [action, setAction] = useState('new');
  const [totalVinEcus, setTotalVinEcus] = useState({
    items: 0,
    current: 0,
    pageSize: MastersPageSizeDefault,
  });
  const [showDeleted, setShowDeleted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [vinEcu, setVinEcu] = useState([]);
  const [vinEcuOriginal, setVinEcuOriginal] = useState([]);
  const [vinEcusInfo, setVinEcusInfo] = useState({});
  const [vinEcuInfo, setVinEcuInfo] = useState(false);
  const [vinEcusSchedules, setVinEcusSchedules] = useState([]);
  const [vinData, setVinData] = useState([]);
  const [selectedEcus, setSelectedEcus] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [allEcus, setAllEcus] = useState([]);

  const [form] = Form.useForm();
  const [formEcu] = Form.useForm();

  const formInitState = {};
  const [formInitValues, setFormInitValues] = useState({ ...formInitState });

  const openAdd = () => {
    setAction('new');
    setVisible(true);
    setVinEcusSchedules([]);
    setSelectedEcus([]);
  };

  const finishAdd = async (type) => {
    try {
      const values = await form.validateFields();
      const commonValues = buildCommonApiValues(context.profile);

      if (action === 'new') {
        await saveVinEcuAction({
          createdTime: commonValues.createdTime,
          modifiedTime: commonValues.modifiedTime,
          modifiedBy: commonValues.modifiedBy,
          createdBy: commonValues.createdBy,
          deleted: commonValues.deleted,
          ...values,
        });
        if (type === 'save') setVisible(false);
        if (type === 'add') form.resetFields();
      }
      if (action === 'edit') {
        updateVinEcuCall(values);
        if (type === 'add') form.resetFields({});
        setFormInitValues(null);
        setVisible(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //Save VIN ECU data
  const saveVinEcuAction = async (vinEcu) => {
    const commonValues = buildCommonApiValues(context.profile);
    const values = {
      createdTime: commonValues.createdTime,
      modifiedTime: commonValues.modifiedTime,
      modifiedBy: commonValues.modifiedBy,
      createdBy: commonValues.createdBy,
    };
    const ecuObjects = [];
    selectedEcus.forEach((ecuName) => {
      const foundEcu = vinEcusSchedules.find((x) => x.ecuName === ecuName);
      if (foundEcu) ecuObjects.push(foundEcu);
    });

    vinEcu.vinEcusSchedules = ecuObjects;
    const matchedVehicleModel = vinData.find((x) => x.vinNumber === vinEcu.vinNumber)?.vehicleModel || 'NA';
    const formData = form.getFieldsValue();
    const campaignTypeFound = campaignData.find((x) => x.vinNumber === formData.vinNumber)?.campaignType || 'NA';
    const modelNameFound = vinData.find((x) => x.vinNumber === formData.vinNumber)?.modelName || 'NA';
    const data = {
      updateRequired: true,
      updateStatus: 'SCHEDULED',
      vinNumber: formData.vinNumber,
      vinEcusSchedules: selectedEcus,
      ecuCount: selectedEcus.length,
      tenantId: context.profile.tenantId,
      modelId: matchedVehicleModel,
      modelName: modelNameFound,
      campaignType: campaignTypeFound,
      ...values,
    };
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    addVinEcu(data)
      .then((res) => {
        setVinEcu((state) => [res.data, ...state]);
        message.success('Succesfully Added VIN ECU');
        form.resetFields();
        setFormInitValues(null);
      })
      .catch((e) => {
        console.log(e);
        message.error('Unable to add  VIN ECU, try again later');
      })
      .finally(() => {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      });
  };

  //update VIN ECU data
  const editVinEcuAction = async (vinEcuData) => {
    form.setFieldsValue(vinEcuData);
    setAction('edit');
    setVinEcusInfo(vinEcuData);
    setVinEcusSchedules(vinEcuData.vinEcusSchedules);
    setSelectedEcus(vinEcuData.vinEcusSchedules);
    setVisible(true);

    const matchedVehicleModel = vinData.find((x) => x.vinNumber === vinEcuData.vinNumber)?.vehicleModel || 'NA';
    const vinNumberSearch = await getSchedulesUpdateCall(vinEcuData.vinNumber, matchedVehicleModel);
    const vinNumberSearchData = vinNumberSearch.data;
    setAllEcus(vinNumberSearchData);
  };

  const updateVinEcuCall = (vinsData) => {
    const commonValues = buildCommonApiValues(context.profile);
    const values = {
      createdTime: commonValues.createdTime,
      modifiedTime: commonValues.modifiedTime,
      modifiedBy: commonValues.modifiedBy,
      createdBy: commonValues.createdBy,
    };

    const ecuObjects = [];
    selectedEcus.forEach((ecuName) => {
      const foundEcu = vinEcusSchedules.find((x) => x.ecuName === ecuName);
      if (foundEcu) ecuObjects.push(foundEcu);
    });

    vinEcu.vinEcusSchedules = ecuObjects;
    const modelFound = vinData.find((x) => x.vinNumber === vinsData.vinNumber);
    const data = {
      updateRequired: true,
      updateStatus: 'SCHEDULED',
      ...vinEcusInfo,
      ...values,
      tenantId: context.profile.tenantId,
      modelName: modelFound.modelName,
      vinEcusSchedules: selectedEcus,
      modifiedTime: new Date(),
    };
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    updateVinEcu(data)
      .then((res) => {
        setVinEcu((state) => {
          const tempData = [...state];
          tempData.forEach((i) => {
            if (i.vinTransactionId === res.data.vinTransactionId) {
              Object.assign(i, res.data);
            }
          });
          return tempData;
        });
        const nullFound = selectedEcus.forEach((x) => {
          (x.ecuVersion || x.fileLocation || x.fileName || x.fileType || x.userName || x.password || x.sftpUrl) ===
            null;
        })
          ? true
          : false;
        if (nullFound) {
          message.error('ECU values cannot be empty!');
          setVisible(true);
          return;
        } else {
          form.resetFields();
          setFormInitValues(null);
          setVinEcusSchedules([]);
          message.success('Succesfully Updated VIN ECU');
        }
      })
      .catch((e) => {
        console.log(e);
        message.error('Unable to update VIN ECU, try again later');
      })
      .finally(() => {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      });
  };

  const makeActive = (data) => {
    updateVinEcuCall({ ...data, deleted: false });
  };

  //Delete VIN ECU Data
  const setDeleteVinEcuAction = (vinId, visible) => {
    setVinEcu((state) => {
      const tempData = [...state];
      tempData.find((x) => x.vinTransactionId === vinId).visible = visible;
      tempData
        .filter((x) => x.vinTransactionId !== vinId)
        .forEach((u) => {
          u.visible = false;
        });
      return tempData;
    });
  };

  const deleteVinEcuAction = (vinId) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    deleteVinEcu(vinId)
      .then(() => {
        setVinEcu((state) => {
          const tempState = [...state];
          tempState.find((x) => x.vinTransactionId === vinId).visible = false;
          tempState.find((x) => x.vinTransactionId === vinId).deleted = true;
          return [...state].filter((x) => x.vinTransactionId !== vinId);
        });
        message.success('Succesfully Deleted VIN ECU');
      })
      .catch((e) => {
        console.log(e);
        message.error('Unable to delete VIN ECU, try again later');
      })
      .finally(() => {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      });
  };

  //view model ECU information
  const viewVinEcusInfo = async (e) => {
    try {
      setVinEcusInfo(e);
      setVinEcuInfo(true);
    } catch (e) {
      console.log(e);
      message.error('Unable to get VIN ECU details, try again later');
    }
  };

  const closeAdd = () => {
    setVisible(false);
    setVinEcuInfo(false);
    if (action === 'edit') form.resetFields();
  };

  //Child drawer close
  const closeAddEcu = () => {
    setChildrenDrawer(false);
    if (action === 'edit') form.resetFields();
  };

  const clearForm = () => {
    form.resetFields();
    setVinEcusSchedules([]);
  };

  //For ecu data edit
  const finishAddForEcus = async (type) => {
    if (type === 'save') {
      const data = await formEcu.validateFields();
      setSelectedEcus((ps) => {
        let temp = [...ps];
        let foundEcu = temp.find((_ecu) => _ecu.ecuName === data.ecuName);
        foundEcu = { ...foundEcu, ...data };
        temp = [...temp.filter((x) => x.ecuName !== data.ecuName)];
        temp = [...temp, foundEcu];
        return temp;
      });
    }
    setChildrenDrawer(false);
  };
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  //Update all ecus
  const editEcuAction = async (ecu) => {
    formEcu.setFieldsValue({ ...ecu });
    setChildrenDrawer(true);
  };

  const [ecuSelected, setEcuSelected] = useState(undefined);
  const onSelectedCheckBox = (ecu) => {
    setEcuSelected(ecu);
  };

  // Selecting multiple ECUs by checking
  const onSelectEcu = (ecu, alwaysAdd = false) => {
    setSelectedEcus((ps) => {
      let temp = [...ps];
      if (temp.some((_ecu) => _ecu.ecuName === ecu.ecuName) && !alwaysAdd)
        temp = [...temp.filter((x) => x.ecuName !== ecu.ecuName)];
      else temp = [...temp, ecu];
      return temp;
    });
  };

  //On search VIN number
  const onSelectVinNumber = async (vinNumber) => {
    try {
      const matchedVehicleModel = vinData.find((x) => x.vinNumber === vinNumber)?.vehicleModel;
      const vinNumberSearch = await getVinEcuByVinNumberAndModel(vinNumber, matchedVehicleModel);
      const vinNumberSearchData = vinNumberSearch.data.vinEcusSchedules;
      setVinEcusSchedules(vinNumberSearchData);
    } catch (e) {
      console.log(e);
      if (e.response.data.debugMessage) {
        message.error(e.response.data.debugMessage);
      } else {
        message.error('Unable to add  VIN ECU, try again later');
      }
    }
  };

  //get all VIN ECU data by pagination
  const onPaginationChange = (e) => {
    init(+e - 1);
  };

  const init = async (page = 0) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    const pagination = {
      size: totalVinEcus.pageSize,
      page: page,
    };

    try {
      const vinEcuRes = await getAllVinEcuByPagination(pagination);
      const vinEcuData = vinEcuRes.data?.content || [];
      const allVinsRes = await getAllVin();
      const allVinsData = allVinsRes.data.filter((x) => x.deleted === false);
      const getAllCampaignsRes = await getAllVinEcu();
      const getAllCampaignsData = getAllCampaignsRes.data;
      setCampaignData(getAllCampaignsData);
      setVinData(allVinsData);
      setVinEcu(vinEcuData);
      setVinEcuOriginal(vinEcuData);
      setTotalVinEcus((ps) => ({ ...ps, current: page, items: vinEcuRes.data.totalElements }));
    } catch (e) {
      console.log(e);
      message.error('Unable to get VIN ECU details, try again later');
    } finally {
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  //List of ECUs table columns
  const ecuTableCol = [
    {
      title: '',
      render: (ecu) => {
        const foundEcu = selectedEcus.some((_ecu) => _ecu.ecuName === ecu.ecuName);
        return (
          <>
            <Popconfirm
              title={`Are you sure to remove ${ecu.ecuName}?`}
              visible={ecu === ecuSelected}
              onConfirm={() => {
                onSelectEcu(ecu);
                setEcuSelected(false);
              }}
              onCancel={() => setEcuSelected(false)}
            ></Popconfirm>
            <Checkbox
              onChange={() => (foundEcu ? onSelectedCheckBox(ecu) : onSelectEcu(ecu))}
              checked={foundEcu}
            ></Checkbox>
          </>
        );
      },
    },
    { title: <strong>ECU Name</strong>, key: 'ecuName', dataIndex: 'ecuName' },
    {
      title: <strong> Actions </strong>,
      key: 'Actions',
      render: (record) => (
        <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_SCHEDULES} permission={CRUD.UPDATE}>
          <Button type="link" className="actionButton" onClick={() => editEcuAction(record)}>
            Edit
          </Button>
        </PermissionContainer>
      ),
    },
  ];

  //If it is vin ecu  table set the tableModeNormal to true
  //If it is search table set the tableModeNormal to false
  const [tableModeNormal, setTableModeNormal] = useState(true);
  //onSearch vin ecu data with vin number
  const onSearchVinNumber = async (e) => {
    if (!e.target.value) {
      setVinEcu(vinEcuOriginal);
      setTableModeNormal(true);
    } else {
      try {
        setVinEcu(() => {
          const tempData = [...vinEcu];
          return tempData.filter((x) => x.vinNumber.toLowerCase().includes(e.target.value.toLowerCase()));
        });
        setTableModeNormal(false);
      } catch {
        message.error('Could not retrieve VIN ECU');
      }
    }
  };

  const tableCols = [
    { title: <strong>VIN Number </strong>, key: 'vinNumber', dataIndex: 'vinNumber' },
    {
      title: <strong>Model</strong>,
      key: 'modelName',
      dataIndex: 'modelName',
    },
    {
      title: <strong>ECUs</strong>,
      render: (record) => (
        <Tooltip placement="right" title={record?.vinEcusSchedules?.map((x) => [x.ecuName]).join(', ')}>
          {record?.vinEcusSchedules
            ?.map((x) => [x.ecuName])
            .splice(0, 2)
            .join(', ')}
          {record?.vinEcusSchedules.length > 2 ? (
            <Tag color="blue" className="ml-1">{`+${record?.vinEcusSchedules.length - 2} more`}</Tag>
          ) : (
            ''
          )}
        </Tooltip>
      ),
    },
    {
      title: <strong> Actions </strong>,
      width: 350,
      key: 'Actions',
      render: (record) =>
        record.deleted ? (
          <Row>
            <Col>
              <Button type="link" className="actionButton" onClick={() => makeActive(record)}>
                Activate
              </Button>
            </Col>
            <Col>
              <p className="lastModifiedDate">
                Last Modified on {new Date(record.modifiedTime).toLocaleDateString().toString()}
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_SCHEDULES} permission={CRUD.VIEW}>
              <Button type="link" className="actionButton" onClick={() => viewVinEcusInfo(record)}>
                View
              </Button>
            </PermissionContainer>
            <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_SCHEDULES} permission={CRUD.UPDATE}>
              <Button type="link" className="actionButton" onClick={() => editVinEcuAction(record)}>
                Edit
              </Button>
            </PermissionContainer>
            <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_SCHEDULES} permission={CRUD.DELETE}>
              <Popconfirm
                title={`Are you sure to delete VIN ECU ${record.vinNumber}?`}
                visible={record.visible || false}
                onConfirm={() => deleteVinEcuAction(record.vinTransactionId)}
                onCancel={() => setDeleteVinEcuAction(record.vinTransactionId, false)}
              >
                <Button
                  type="link"
                  className="actionButton"
                  onClick={() => setDeleteVinEcuAction(record.vinTransactionId, true)}
                >
                  Delete
                </Button>
              </Popconfirm>
            </PermissionContainer>
          </>
        ),
    },
  ];

  //ECUs table for view
  const viewEcusCol = [
    { title: <strong>Name</strong>, render: (record) => record.ecuName },
    { title: <strong>Version</strong>, render: (record) => record.ecuVersion },
    { title: <strong>Make </strong>, render: (record) => record.ecuMake },
    { title: <strong>External ID</strong>, render: (record) => record.ecuExtId },
    { title: <strong>Model </strong>, render: (record) => record.ecuModel },
    { title: <strong>File Name</strong>, render: (record) => record.fileName },
    { title: <strong>File Type</strong>, render: (record) => record.fileType },
    { title: <strong>File Location</strong>, render: (record) => record.fileLocation },
    { title: <strong>Sftp Url </strong>, render: (record) => record.sftpUrl },
    { title: <strong>User Name</strong>, render: (record) => record.userName },
    { title: <strong>Password</strong>, render: (record) => record.password },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={[ModuleNames.VEHICLE_DIAGNOSTICS, ModuleNames.SCHEDULES]} />
        </Col>
        <Col>
          <Checkbox
            onChange={() => {
              setShowDeleted(!showDeleted);
            }}
          >
            Inactive
          </Checkbox>
          <PermissionContainer page={Pages.VEHICLE_DIAGNOSTICS_SCHEDULES} permission={CRUD.ADD}>
            <Button type="link" className="commonAddButton actionButton" onClick={openAdd} icon={<PlusOutlined />}>
              Add VIN ECU
            </Button>
          </PermissionContainer>
        </Col>
      </Row>
      <Row className="searchDiv" justify="end">
        <Col xs={24} lg={6}>
          <Search placeholder="Enter VIN Number" allowClear enterButton onChange={onSearchVinNumber} />
        </Col>
      </Row>
      <>
        {!context.isCompact ? (
          <>
            {tableModeNormal ? (
              <>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  rowKey="vinTransactionId"
                  columns={tableCols}
                  dataSource={vinEcu}
                  rowClassName={(record) => record.deleted && 'rowInactive'}
                  pagination={false}
                />
                <Row justify="end">
                  <Col>
                    <div className="m-2">
                      <Pagination
                        onChange={onPaginationChange}
                        current={totalVinEcus.current + 1}
                        total={totalVinEcus.items}
                        defaultPageSize={totalVinEcus.pageSize}
                        showSizeChanger={false}
                        showQuickJumper={false}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Table
                size="small"
                scroll={{ x: true }}
                rowKey="vinTransactionId"
                columns={tableCols}
                dataSource={vinEcu}
                rowClassName={(record) => record.deleted && 'rowInactive'}
              />
            )}
          </>
        ) : (
          <>
            <CommonCompactView
              data={vinEcu}
              onEdit={editVinEcuAction}
              onDelete={deleteVinEcuAction}
              permissions={[
                { pageName: Pages.VEHICLE_DIAGNOSTICS_SCHEDULES, permission: CRUD.UPDATE, label: 'Edit' },
                { pageName: Pages.VEHICLE_DIAGNOSTICS_SCHEDULES, permission: CRUD.DELETE, label: 'Delete' },
              ]}
              title="vinNumber"
              dataList={[{ label: 'VIN Number', value: 'vinNumber' }]}
            />
            <Row justify="end">
              <Col>
                <div className="m-2">
                  <Pagination
                    onChange={onPaginationChange}
                    current={totalVinEcus.current + 1}
                    total={totalVinEcus.items}
                    defaultPageSize={totalVinEcus.pageSize}
                    showSizeChanger={false}
                    showQuickJumper={false}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </>

      <CommonDrawer title="VIN ECU Information" visible={vinEcuInfo} closeAdd={closeAdd}>
        <div className="infoDrawer">
          {vinEcusInfo ? (
            <Row className="infoContainer" span={24}>
              <Col className="info" span={24}>
                <Row>
                  <Col className="infoTitle" span={10}>
                    VIN Number :
                  </Col>
                  <Col>{vinEcusInfo.vinNumber}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Model Name :
                  </Col>
                  <Col>{vinEcusInfo.modelName}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    ECU Count :
                  </Col>
                  <Col>{vinEcusInfo.ecuCount}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Campaign Type :
                  </Col>
                  <Col>{vinEcusInfo.campaignType}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Model ID :
                  </Col>
                  <Col>{vinEcusInfo.modelId}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Update Required :
                  </Col>
                  <Col>{String(vinEcusInfo.updateRequired)}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Update Status :
                  </Col>
                  <Col>{vinEcusInfo.updateStatus}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Created By :
                  </Col>
                  <Col>{vinEcusInfo.createdBy}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Created Time :
                  </Col>
                  <Col>{new Date(vinEcusInfo.createdTime).toLocaleString()}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Modified Time :
                  </Col>
                  <Col>{new Date(vinEcusInfo.modifiedTime).toLocaleString()}</Col>
                </Row>
                <Row>
                  <Col className="infoTitle" span={10}>
                    Modified By :
                  </Col>
                  <Col>{vinEcusInfo.modifiedBy}</Col>
                </Row>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  rowKey="ecuId"
                  columns={viewEcusCol}
                  dataSource={vinEcusInfo.vinEcusSchedules}
                  rowClassName={(record) => record.deleted && 'rowInactive'}
                  pagination={false}
                  className="m-3"
                />
                <br />
                <Row justify="center">
                  <Button type="primary" onClick={() => editVinEcuAction(vinEcusInfo)}>
                    Edit
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </CommonDrawer>
      <CommonDrawer title="VIN ECU" visible={visible} closeAdd={closeAdd}>
        <Form
          initialValues={formInitValues}
          scrollToFirstError={true}
          autoComplete={'new-password'}
          layout="horizontal"
          form={form}
          wrapperCol={{ span: 16 }}
          labelCol={{ span: 8 }}
        >
          <Form.Item
            shouldUpdate={true}
            hasFeedback
            label="VIN Number"
            name="vinNumber"
            rules={[
              {
                required: true,
                message: 'Please input VIN number!',
              },
            ]}
          >
            <Select showSearch placeholder="VIN Number" onSelect={onSelectVinNumber}>
              {vinData.map((a) => (
                <Option title={a.vinNumber} key={a.vinNumber} value={a.vinNumber}>
                  {a.vinNumber}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Table
          size="small"
          scroll={{ x: true }}
          rowKey="ecuName"
          columns={ecuTableCol}
          dataSource={action === 'edit' ? allEcus.vinEcusSchedules : vinEcusSchedules}
          rowClassName={(record) => record.deleted && 'rowInactive'}
          pagination={false}
          className="m-3"
        />
        <Row gutter={6}>
          <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
            <Button block className="commonSaveButton formButton" onClick={() => finishAdd('save')}>
              Save
            </Button>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 0, span: 9 }}>
            <div>
              {action === 'new' && (
                <Col>
                  <Button block onClick={() => finishAdd('add')}>
                    Save + 1
                  </Button>
                </Col>
              )}
            </div>
          </Col>
        </Row>
        <Row className="footer" gutter={6}>
          <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
            <Button block className={['clearButton', !context.isCompact ? 'clear' : null]} onClick={() => clearForm()}>
              Clear
            </Button>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 0, span: 9 }}>
            <Button block danger type="primary" onClick={closeAdd}>
              Close
            </Button>
          </Col>
        </Row>
        <Drawer
          title={<Title className={s.title}>ECU</Title>}
          width={context.isCompact ? 400 : 550}
          visible={childrenDrawer}
          onClose={onChildrenDrawerClose}
        >
          <Form
            initialValues={formInitValues}
            scrollToFirstError={true}
            autoComplete={'new-password'}
            layout="horizontal"
            form={formEcu}
            wrapperCol={{ span: 16 }}
            labelCol={{ span: 8 }}
          >
            <Form.Item shouldUpdate={true} hasFeedback label="Name" name="ecuName">
              <Input placeholder="Name" disabled={true} />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="External ID" name="ecuExtID">
              <Input placeholder="External ID" disabled={true} />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Model" name="ecuModel">
              <Input placeholder="Model" disabled={true} />
            </Form.Item>
            <Form.Item shouldUpdate={true} hasFeedback label="Make" name="ecuMake">
              <Input placeholder="Make" disabled={true} />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Software Version"
              name="ecuVersion"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input software version!',
                },
              ]}
            >
              <Input placeholder="Software Version" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Name"
              name="fileName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file name!',
                },
              ]}
            >
              <Input placeholder="File Name" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Type"
              name="fileType"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file type!',
                },
              ]}
            >
              <Input placeholder="File Type" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="File Location"
              name="fileLocation"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input file location!',
                },
              ]}
            >
              <Input placeholder="File Location" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="User Name"
              name="userName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input user name!',
                },
              ]}
            >
              <Input placeholder="User Name" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input password!',
                },
              ]}
            >
              <Input placeholder="Password" />
            </Form.Item>
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              label="Sftp Url"
              name="sftpUrl"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input sftpUrl!',
                },
              ]}
            >
              <Input placeholder="Sftp Url" />
            </Form.Item>
            <Row gutter={6}>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
                <Button block className="commonSaveButton formButton" onClick={() => finishAddForEcus('save')}>
                  Save
                </Button>
              </Col>
            </Row>
            <Row className="footer" gutter={6}>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 6, span: 9 }}>
                <Button
                  block
                  className={['clearButton', !context.isCompact ? 'clear' : null]}
                  onClick={() => clearForm()}
                >
                  Clear
                </Button>
              </Col>
              <Col xs={{ span: 12, offset: 0 }} lg={{ offset: 0, span: 9 }}>
                <Button block danger type="primary" onClick={closeAddEcu}>
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </CommonDrawer>
    </>
  );
};

export default Schedules;
