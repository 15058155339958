import { getMenu } from './menu';
import { Pages, RoutePaths, ModuleNames, CRUD } from './constants';
import Page404 from './routes/404';
import Login from './routes/Login';
import DeviceManagement from './routes/Dashboards/DeviceManagement';
import Sanitary from './routes/Dashboards/Sanitary';
import RESTAURANT from './routes/Dashboards/Restaurant';
import AssetDashboard from './routes/Dashboards/PeopleTracking';
import AssetTracking from './routes/AssetTracking';
import VehicleDiagnostics from './routes/Dashboards/Vehicle Diagnostics';
import AnalyticsDashboard from './routes/Dashboards/PeopleTracking/AnalyticsDashboard';
import CreateReport from './routes/RegisterManagement/CreateReport';
import {
  AimOutlined,
  DashboardOutlined,
  HeatMapOutlined,
  WalletOutlined,
  RestOutlined,
  CarOutlined,
} from '@ant-design/icons';
import ApplicationDashboardVisual from './routes/ApplicationManagement/Visual';
import Reports from './routes/Dashboards/Vehicle Diagnostics/Reports';
import VehicleAnalytics from './routes/Dashboards/Vehicle Diagnostics/AnalyticsDahboard';
import Schedules from './routes/Dashboards/Vehicle Diagnostics/Schedules';
import Campaign from './routes/Dashboards/Vehicle Diagnostics/Campaign';

export const publicRoutes = () => {
  return [
    {
      path: RoutePaths['404'],
      component: Page404,
      exact: true,
    },
    {
      path: `${RoutePaths.LOGIN}/:id?`,
      component: Login,
      exact: true,
    },
  ];
};

export const nonModulePrivateRoutes = (pagePermissions) => {
  const pagePermissionsArray = pagePermissions.map((x) => {
    if (x.permissions.includes(CRUD.VIEW)) {
      return x.pageName;
    }
    return null;
  });
  const routes = [];

  if (pagePermissionsArray.includes(Pages.PEOPLE_TRACKING_DASHBOARD)) {
    routes.push({
      name: ModuleNames.PEOPLE_TRACKING,
      pageName: Pages.PEOPLE_TRACKING_DASHBOARD,
      icon: <HeatMapOutlined />,
      path: `${RoutePaths.ASSET_DASHBOARD}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: AssetDashboard,
          path: `${RoutePaths.ASSET_DASHBOARD}`,
        },
        {
          name: ModuleNames.ANALYTICS,
          component: AnalyticsDashboard,
          path: `${RoutePaths.ANALYTICS_PEOPLE_TRACKING}`,
        },
        {
          name: ModuleNames.REPORT,
          path: `${RoutePaths.REPORT}/createre`,
          component: CreateReport,
        },
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.VEHICLE_DIAGNOSTICS_DASHBOARD)) {
    routes.push({
      name: ModuleNames.VEHICLE_DIAGNOSTICS,
      pageName: Pages.VEHICLE_DIAGNOSTICS_DASHBOARD,
      icon: <CarOutlined />,
      path: `${RoutePaths.VEHICLE_DIAGNOSTICS_DASHBOARD}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: VehicleDiagnostics,
          path: `${RoutePaths.VEHICLE_DIAGNOSTICS_DASHBOARD}`,
        },
        {
          name: ModuleNames.ANALYTICS,
          component: VehicleAnalytics,
          path: `${RoutePaths.ANALYTICS_VEHICLE_DIAGNOSTICS}`,
        },
        {
          name: ModuleNames.REPORT,
          path: `${RoutePaths.VEHICLE_REPORT}`,
          component: Reports,
        },
        {
          name: ModuleNames.SCHEDULES,
          path: `${RoutePaths.VEHICLE_DIAGNOSTICS_SCHEDULES}`,
          component: Schedules,
        },
        {
          name: ModuleNames.CAMPAIGN,
          path: `${RoutePaths.VEHICLE_DIAGNOSTICS_CAMPAIGN}`,
          component: Campaign,
        },
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.ASSET_TRACKING_PAGE)) {
    routes.push({
      name: ModuleNames.ASSET_TRACKING,
      pageName: Pages.ASSET_TRACKING_DASHBOARD,
      icon: <AimOutlined />,
      path: `${RoutePaths.ASSET_TRACKING}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: AssetTracking,
          path: `${RoutePaths.ASSET_TRACKING}`,
        },
        {
          name: ModuleNames.ANALYTICS,
          component: AnalyticsDashboard,
          path: `${RoutePaths.ANALYTICS_ASSET_TRACKING}`,
        },
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.DEVICE_DASHBOARD_PAGE)) {
    routes.push({
      name: ModuleNames.DEVICE_MANAGEMENT,
      pageName: Pages.DEVICE_MANAGEMENT_DASHBOARD,
      icon: <DashboardOutlined />,
      path: `${RoutePaths.DEVICE_DASHBOARD}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: DeviceManagement,
          path: `${RoutePaths.DEVICE_DASHBOARD}`,
        },
        {
          name: ModuleNames.ANALYTICS,
          component: AnalyticsDashboard,
          path: `${RoutePaths.ANALYTICS_DEVICE_MANAGEMENT}`,
        },
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.WASHROOMS_DASHBOARD_PAGE)) {
    routes.push({
      name: ModuleNames.SANITARY,
      pageName: Pages.SANITARY_DASHBOARD,
      icon: <RestOutlined />,
      path: `${RoutePaths.WASHROOMS_DASHBOARD}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: Sanitary,
          path: `${RoutePaths.WASHROOMS_DASHBOARD}`,
        },
        {
          name: ModuleNames.ANALYTICS,
          component: AnalyticsDashboard,
          path: `${RoutePaths.ANALYTICS_SANITARY}`,
        },
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.RESTAURANT_DASHBOARD)) {
    routes.push({
      name: ModuleNames.RESTAURANT,
      pageName: Pages.RESTAURANT_DASHBOARD,
      icon: <RestOutlined />,
      path: `${RoutePaths.RESTAURANT_DASHBOARD}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.DASHBOARD,
          component: RESTAURANT,
          path: `${RoutePaths.RESTAURANT_DASHBOARD}`,
        }
      ],
    });
  }
  if (pagePermissionsArray.includes(Pages.APPLICATION_MANAGEMENT_VISUAL)) {
    routes.push({
      name: ModuleNames.APPLICATION_MANAGEMENT,
      pageName: Pages.APPLICATION_MANAGEMENT_VISUAL,
      icon: <WalletOutlined />,
      path: `${RoutePaths.VISUAL}`,
      exact: true,
      before: true,
      modules: [
        {
          name: ModuleNames.VISUAL,
          component: ApplicationDashboardVisual,
          path: `${RoutePaths.VISUAL}`,
        },
      ],
    });
  }
  return routes;
};

export const privateRoutes = (pagePermissions) => {
  let routes = [
    ...publicRoutes(),
    ...nonModulePrivateRoutes(pagePermissions)
      .map((x) => x.modules)
      .flat(),
  ];
  getMenu(pagePermissions).forEach((i) => {
    i.category.modules.forEach((m) => {
      m.subModules.forEach((s) => {
        if (s.component) {
          routes.push({
            path: `${m.path}${s.path}${s.params?.join('') || ''}`,
            component: s.component || null,
            exact: true,
          });
        }
      });
    });
  });

  return routes;
};
