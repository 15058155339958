import { useEffect, useState, useContext } from 'react';
import {
  Input,
  Table,
  CommonCompactView,
  message,
  Row,
  Col,
  Select,
  Button,
  Drawer,
  Tag,
  Progress,
  Typography,
} from '../../../components';
import { getAllVinEcuByPagination, getAllEcu, getAllVinEcu } from '../../../services';
import { calcDrawerWidth } from '../../../utils';
import Context from '../../../context';
import { BreadcrumbList } from '../../../shared';
import { get } from 'lodash';
import { CRUD, Pages, ModuleNames, RoutePaths } from '../../../constants';
import { Link } from 'react-router-dom';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

const VehicleDiagnostics = () => {
  const [context, setContext] = useContext(Context);
  const [vinEcusOriginal, setVinEcusOriginal] = useState([]);
  const [ecusInfo, setEcusInfo] = useState([]);
  const [allVinEcus, setAllVinEcus] = useState([]);

  const init = async () => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });

    try {
      const allVinEcusRes = await getAllVinEcu();
      const allVinEcusData = allVinEcusRes.data;
      setAllVinEcus(allVinEcusData);
      setVinEcusOriginal(allVinEcusData);
    } catch (e) {
      console.log(e);
      message.error('Unable to get VIN details, try again later');
    } finally {
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  //If it is vin table set the tableModeNormal to true
  //If it is search table set the tableModeNormal to false
  const [tableModeNormal, setTableModeNormal] = useState(true);
  //onSearch Vin data with vehicle manufacturer,chasis number,vin number,engine number
  const onSearchVinData = async (e) => {
    if (e) {
      try {
        const pagination = {
          size: allVinEcus.length,
          page: 0,
        };
        const vinEcusRes = await getAllVinEcuByPagination(pagination, e);
        const vinEcusdata = vinEcusRes.data?.content;
        setAllVinEcus(vinEcusdata);
        setTableModeNormal(false);
      } catch (e) {
        console.log(e);
        message.error('Could not retrieve VIN');
      }
    } else {
      setTableModeNormal(true);
      setAllVinEcus(vinEcusOriginal);
    }
  };

  const [visibleVinDrawer, setVisibleVinDrawer] = useState(false);
  const [selectedVinNumber, setSelectedVinNumber] = useState([]);
  //On click vin number
  const onClickVinNumber = async (selectedVin) => {
    const getAllEcusInfo = await getAllEcu();
    const ecus = getAllEcusInfo.data.filter((x) => x.deleted === false);
    setEcusInfo(ecus);
    setSelectedVinNumber(selectedVin);
  };

  const showDrawer = async () => {
    setVisibleVinDrawer(true);
  };

  //On Drawer close
  const onClose = () => {
    setVisibleVinDrawer(false);
  };
  const tableCols = [
    {
      title: <strong> VIN Number [{allVinEcus === undefined ? 0 : allVinEcus.length}] </strong>,
      render: (vin) => (
        <Button
          type="link"
          onClick={() => {
            onClickVinNumber(vin);
            showDrawer(vin);
          }}
        >
          {vin?.vinNumber}
        </Button>
      ),
    },
    { title: <strong>ECU Count</strong>, key: 'ecuCount', dataIndex: 'ecuCount' },
    {
      title: <strong>Model</strong>,
      key: 'modelName',
      dataIndex: 'modelName',
    },
    {
      title: <strong>Last Updated</strong>,
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
    },
    {
      title: <strong>VIN Overall Status</strong>,
      render: () => (
        <Row>
          <Col span={6}>
            <Tag color="success">Scheduled</Tag>
          </Col>
          <Col span={6}>
            <Tag color="error">File Download</Tag>
          </Col>
          <Col span={6} className="mx-2">
            <Progress percent={48} />
          </Col>
        </Row>
      ),
    },
  ];

  const tableColsForVin = [
    {
      title: <strong> ECU [{ecusInfo.length}]</strong>,
      render: (record) => record.ecuName,
    },
    {
      title: <strong> Last Updated </strong>,
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
    },
    {
      title: <strong> Status </strong>,
      render: () => (
        <Row gutter={8}>
          <Col>
            <Tag color="success">Scheduled</Tag>
          </Col>
          <Col>
            <Tag color="error">File Download</Tag>
          </Col>
          <Col span={6}>
            <Progress percent={48} />
          </Col>
        </Row>
      ),
    },
  ];

  //vin breadcrumbs name
  const vehicleDiagnosticsBreadcrumbsName = get(
    context.tenantProfile,
    `moduleNames[${Pages.VEHICLE_DIAGNOSTICS_DASHBOARD}].displayName`,
    ModuleNames.VEHICLE_DIAGNOSTICS
  );

  return (
    <>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={[vehicleDiagnosticsBreadcrumbsName]} />
        </Col>
      </Row>
      <Row className="searchDiv">
        <Col className="mx-3">
          <Search placeholder="Enter VIN Number" allowClear enterButton onSearch={onSearchVinData} />
        </Col>
        <Col className="mx-3">
          <Select placeholder="Status Filter">
            <Option value="Scheduled">SCHEDULED</Option>
            <Option value="In-Progress">IN-PROGRESS</Option>
            <Option value="Completed">COMPLETED</Option>
            <Option value="Failed">FAILED</Option>
          </Select>
        </Col>
        <Col className="mx-3">
          <Button className="commonSaveButton formButton">
            <Link to={`${RoutePaths.VEHICLE_DIAGNOSTICS_SCHEDULES}`}>Schedule</Link>
          </Button>
        </Col>
      </Row>
      <>
        {!context.isCompact ? (
          <>
            {tableModeNormal ? (
              <>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  rowKey="vinTransactionId"
                  columns={tableCols}
                  dataSource={allVinEcus}
                  rowClassName={(record) => record.deleted && 'rowInactive'}
                  pagination={false}
                />
              </>
            ) : (
              <Table
                size="small"
                scroll={{ x: true }}
                rowKey="vinTransactionId"
                columns={tableCols}
                dataSource={allVinEcus}
                rowClassName={(record) => record.deleted && 'rowInactive'}
              />
            )}
          </>
        ) : (
          <>
            <CommonCompactView
              data={allVinEcus}
              permissions={[
                { pageName: Pages.VIN, permission: CRUD.UPDATE, label: 'Edit' },
                { pageName: Pages.VIN, permission: CRUD.DELETE, label: 'Delete' },
              ]}
              title="vinNumber"
              dataList={[{ label: 'Vin Number', value: 'vinNumber' }]}
            />
          </>
        )}
      </>
      <Drawer
        width={calcDrawerWidth(true)}
        className="commonDrawer"
        onClose={onClose}
        bodyStyle={{ padding: 0 }}
        visible={visibleVinDrawer}
        getContainer={false}
      >
        <Title className="title"> {selectedVinNumber.vinNumber} </Title>
        <div className="content">
          <Table
            size="small"
            scroll={{ x: true }}
            rowKey="id"
            columns={tableColsForVin}
            dataSource={ecusInfo}
            rowClassName={(record) => record.deleted && 'rowInactive'}
            pagination={false}
            className="m-3"
          />
        </div>
      </Drawer>
    </>
  );
};

export default VehicleDiagnostics;
