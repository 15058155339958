import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Select, Form, DatePicker, Tag, message } from '../../../components';
import {
  getVinCountByManufacture,
  getModelCountByManufacture,
  getEcusCountByManufacture,
  getvehiclemodelVariantByManufacture,
  getVinEcusByDate,
} from '../../../services';
import { BreadcrumbList } from '../../../shared';
import { ModuleNames, VehicleManufacturers, DateFormats } from '../../../constants';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import moment from 'moment';
import Context from '../../../context';
import s from './analytics.module.less';

const { Option } = Select;

const AnalyticsDashboard = () => {
  const [form] = Form.useForm();
  const formInitState = { vehicleManufacturer: VehicleManufacturers[0] };
  const [context] = useContext(Context);
  const [allVinCountData, setAllVinCountData] = useState([]);
  const [allModelsCount, setAllModelsCount] = useState([]);
  const [allEcusCount, setAllEcusCount] = useState([]);
  const [allVehiclemodelVariants, setAllVehiclemodelVariants] = useState([]);
  const [countOfSchedules, setCountOfSchedules] = useState([]);

  const onSelectVehicleManufacturer = (manufacture) => {
    try {
      const getAllPromisesCounts = [
        getVinCountByManufacture(manufacture),
        getModelCountByManufacture(manufacture),
        getEcusCountByManufacture(manufacture),
        getvehiclemodelVariantByManufacture(manufacture),
      ];
      Promise.allSettled(getAllPromisesCounts)
        .then((res) => {
          setAllVehiclemodelVariants(res[3].value.data);
          setAllVinCountData(res[0].value.data);
          setAllModelsCount(res[1].value.data);
          setAllEcusCount(res[2].value.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      message.error('Analytics cannot be loaded at this time');
      console.log(e);
    }
  };

  useEffect(() => {
    const manufacture = VehicleManufacturers[0];
    onSelectVehicleManufacturer(manufacture);
  }, []);

  //Date range selected values
  const [dateRangeSelectionForAnalaytics, setDateRangeSelectionForAnalaytics] = useState([
    moment().startOf('day'),
    moment(),
  ]);
  const onDateChangeForAnalytics = (_, date) => {
    try {
      if (!date[0] && !date[1]) return;
    } catch (e) {
      return;
    }
    setDateRangeSelectionForAnalaytics([moment(date[0], DateFormats.TYPE_7), moment(date[1], DateFormats.TYPE_7)]);
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!dateRangeSelectionForAnalaytics[0] && !dateRangeSelectionForAnalaytics[1]) return;
      } catch (e) {
        return;
      }
      try {
        const fromDate = dateRangeSelectionForAnalaytics[0].format(DateFormats.TYPE_7);
        const toDate = dateRangeSelectionForAnalaytics[1].format(DateFormats.TYPE_7);
        const status = 'SCHEDULED';
        const res = await getVinEcusByDate(fromDate, toDate, status);
        const data = res.data;
        setDateRangeSelectionForAnalaytics(data);
        setCountOfSchedules(data);
      } catch (e) {
        console.log(e);
        return;
      }
    };
    init();
    // eslint-disable-next-line
  }, [dateRangeSelectionForAnalaytics]);

  const colors = ['#FF6699', '#4DBFC0', '#FFC43D', '#38A2EB'];
  const data = [
    { name: 'Ecus Count', value: allEcusCount },
    { name: 'Vins Count', value: allVinCountData },
    { name: 'Models Count', value: allModelsCount },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col className="mb-3">
          <BreadcrumbList list={[ModuleNames.ANALYTICS]} />
        </Col>
        <Col>
          <Tag color={`${colors[0]}`}>ECU Count {allEcusCount}</Tag>
          <Tag color={`${colors[1]}`}>VIN Count {allVinCountData}</Tag>
          <Tag color={`${colors[2]}`}>MODEL Count {allModelsCount}</Tag>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form
            initialValues={formInitState}
            scrollToFirstError={true}
            autoComplete={'new-password'}
            layout="horizontal"
            form={form}
          >
            <Form.Item
              shouldUpdate={true}
              hasFeedback
              name="vehicleManufacturer"
              rules={[
                {
                  required: true,
                  message: 'Please input vehicle manufacturer!',
                },
              ]}
            >
              <Select placeholder="Vehicle Manufacturer" onSelect={onSelectVehicleManufacturer}>
                {VehicleManufacturers.map((m) => (
                  <Option title={m} key={m} value={m}>
                    {m}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify={context.isCompact ? 'start' : 'center'} className="mb-4">
        <Col>
          <PieChart width={730} height={300}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#4DBFC0"
              label
              isAnimationActive={false}
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/jsx-key
                <Cell fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Col>
        <Col className={`${s.card} p-3`}>
          {allVehiclemodelVariants.map((x) => {
            return (
              <>
                <Tag>
                  {x.model} : {x.count}
                </Tag>
              </>
            );
          })}
        </Col>
      </Row>
      <Row justify="center" className={`${s.card}`}>
        <Col>
          <Col className="pt-3">
            <Form
              initialValues={formInitState}
              scrollToFirstError={true}
              autoComplete={'new-password'}
              layout="horizontal"
              form={form}
            >
              <Form.Item>
                <DatePicker.RangePicker
                  showTime={{
                    format: DateFormats.TYPE_9,
                  }}
                  format={DateFormats.TYPE_7}
                  defaultValue={dateRangeSelectionForAnalaytics}
                  onChange={onDateChangeForAnalytics}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Col className="m-4">
              <Tag color="blue" className="mr-3">
                SCHEDULED : {countOfSchedules}
              </Tag>
              <Tag color="warning" className="ml-5">
                INPROGRESS : {0}
              </Tag>
            </Col>
            <Col className="m-4">
              <Tag color="error" className="mr-5">
                FAILED : {0}
              </Tag>
              <Tag color="success" className="ml-5">
                COMPLETED : {0}
              </Tag>
            </Col>
          </Col>
        </Col>
      </Row>
    </>
  );
};
export default AnalyticsDashboard;
