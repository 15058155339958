import axios from '../axios';

const ROLE_URI = '/role/';
const PAGE_ACCESS_URI = '/pageAccess/';
const ROLE_ACCESS_URI = '/roleAccess/';

export const addRole = (role) => {
  return axios.post(`${ROLE_URI}`, role);
};

export const deleteRole = (roleId) => {
  return axios.delete(`${ROLE_URI}${roleId}`);
};

export const getAllRoles = () => {
  return axios.get(`${ROLE_URI}all`);
};

export const addPage = (page) => {
  return axios.post(`${PAGE_ACCESS_URI}`, page);
};

export const deletePage = (pageId) => {
  return axios.delete(`${PAGE_ACCESS_URI}${pageId}`);
};

export const getAllPages = () => {
  return axios.get(`${PAGE_ACCESS_URI}all`);
};

export const linkPageRole = (link) => {
  return axios.post(`${ROLE_ACCESS_URI}`, link);
};

export const deletePageRoleLink = (linkId) => {
  return axios.delete(`${ROLE_ACCESS_URI}${linkId}`);
};

export const getPagesRolesLinksByRole = (roleId) => {
  return axios.get(`${ROLE_ACCESS_URI}${roleId}`);
};

export const getAllPagesRolesLinks = () => {
  return axios.get(`${ROLE_ACCESS_URI}all`);
};
