import jwt from 'jsonwebtoken';
import { CRUD, PageRoutes, Pages, RoutePaths } from '../constants';
import context from '../context';

export const calcDrawerWidth = (max = false, offset = 100) => {
  const maxWidth = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  return maxWidth <= 576 ? maxWidth : max ? maxWidth - offset : 577;
};

export const buildCommonApiValues = (context) => {
  return {
    id: null,
    createdBy: context.username,
    createdTime: new Date().toISOString(),
    modifiedBy: context.username,
    modifiedTime: new Date().toISOString(),
    deleted: false,
    tenantId: context.tenantId,
  };
};

export const isRecentlyUpdated = (dateTime, updateDelay = 20) => {
  const timeNow = new Date();
  const seconds = (timeNow.getTime() - dateTime.getTime()) / 1000;
  if (seconds <= updateDelay) return true;
  return false;
};

export const isExpired = (token) => {
  const decode = jwt.decode(token);
  const timeNow = new Date();
  const tokenTime = new Date(decode.exp * 1000);
  if (tokenTime < timeNow) return true;
  return false;
};

export const csvFile = ({ data, fileName }) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  const csvData = [];
  data.map((e, index) => {
    if (index === 0) {
      const ky = Object.keys(e).map((x) => x);
      csvData.push(ky);
    }
    const vl = Object.values(e).map((x) => x);
    csvData.push(vl);
    return csvData;
  });
  csvContent += csvData.map((e) => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
};

export const findFirstPermissionedRoute = (pagePermissions) => {
  const isDeviceDashboard = pagePermissions.find(
    (x) => x.pageName === Pages.DEVICE_DASHBOARD_PAGE && x.permissions.includes(CRUD.VIEW)
  );
  if (isDeviceDashboard) return RoutePaths.DEVICE_DASHBOARD;
  const isPeopleTrackingDashboard = pagePermissions.find(
    (x) => x.pageName === Pages.PEOPLE_TRACKING_DASHBOARD && x.permissions.includes(CRUD.VIEW)
  );
  if (isPeopleTrackingDashboard) return RoutePaths.ASSET_DASHBOARD;
  const isAssetTrackingDashboard = pagePermissions.find(
    (x) => x.pageName === Pages.ASSET_TRACKING_PAGE && x.permissions.includes(CRUD.VIEW)
  );
  if (isAssetTrackingDashboard) return RoutePaths.ASSET_TRACKING;
  const isSanitaryDashboard = pagePermissions.find(
    (x) => x.pageName === Pages.WASHROOMS_DASHBOARD_PAGE && x.permissions.includes(CRUD.VIEW)
  );
  if (isSanitaryDashboard) return RoutePaths.WASHROOMS_DASHBOARD;
  const routeToFind = pagePermissions.filter((x) => x.permissions.includes(CRUD.VIEW))[0];
  const route = PageRoutes[routeToFind.pageName];
  return route;
};

export const cleanJSON = (json) => {
  for (let key in json) {
    if (json[key] === undefined || json[key] === null) {
      json[key] = '';
    } else if (typeof json[key] === 'object') json[key] = cleanJSON(json[key]);
  }
  return json;
};

export const normFile = (e) => {
  const reader = new FileReader();
  const fileByteArray = [];

  reader.onloadend = (evt) => {
    if (evt.target.readyState === FileReader.DONE) {
      const arrayBuffer = evt.target.result,
        array = new Uint8Array(arrayBuffer);
      for (const a of array) {
        fileByteArray.push(a);
      }
    }
  };
  reader.readAsArrayBuffer(e.file.originFileObj);
  return fileByteArray;
};
//width of the date range selected bar graph
export const lengthOftheBarChartLine = (noOfItems) => {
  const widthOfBarChart =
    noOfItems.length === 0
      ? document.body.clientWidth * (context.isCompact ? 4 : 1.5)
      : (document.body.clientWidth * (context.isCompact ? 4 : 1.5) * noOfItems.length) / 50;
  const barChartWidth =
    widthOfBarChart < document.body.clientWidth * 0.7 ? document.body.clientWidth * 0.7 : widthOfBarChart;
  return barChartWidth;
};
