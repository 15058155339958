require('dotenv').config();

export const REACT_APP_THEME_PRIMARY_COLOR = process.env.REACT_APP_THEME_PRIMARY_COLOR;
export const REACT_APP_SPIDEX_API_BASE_URL = process.env.REACT_APP_SPIDEX_API_BASE_URL;
export const REACT_APP_SPIDEX_API_BASE_URL_ALTERNATE = process.env.REACT_APP_SPIDEX_API_BASE_URL_ALTERNATE;
export const REACT_APP_MAP_GOOGLE_API_KEY = process.env.REACT_APP_MAP_GOOGLE_API_KEY;
export const REACT_APP_W3W_API_KEY = process.env.REACT_APP_W3W_API_KEY;
export const REACT_APP_DEFAULT_USER_PASSWORD = process.env.REACT_APP_DEFAULT_USER_PASSWORD;
export const REACT_APP_SPIDEX_SSE_URL = process.env.REACT_APP_SPIDEX_SSE_URL;
export const REACT_APP_CUSTOM_HOST = process.env.REACT_APP_CUSTOM_HOST;
export const REACT_APP_SPIDEX_SOCKET_URI = process.env.REACT_APP_SPIDEX_SOCKET_URI;
