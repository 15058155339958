import React from 'react';
import { Select } from 'antd';

const ModifiedSelect = React.forwardRef((props, ref) => {
  return (
    <Select
      ref={ref}
      allowClear
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
    />
  );
});

ModifiedSelect.Option = Select.Option;
export default ModifiedSelect;
