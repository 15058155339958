import React, { useContext } from 'react';
import { Table } from 'antd';
import Context from '../../context';

const TableCustom = (props) => {
  const [context] = useContext(Context);

  let { columns } = props;

  columns.forEach((element) => {
    element.align = context.tenantProfile?.tableProperties?.headerAlign || 'left';
  });
  return (
    <Table {...props} columns={columns} scroll={{ x: 'max-content' }}>
      {props.children}
    </Table>
  );
};

export default TableCustom;
