import React, {  useContext, useEffect, useState, useRef } from 'react';
import s from './index.module.less';
import {
  Select,
  Col,
  Row,
  Card,
  message,
  Space,
} from '../../../components';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import * as config from '../../../config';
import Context from '../../../context';
import {
  getAllAreas,
  getAllLocations,
  getSensorProximityInitialByLocation,
  getAllGateways,
  getAllTaggedAssets,
} from '../../../services';

const { Option } = Select;

const RestaurantDashboard = () => {
  const [context, setContext] = useContext(Context);
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [gateways, setGateways] = useState([]);
  const [lints, setLints] = useState([]);
  const [lintAssets, setLintAssets] = useState([]);
  const [tgAssets, setTgAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lintSelect, setLintSelect] = useState('gateway');
  const [proximityData, setProximityData] = useState({});
  const [ticks, setTicks] = useState(0);

  const subs = useRef([]);
  const stompClient = useRef(null);
  const subsGateways = useRef({});

  const handleChange = (value) => {
    setLintSelect(value);
  };

  //Updating zone view table data ,based on location selection
  useEffect(() => {
      const lintzones = [];
      setLints(lintzones);
      gateways
        .filter((x) => x.locId === selectedLocationId)
        .forEach((gateway) => {
          const item = {
            area: gateway.area || '',
            areaId: gateway.areaId || '',
            type: gateway.categoryType || '',
            assets: [],
          };
          item.children = [];
          if(lintSelect === 'both' || lintSelect === 'lint')
          {
            if (gateway.categoryType === 'lint') {
              gateway?.gatewayLint?.forEach((lint) => {
                const lintItem = {
                  area: lint.areaName || '',
                  areaId: lint.areaId || '',
                  gatLintId: lint.gatLintId || '',
                  type: 'lint',
                  assets: []
                };
                item.children.push(lintItem);
              });
            }
          }
          console.log("selected lint");
          console.log(lintSelect);
          if(lintSelect === 'gateway' || lintSelect === 'both')
          {
            const lintItem = {
              area: gateway.area || '',
              areaId: gateway.areaId || '',
              gatLintId: gateway.id || '',
              type: 'lint',
              assets: []
            };
            item.children.push(lintItem);
          }
          lintzones.push(item);
        });
      setLints(lintzones);
    // eslint-disable-next-line
  }, [selectedLocationId, lintSelect]);

  //Promises data for all gateways,all locations,all tagged assets, all areas
  useEffect(() => {
    const init = async () => {
      setContext((state) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      try {
        const resGateways = await getAllGateways(context.profile.tenantId);
        const gatewayActive = resGateways.data.filter((x) => x.deleted === false);
        const resLocations = await getAllLocations(context.profile.tenantId);
        const locationActive = resLocations.data.filter((x) => x.deleted === false);
        const resTgAsset = await getAllTaggedAssets(context.profile.tenantId);
        const resAreas = await getAllAreas(context.profile.tenantId);
        gatewayActive.forEach((gateway) => {
          gateway.area = resAreas.data.find((x) => x.id === gateway.areaId)?.name || 'NA';
        });
        console.log(gatewayActive);
        setTgAssets(resTgAsset.data);
        setLocations(locationActive);
        setGateways(gatewayActive);

        let socket = new SockJS(`${config.REACT_APP_SPIDEX_SOCKET_URI}/spdxsocket`);
        stompClient.current = Stomp.over(socket);
        stompClient.current.connect(
          (err) => {
            console.log(err);
            message.error('Unable to get the data, try again later');
          },
          () => {
            console.log('socket is Connected');
            setSelectedLocationId('2eb17a70-b070-4ac1-b5cb-7dbd48361c88');
        });
      } catch (e) {
        message.error('Unable to get Gateway details, try again later');
      } finally {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tempSub = subs.current;
    return () => {
      tempSub.forEach((sub) => {
        try {
          sub?.unsubscribe();
        } catch (e) {
          console.log(e);
        }
      });
      stompClient?.current?.disconnect();
    };
  }, []);

    //updating gateway data
    const gatewayWebsocketProx = (gateway) => {
      console.log(gateway.areaName);
      const subscribeFn = () => {
        const sub = stompClient.current.subscribe(`/data/proximity/${gateway.id}`, (message) => {
          const jsonData = JSON.parse(message.body);
          /*
          const lintData = [];
          lintAssets.forEach((data) => {
            if(data.devicePhyId === jsonData.devicePhyId)
            {
              lintData.push(jsonData);
            }
            else
            {
              lintData.push(data);
            }
          });
          console.log(lintData);
          */
          setProximityData(jsonData);
        });
        subs.current.push(sub);
      };
      if (stompClient?.current?.connected && gateway?.id) {
        if (subsGateways.current[gateway.id] !== true) subscribeFn();
        subsGateways.current[gateway.id] = true;
      }
    };

    useEffect(() => {
      console.log("enter gateway web socket");
      gateways
      .filter((x) => x.locId === selectedLocationId)
      .forEach((gateway) => {
        gatewayWebsocketProx(gateway);
      });
      console.log("end gateway web socket");
      // eslint-disable-next-line
    }, [selectedLocationId]);

  //On locations selector changing, slider cards data is updating for asset view & zone view
  const selectedLocation = (location) => {
    setSelectedLocationId(location);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const proximityRes = await getSensorProximityInitialByLocation(context.profile.tenantId, selectedLocationId);
        proximityRes.data.forEach((tent) => {
          tent.ticks = ticks;
        });
        setLintAssets(proximityRes.data);
        setLoading(false);
      } catch {
        message.error('Unable to get Sensor Proximity details, try again later');
      }
      console.log("end get proximity data");
    };
    init();
    // eslint-disable-next-line
  }, [selectedLocationId]);

/* TODO: Ticks should reset at every card/asset update */
function updateTicksDisplay(ticks) {
  const days = Math.floor(ticks / (60 * 60 * 24));
  const hours = Math.floor((ticks % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((ticks % (60 * 60)) / 60);
  const seconds = ticks % 60;

  const formattedDays = String(days).padStart(2, '0');
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  if(minutes > 0){
    return `${formattedMinutes}:${formattedSeconds} mins`;
  }else if(hours > 0){
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hrs`;
  }else if(seconds < 60){
    return `${formattedSeconds} secs`;
  }else {
    return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds} days`;
  }
}


function renderTop3LintAssets(lintAssets, lintChild) {
  if(proximityData !== null)
  {
    let updateDone = false;
    const tempData = [] ;
    lintAssets.forEach((data) => {
      console.log(proximityData);
      if(data.deviceLogId === proximityData.deviceLogId)
      {
        proximityData.ticks = ticks
        console.log(proximityData.ticks);
        tempData.push(proximityData);
        updateDone = true;
      }
      else
      {
        tempData.push(data);
      }
    });
    if(updateDone !== true)
    {
      tempData.push(proximityData);
    }
    setProximityData(null);
    setLintAssets(tempData);
  }
  const newAssets = lintAssets.sort(((a, b) => Number(b.proximity,) - Number(a.proximity)));

  const top3LintAssets = newAssets
      .filter((lintAsset) => lintChild.gatLintId === lintAsset.lintLogId)
      .slice(0, 10);

    const ticksLintAssets = [];

      top3LintAssets.forEach((ticktLintAsset) => {
        if((ticktLintAsset.ticks + 30) >= ticks)
        {
          ticksLintAssets.push(ticktLintAsset);
        }
      });
      console.log(ticksLintAssets);

      const assetNameStyle = {
        backgroundColor: 'lightblue', // Background color for the asset name
        width: '100px', // Fixed width
        height: '20px', // Fixed height
        border: '0px solid #888', // Border
        padding: '5px', // Padding
        borderRadius: '5px', // Rounded corners
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    
      const proximityStyle = {
        backgroundColor: 'lightblue', // Background color for proximity
        width: '40px', // Fixed width
        height: '20px', // Fixed height
        border: '0px solid #888', // Border
        padding: '5px', // Padding
        borderRadius: '5px', // Rounded corners
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

      const timertickStyle = {
        backgroundColor: 'lightblue', // Background color for proximity
        width: '140px', // Fixed width
        height: '20px', // Fixed height
        border: '0px solid #888', // Border
        padding: '5px', // Padding
        borderRadius: '5px', // Rounded corners
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

      function formatEpochSeconds(epochSeconds) {
        const date = new Date(epochSeconds * 1000); // Multiply by 1000 to convert to milliseconds
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
      
        const formattedDate = `${day}:${month}:${year} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      }

  /*TODO: The arguement to this updateTicksDisplay() should be the dwell time came from websocket/API */
  return ticksLintAssets.map((lintAsset) => (
      <p key={lintAsset.id}>
          <div className="site-space-compact-wrapper">
            <p>
              <Space size={10} align="center">
                <div style={assetNameStyle}>
                  {tgAssets.find((x) => x.id === lintAsset.deviceLogId)?.taggedAssetInfo?.assetName}
                </div>
                <Space size={10} align="center">
                <div style={proximityStyle}>
                  {lintAsset.proximity}
                </div>
                </Space>
                <Space size={10} align="center">
                <div style={timertickStyle}>
                  {formatEpochSeconds(lintAsset.reportedTime)}
                </div>
                </Space>
              </Space>
            </p>
          </div>
      </p>
  ));
}

/*
function renderLintAsset(lintAssets, lintChild) {
  return lintAssets.map((lintAsset) => {
      if (lintChild.gatLintId === lintAsset.lintLogId) {
          return (
              <p key={lintAsset.id}>
                  <div className="space-align-block">
                      <Tag>
                          <Space size={140} align="center">
                              <>
                                  {lintAsset.devPhysicalId}
                              </>
                              <>
                                  [00:00:00]
                              </>
                          </Space>
                      </Tag>
                  </div>
              </p>
          );
      } else {
          return <></>;
      }
  });
}
*/
/*
function renderLintItems(lints, lintAssets, loading) {
  console.log(tgAssets);
  console.log(lintAssets);
  return lints?.map((slide) => (
      <Col key={slide.id} span={24}>
          <Card key={slide.id} title={slide.area}>
              <Row key={slide.id} gutter={[16, 24]}>
                  {slide.children.map((lintChild) => (
                      <Col key={lintChild.id} span={8}>
                          <Card key={lintChild.id}
                                style={{ width: 350 }}
                                type="inner" title={lintChild.area}
                                //extra={lintAssets.filter((lintAsset) => lintChild.gatLintId === lintAsset.lintLogId).length}
                                extra={`Count: ${lintAssets.filter((lintAsset) => lintChild.gatLintId === lintAsset.lintLogId).length} YourAdditionalString`} 
                                loading={loading}>
                              {renderTop3LintAssets(lintAssets, lintChild)}
                          </Card>
                      </Col>
                  ))}
              </Row>
          </Card>
      </Col>
  ));
}

*/


function renderLintItems(lints, lintAssets, loading) {
    useEffect(() => {
        const timer = setInterval(() => {
            setTicks((prevTicks) => prevTicks + 1);
        }, 1000); // Update every second

        return () => {
            clearInterval(timer);
        };
    }, []);

    return lints?.map((slide) => (
        <Col key={slide.id} span={24}>
            <Card key={slide.id} title={slide.area} style={{ border: '1px solid #d9d9d9' }}>
                <Row key={slide.id} gutter={[16, 24]}>
                    {slide.children.map((lintChild) => (
                        <Col key={lintChild.id} span={8}>
                            <Card
                                key={lintChild.id}
                                style={{ width: 350, backgroundColor: lintAssets.filter((lintAsset) => lintChild.gatLintId === lintAsset.lintLogId).length === 0 ? 'lightgreen' : 'white' }}
                                type="inner"
                                title={lintChild.area}
                                extra={`Total: ${lintAssets.filter((lintAsset) => lintChild.gatLintId === lintAsset.lintLogId).length} | ${updateTicksDisplay(ticks)} ago`}
                                loading={loading}
                            >
                                {renderTop3LintAssets(lintAssets, lintChild)}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card>
        </Col>
    ));
}

  return (
    <>
    <Row className="searchDiv">
      <Col>
       <Row gutter={20} align="middle">
        <Col>
          <strong> Locations </strong>
        </Col>
        <Col>
          {locations.length > 0 && (
            <Select
              className={s.locationSelection}
              value={selectedLocationId}
              onChange={selectedLocation}
              allowClear={false}
              style={{
                width: 180,
              }}
            >
              {locations.map((loc) => (
                <Option key={loc.id} value={loc.id}>
                  {loc.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col>
          <Select
            defaultValue={lintSelect}
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'lint',
                label: 'Lint',
              },
              {
                value: 'gateway',
                label: 'Gateway',
              },
              {
                value: 'both',
                label: 'Both',
              },
            ]}
          />
        </Col>
       </Row>
      </Col>
    </Row>
    <Row gutter={[16, 24]}>
      {renderLintItems(lints, lintAssets, loading)}
    </Row>
    </>
  );
};

export default RestaurantDashboard;
