import { List, Dropdown, Button, Typography } from '../index';
import { MoreOutlined } from '@ant-design/icons';
import { Row, Col, Modal } from '../../components';
import { PermissionMenu } from '../../shared';
import { Menu } from '../index';
import _ from 'lodash';
import s from './index.module.less';

const { Text, Title } = Typography;

const CommonCompactView = ({ data, onEdit, onDelete, dataList, title, permissions }) => {
  const onMenuClick = (e, item) => {
    if (e.key === 'update') onEdit(item);
    if (e.key === 'delete') confirmDelete(item);
  };

  const confirmDelete = (item) => {
    Modal.confirm({
      title: `Are you sure you want to delete ?`,
      content: `${item.name}`,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => onDelete(item.id),
    });
  };

  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <Row className={item.deleted ? 'compactRowDel' : 'compactRow'}>
            <Col span={22}>
              <Title type={item.deleted && 'secondary'} level={3}>
                {item[title]}
              </Title>
              {dataList.map((e, index) => {
                return (
                  <div key={index}>
                    <Text type={item.deleted && 'secondary'}>
                      {e.label} :
                      {e.type === 'date'
                        ? new Date(item[e.value]).toDateString()
                        : e.type === 'gps'
                        ? `${item.gpsPoint?.latitude.substring(0, 6)},${item.gpsPoint?.longitude.substring(0, 6)}`
                        : e.type === 'boolean'
                        ? _.capitalize(item[e.value]?.toString())
                        : e.type === 'join'
                        ? item[e.value].join(',')
                        : item[e.value]}
                    </Text>
                    <br />
                  </div>
                );
              })}
              {item.parentId && (
                <>
                  <Text>Parent: {data.filter((x) => x.id === item.parentId)[0]?.name}</Text>
                  <br />
                </>
              )}
            </Col>
            <Col span={2}>
              {!item.deleted && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item className={s.menuItem} key="0">
                        <PermissionMenu onMenuClick={(e) => onMenuClick(e, item)} pagePermission={permissions} />
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button className="compactViewMore" icon={<MoreOutlined style={{ fontSize: '2em' }} />} />
                </Dropdown>
              )}
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default CommonCompactView;
