import React, { useContext } from 'react';
import { Result } from 'antd';
import Context from '../../context';
import { Link } from 'react-router-dom';
import { Button } from '../../components';

const Page404 = () => {
  const [context] = useContext(Context);

  if (context.isAuth) return <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;
  else {
    return (
      <Result
        extra={[
          <Link to={'/login'} key="login">
            <Button type="primary">Go to Login</Button>
          </Link>,
        ]}
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    );
  }
};

export default Page404;
