import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Button, Table, DatePicker, Radio, message, Row, Col } from '../../../components';
import {
  getAllAreas,
  getAllTenants,
  getAllVendors,
  vendorWiseReport,
  workerVendorWiseReport,
  vehicleWiseReport,
  areaWiseReport,
  monthlyBillingReport,
  dayBillingReport,
  vendorWiseReportInRange,
} from '../../../services';
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import ExcelJS from 'exceljs';
// import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import Context from '../../../context';
import { BreadcrumbList } from '../../../shared';
import { DateFormats, TimeZone, ModuleNames } from '../../../constants';
import moment from 'moment';
import s from './index.module.less';

const { Option } = Select;

const CreateReport = () => {
  const [context, setContext] = useContext(Context);
  const [vendors, setVendors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [reportTable, setReportTable] = useState(true);
  const [tableHead, setTableHead] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const dateFormat = DateFormats.TYPE_4;

  useEffect(() => {
    const init = async () => {
      setContext((state) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      try {
        const resTenants = await getAllTenants();
        setTenants(resTenants.data.filter((x) => x.deleted === false));
        const resVendors = await getAllVendors(context.profile.tenantId);
        setVendors(resVendors.data.filter((x) => x.deleted === false));
        const resAreas = await getAllAreas(context.profile.tenantId);
        setAreas(resAreas.data.filter((x) => x.deleted === false));
      } catch (e) {
        console.log(e);
        message.error('Unable to get details, try again later');
      } finally {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  const [value, setValue] = useState('day');
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onFinishVendor = async (val) => {
    const values = {
      ...val,
      reportDate:
        val['reportDate']?.format(DateFormats.TYPE_4) || val['reportDateRange'][1]?.format(DateFormats.TYPE_4),
      vendorName: vendors.find((x) => x.id === val.vendorId).vendorName,
      reportName: 'Vendor Wise Report',
      fromDate: val['reportDateRange'] ? val['reportDateRange'][0]?.format(DateFormats.TYPE_4) : '',
      toDate: val['reportDateRange'] ? val['reportDateRange'][1]?.format(DateFormats.TYPE_4) : '',
    };
    setTableHead(values);
    try {
      let vendorWiseReportRes;
      try {
        if (value === 'day')
          vendorWiseReportRes = await vendorWiseReport(
            context.profile.tenantId,
            val['reportDate']?.format(DateFormats.TYPE_3),
            values.vendorId
          );
        if (value === 'range')
          vendorWiseReportRes = await vendorWiseReportInRange(
            context.profile.tenantId,
            val['reportDateRange'][0]?.format(DateFormats.TYPE_3),
            val['reportDateRange'][1]?.format(DateFormats.TYPE_3),
            values.vendorId
          );
      } catch (e) {
        console.log(e);
        message.error('Unable to get details, try again later');
      }

      const temp = vendorWiseReportRes.data.map((item, index) => ({
        key: index + 1,
        eventRawDate: new Date(item.eventRawDate).toLocaleDateString().replace(/\//g, '-'),
        shiftName: item.shiftName,
        vendorName: item.vendorName,
        skillCategory: item.skillCategory,
        skillType: item.skillType,
        workerPackage: item.workerPackage,
        location: areas.find((y) => y.id === item.inAreaId)?.name,
        workerCount: item.workerCount,
      }));
      value === 'day' && temp.map((x) => delete x.eventRawDate);
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Shift Name', key: 'shiftName' },
        { title: 'Vendor Name', key: 'vendorName' },
        { title: 'Skill Category', key: 'skillCategory' },
        { title: 'Skill Type', key: 'skillType' },
        { title: 'Package', key: 'workerPackage' },
        { title: 'Location', key: 'location' },
        { title: 'No. of Workers', key: 'workerCount' },
      ];
      value === 'range' && tempObj.unshift({ title: 'Date', key: 'eventRawDate' });
      setTableColumns([
        {
          title: <strong> {tempObj[0].title} </strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong> {tempObj[1].title} </strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong> {tempObj[2].title} </strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong> {tempObj[3].title} </strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong> {tempObj[4].title} </strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
        {
          title: <strong> {tempObj[5].title} </strong>,
          dataIndex: tempObj[5].key,
          header: tempObj[5].title,
          key: tempObj[5].key,
        },
        {
          title: <strong> {tempObj[6].title} </strong>,
          dataIndex: tempObj[6].key,
          header: tempObj[6].title,
          key: tempObj[6].key,
        },
      ]);
      value === 'range' &&
        setTableColumns((state) => [
          ...state,
          {
            title: <strong> {tempObj[7].title} </strong>,
            dataIndex: tempObj[7].key,
            header: tempObj[7].title,
            key: tempObj[7].key,
          },
        ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  const onFinishVendorWorker = async (val) => {
    const values = {
      ...val,
      reportDate: val['reportDate'].format(DateFormats.TYPE_4),
      vendorName: vendors.find((x) => x.id === val.vendorId)?.vendorName,
      reportName: 'Vendor Wise Worker Report',
    };
    setTableHead(values);
    try {
      const workerVendorWiseRes = await workerVendorWiseReport(
        context.profile.tenantId,
        val['reportDate'].format(DateFormats.TYPE_3),
        values.vendorId || 'NA'
      );
      const temp = workerVendorWiseRes.data.map((item, index) => {
        return {
          key: index + 1,
          shiftName: item.shiftName,
          vendorName: item.vendorName,
          devicePhyId: item.devicePhyId,
          workerName: item.workerName,
          skillCategory: item.skillCategory,
          skillType: item.skillType,
          workerPackage: item.workerPackage,
          location: areas.find((y) => y.id === item.inAreaId)?.name,
          inTime: item.inTime,
          outTime: item.outTime,
          workedHours: item.workedHours,
          ot: item.workedHours ? parseInt(item.workedHours) - 9 : '',
        };
      });
      console.log(temp);
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Shift Name', key: 'shiftName' },
        { title: 'Vendor Name', key: 'vendorName' },
        { title: 'Tag ID', key: 'devicePhyId' },
        { title: 'Worker name', key: 'workerName' },
        { title: 'Skill Category', key: 'skillCategory' },
        { title: 'Skill Type', key: 'skillType' },
        { title: 'Package', key: 'workerPackage' },
        { title: 'Location', key: 'location' },
        { title: 'In Time', key: 'inTime' },
        { title: 'Out Time', key: 'outTime' },
        { title: 'Total Hours', key: 'workedHours' },
        { title: 'OT', key: 'ot' },
      ];
      setTableColumns([
        {
          title: <strong> {tempObj[0].title} </strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong> {tempObj[1].title} </strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong> {tempObj[2].title} </strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong> {tempObj[3].title} </strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong> {tempObj[4].title} </strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
        {
          title: <strong> {tempObj[5].title} </strong>,
          dataIndex: tempObj[5].key,
          header: tempObj[5].title,
          key: tempObj[5].key,
        },
        {
          title: <strong> {tempObj[6].title} </strong>,
          dataIndex: tempObj[6].key,
          header: tempObj[6].title,
          key: tempObj[6].key,
        },
        {
          title: <strong> {tempObj[7].title} </strong>,
          dataIndex: tempObj[7].key,
          header: tempObj[7].title,
          key: tempObj[7].key,
        },
        {
          title: <strong> {tempObj[8].title} </strong>,
          dataIndex: tempObj[8].key,
          header: tempObj[8].title,
          key: tempObj[8].key,
        },
        {
          title: <strong> {tempObj[9].title} </strong>,
          dataIndex: tempObj[9].key,
          header: tempObj[9].title,
          key: tempObj[9].key,
        },
        {
          title: <strong> {tempObj[10].title} </strong>,
          dataIndex: tempObj[10].key,
          header: tempObj[10].title,
          key: tempObj[10].key,
        },
        {
          title: <strong> {tempObj[11].title} </strong>,
          dataIndex: tempObj[11].key,
          header: tempObj[11].title,
          key: tempObj[11].key,
        },
      ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  const onFinishArea = async (val) => {
    const values = {
      ...val,
      reportDate: val['reportDate'].format(DateFormats.TYPE_4),
      areaName: areas.find((x) => x.id === val.location).name,
      reportName: 'Area Wise Report',
    };
    setTableHead(values);
    try {
      const areaWiseRes = await areaWiseReport(
        context.profile.tenantId,
        val['reportDate'].format(DateFormats.TYPE_3),
        values.location
      );
      const temp = areaWiseRes.data.map((item, index) => ({
        key: index + 1,
        vendorName: item.vendorName,
        skillCategory: item.skillCategory,
        skillType: item.skillType,
        location: areas.find((y) => y.id === item.inAreaId)?.name,
        workerCount: item.workerCount,
      }));
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Vendor Name', key: 'vendorName' },
        { title: 'Skill Category', key: 'skillCategory' },
        { title: 'Skill Type', key: 'skillType' },
        { title: 'Location', key: 'location' },
        { title: 'No. of Workers', key: 'workerCount' },
      ];
      setTableColumns([
        {
          title: <strong>{tempObj[0].title} </strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong>{tempObj[1].title} </strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong>{tempObj[2].title} </strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong>{tempObj[3].title} </strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong>{tempObj[4].title} </strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
      ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  const onFinishDayReport = async (val) => {
    const values = {
      ...val,
      fromDate: val['reportDateRange'][0].format(DateFormats.TYPE_4),
      toDate: val['reportDateRange'][1].format(DateFormats.TYPE_4),
      reportDate: val['reportDateRange'][1].format(DateFormats.TYPE_3),
      reportName: 'Day Billing Report',
    };
    setTableHead(values);
    try {
      const dayBillingRes = await dayBillingReport(
        context.profile.tenantId,
        val['reportDateRange'][0].format(DateFormats.TYPE_3),
        val['reportDateRange'][1].format(DateFormats.TYPE_3),
        val.vendorId
      );
      const temp = dayBillingRes.data.map((item, index) => ({
        key: index + 1,
        eventRawDate: moment(item.eventRawDate).tz(TimeZone.ASIA_KOLKATA).format(DateFormats.TYPE_2),
        vendorName: item.vendorName,
        skillCategory: item.skillCategory,
        skillType: item.skillType,
        workerPackage: item.workerPackage,
        location: areas.find((y) => y.id === item.inAreaId)?.name,
        workerCount: item.workerCount,
        billingAmt: item.billingAmt,
      }));
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Date', key: 'eventRawDate' },
        { title: 'Vendor Name', key: 'vendorName' },
        { title: 'Skill Category', key: 'skillCategory' },
        { title: 'Skill Type', key: 'skillType' },
        { title: 'Package', key: 'workerPackage' },
        { title: 'Location', key: 'location' },
        { title: 'No. of Workers', key: 'workerCount' },
        { title: 'Billing Amount', key: 'billingAmt' },
      ];
      setTableColumns([
        {
          title: <strong>{tempObj[0].title}</strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong>{tempObj[1].title}</strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong>{tempObj[2].title}</strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong>{tempObj[3].title}</strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong>{tempObj[4].title}</strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
        {
          title: <strong>{tempObj[5].title}</strong>,
          dataIndex: tempObj[5].key,
          header: tempObj[5].title,
          key: tempObj[5].key,
        },
        {
          title: <strong>{tempObj[6].title}</strong>,
          dataIndex: tempObj[6].key,
          header: tempObj[6].title,
          key: tempObj[6].key,
        },
        {
          title: <strong>{tempObj[7].title}</strong>,
          dataIndex: tempObj[7].key,
          header: tempObj[7].title,
          key: tempObj[7].key,
        },
      ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  const onFinishMonthlyReport = async (val) => {
    const values = {
      ...val,
      fromDate: val['reportDateRange'][0].format(DateFormats.TYPE_4),
      toDate: val['reportDateRange'][1].format(DateFormats.TYPE_4),
      reportDate: val['reportDateRange'][1].format(DateFormats.TYPE_3),
      reportName: 'Monthly Billing Report',
    };
    setTableHead(values);
    try {
      const monthlyBillingRes = await monthlyBillingReport(
        context.profile.tenantId,
        val['reportDateRange'][0].format(DateFormats.TYPE_3),
        val['reportDateRange'][1].format(DateFormats.TYPE_3),
        val.vendorId
      );
      const temp = monthlyBillingRes.data.map((item, index) => ({
        key: index + 1,
        vendorName: item.vendorName,
        skillCategory: item.skillCategory,
        skillType: item.skillType,
        workerPackage: item.workerPackage,
        location: areas.find((y) => y.id === item.inAreaId)?.name,
        workerCount: item.workerCount,
        billingAmt: item.billingAmt,
      }));
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Vendor Name', key: 'vendorName' },
        { title: 'Skill Category', key: 'skillCategory' },
        { title: 'Skill Type', key: 'skillType' },
        { title: 'Package', key: 'workerPackage' },
        { title: 'Location', key: 'location' },
        { title: 'No. of Workers', key: 'workerCount' },
        { title: 'Billing Amount', key: 'billingAmt' },
      ];
      setTableColumns([
        {
          title: <strong>{tempObj[0].title}</strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong>{tempObj[1].title}</strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong>{tempObj[2].title}</strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong>{tempObj[3].title}</strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong>{tempObj[4].title}</strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
        {
          title: <strong>{tempObj[5].title}</strong>,
          dataIndex: tempObj[5].key,
          header: tempObj[5].title,
          key: tempObj[5].key,
        },
        {
          title: <strong>{tempObj[6].title}</strong>,
          dataIndex: tempObj[6].key,
          header: tempObj[6].title,
          key: tempObj[6].key,
        },
      ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  const onFinishVehicle = async (val) => {
    const values = {
      ...val,
      reportDate: val['reportDate'].format(DateFormats.TYPE_4),
      vendorName: vendors.find((x) => x.id === val.vendorId).vendorName,
      reportName: 'Vehicle Wise Report',
    };
    setTableHead(values);
    try {
      const vehicleWiseRes = await vehicleWiseReport(
        context.profile.tenantId,
        val['reportDate'].format(DateFormats.TYPE_3),
        values.vendorId
      );
      const temp = vehicleWiseRes.data.map((item, index) => ({
        key: index + 1,
        vehicelNumber: item.vehicelNumber,
        vehicleType: item.vehicleType,
        location: areas.find((y) => y.id === item.inAreaId)?.name,
        inTime: moment(item.inTime).tz(TimeZone.ASIA_KOLKATA).format(DateFormats.TYPE_2),
        outTime: moment(item.outTime).tz(TimeZone.ASIA_KOLKATA).format(DateFormats.TYPE_2),
      }));
      const tempObj = [
        { title: 'No.', key: 'key' },
        { title: 'Vehicle Number', key: 'vehicelNumber' },
        { title: 'Vehicle Type', key: 'vehicleType' },
        { title: 'Location', key: 'location' },
        { title: 'In Time', key: 'inTime' },
        { title: 'Out Time', key: 'outTime' },
      ];
      setTableColumns([
        {
          title: <strong>{tempObj[0].title}</strong>,
          dataIndex: tempObj[0].key,
          header: tempObj[0].title,
          key: tempObj[0].key,
        },
        {
          title: <strong>{tempObj[1].title}</strong>,
          dataIndex: tempObj[1].key,
          header: tempObj[1].title,
          key: tempObj[1].key,
        },
        {
          title: <strong>{tempObj[2].title}</strong>,
          dataIndex: tempObj[2].key,
          header: tempObj[2].title,
          key: tempObj[2].key,
        },
        {
          title: <strong>{tempObj[3].title}</strong>,
          dataIndex: tempObj[3].key,
          header: tempObj[3].title,
          key: tempObj[3].key,
        },
        {
          title: <strong>{tempObj[4].title}</strong>,
          dataIndex: tempObj[4].key,
          header: tempObj[4].title,
          key: tempObj[4].key,
        },
      ]);
      setTableData(temp);
      setReportTable(false);
    } catch (e) {
      console.log(e);
      message.error('Unable to get details, try again later');
    }
  };

  // const workbook = new ExcelJS.Workbook();
  // const excelFile = async (
  //   {
  //     vendorName = tableHead.vendorName,
  //     areaName = tableHead.areaName,
  //     reportDate = tableHead.reportDate,
  //     reportName = tableHead.reportName,
  //     tenantName = tenants.find((x) => x.id === vendors[0].tenantId)?.name,
  //   },
  //   tableData,
  //   tableColumns
  // ) => {
  //   try {
  //     const worksheet = workbook.addWorksheet(`${vendorName || areaName}-${reportDate}`);
  //     worksheet.columns = tableColumns;

  //     worksheet.columns.forEach((column) => {
  //       column.alignment = { horizontal: 'left' };
  //     });

  //     let rowValues4 = [];
  //     worksheet.insertRow(1, rowValues4);
  //     let rowValues3 = [];
  //     rowValues3[3] = reportDate;
  //     worksheet.insertRow(1, rowValues3);
  //     let rowValues2 = [];
  //     rowValues2[3] = tenantName;
  //     worksheet.insertRow(1, rowValues2);
  //     let rowValues1 = [];
  //     rowValues1[3] = reportName;
  //     worksheet.insertRow(1, rowValues1);
  //     let rowValues0 = [];
  //     rowValues0[3] = tenants.find((x) => x.id === vendors[0].tenantId)?.orgName;
  //     worksheet.insertRow(1, rowValues0);

  //     tableData.forEach((singleData) => {
  //       worksheet.addRow(singleData);
  //     });

  //     const buf = await workbook.xlsx.writeBuffer();
  //     saveAs(new Blob([buf]), `${vendorName || areaName}-${reportDate}.xlsx`);
  //   } catch (error) {
  //     console.error('error', error);
  //   }
  // };
  // const csvFile = (
  //   {
  //     vendorName = tableHead.vendorName,
  //     areaName = tableHead.areaName,
  //     reportDate = tableHead.reportDate,
  //     reportName = tableHead.reportName,
  //     tenantName = tenants.find((x) => x.id === vendors[0].tenantId)?.name,
  //   },
  //   tableData,
  //   tableColumns
  // ) => {
  //   let csvContent = 'data:text/csv;charset=utf-8,';
  //   const csvData = [];
  //   csvData.push(
  //     ['', '', context.tenantProfile?.reportHeadingText || 'Report'],
  //     ['', '', reportName],
  //     ['', '', tenantName],
  //     ['', '', reportDate]
  //   );

  //   tableData.map((e, index) => {
  //     if (index === 0) {
  //       const ky = tableColumns?.map((x) => x.header);
  //       ky.unshift('Key');
  //       csvData.push(ky);
  //     }
  //     const vl = Object.values(e).map((x) => x);
  //     csvData.push(vl);
  //     return csvData;
  //   });
  //   csvContent += csvData.map((e) => e.join(',')).join('\n');
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement('a');
  //   link.setAttribute('href', encodedUri);
  //   link.setAttribute('download', `${vendorName || areaName}-${reportDate}.csv`);
  //   document.body.appendChild(link); // Required for FF
  //   link.click();
  // };

  const pdfFile = ({
    vendorName = tableHead.vendorName,
    reportDate = tableHead.reportDate,
    reportName = tableHead.reportName,
    tenantName = tenants.find((x) => x.id === vendors[0].tenantId)?.name,
  }) => {
    const input = document.getElementById('report-container');
    const pdf = new jsPDF('l', 'pt', 'a4', true);
    // setDownloading(true);
    pdf.html(input, { html2canvas: { scale: 0.5 }, margin: [20, 0, 20, 0] }).then(() => {
      pdf.save(`${tenantName}-${vendorName}-${reportName}-${reportDate.toString()}.pdf`);
      // setDownloading(false);
    });
  };

  // const dropdownMenu = (
  //   <Menu>
  //     <Menu.Item key="excel" onClick={() => excelFile(tableHead, tableData, tableColumns)}>
  //       Excel
  //     </Menu.Item>
  //     <Menu.Item key="csv" onClick={() => csvFile(tableHead, tableData, tableColumns)}>
  //       CSV
  //     </Menu.Item>
  //     <Menu.Item key="pdf" onClick={() => pdfFile(tableHead)}>
  //       PDF
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={['Home', ModuleNames.REPORT, ModuleNames.GENERATE, ModuleNames.CREATE]} />
        </Col>
      </Row>
      {reportTable ? (
        <>
          <Row justify="center">
            <Col>
              <h1 className={s.reportHeading}>
                <strong> REPORTS</strong>
              </h1>
            </Col>
          </Row>
          <Row className={s.reportCardsContainer} justify="center">
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> VENDOR WISE </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishVendor}>
                    <Form.Item
                      className={s.vendorField}
                      hasFeedback
                      name="vendorId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Vendor!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Vendor"
                      >
                        {vendors.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.vendorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item className={`${s.vendorFieldRadio} mt-3 ml-1`}>
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={'day'}>Day</Radio>
                        <Radio value={'range'}>Range</Radio>
                      </Radio.Group>
                    </Form.Item>

                    {value === 'day' && (
                      <Form.Item
                        shouldUpdate={true}
                        hasFeedback
                        name="reportDate"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Report Date!',
                          },
                        ]}
                      >
                        <DatePicker format={dateFormat} className={`${s.datePicker}`} />
                      </Form.Item>
                    )}
                    {value === 'range' && (
                      <Form.Item
                        shouldUpdate={true}
                        hasFeedback
                        name="reportDateRange"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Report Date!',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker format={dateFormat} className={`${s.datePicker}`} />
                      </Form.Item>
                    )}

                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-2`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-2`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> VENDOR WORKER WISE </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishVendorWorker}>
                    <Form.Item hasFeedback name="vendorId" className={s.selectInputFields}>
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Vendor"
                      >
                        {vendors.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.vendorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={true}
                      hasFeedback
                      name="reportDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Report Date!',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} className={`${s.datePicker}`} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-4`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-4`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> AREA WISE </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishArea}>
                    <Form.Item
                      className={s.selectInputFields}
                      hasFeedback
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Area!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Area"
                      >
                        {areas.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={true}
                      hasFeedback
                      name="reportDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Report Date!',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} className={`${s.datePicker}`} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-4`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-4`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> MONTHLY BILLING </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishMonthlyReport}>
                    <Form.Item
                      className={s.selectInputFields}
                      hasFeedback
                      name="vendorId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Vendor!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Vendor"
                      >
                        {vendors.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.vendorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={true}
                      hasFeedback
                      name="reportDateRange"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Report Date!',
                        },
                      ]}
                    >
                      <DatePicker.RangePicker format={dateFormat} className={`${s.datePicker}`} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-4`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-4`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> DAY BILLING </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishDayReport}>
                    <Form.Item
                      className={s.selectInputFields}
                      hasFeedback
                      name="vendorId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Vendor!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Vendor"
                      >
                        {vendors.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.vendorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={true}
                      hasFeedback
                      name="reportDateRange"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Report Date!',
                        },
                      ]}
                    >
                      <DatePicker.RangePicker format={dateFormat} className={`${s.datePicker}`} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-4`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-4`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col className={s.reportCard} span={8}>
              <Row justify="center" className={s.reportCardTitle}>
                <Col>
                  <h3 className={s.title}> VEHICLE WISE </h3>
                </Col>
              </Row>
              <Row justify="center" align="middle" className={`${s.cardBody} mt-1`}>
                <Col span={24}>
                  <Form size="default" onFinish={onFinishVehicle}>
                    <Form.Item
                      className={s.selectInputFields}
                      hasFeedback
                      name="vendorId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Vendor!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Vendor"
                      >
                        {vendors.map((b) => (
                          <Option title={b.vendorName} key={b.id} value={b.id}>
                            {b.vendorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={true}
                      hasFeedback
                      name="reportDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Report Date!',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} className={`${s.datePicker}`} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6 }}>
                      <Button
                        className={
                          context.isCompact
                            ? `${s.submitButton} commonSaveButton formButton mt-4`
                            : `${s.submitButton} commonSaveButton formButton w-75 mt-4`
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <div>
          <Row justify="center" className={s.reportHeader}>
            <Col>
              <h1 className="text-center">
                <strong> {context.tenantProfile?.reportHeadingText || 'Report'}</strong>
              </h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <h3 className="text-center">{context.tenantProfile?.reportSubHeadingText || ''}</h3>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <h4 className="text-center">{tableHead.reportName}</h4>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={6}>
              <Button className="backBtn" size="small" onClick={() => setReportTable(true)}>
                <ArrowLeftOutlined />
              </Button>
            </Col>
            <Col>
              {tableHead.fromDate && tableHead.toDate ? (
                <p>
                  {tableHead.fromDate} to {tableHead.toDate}
                </p>
              ) : (
                <p> {tableHead?.reportDate}</p>
              )}
            </Col>
            <Col span={6}>
              <Row justify="end">
                <Col>
                  <Button type="primary" className="downloadButton" size="small" onClick={() => pdfFile(tableHead)}>
                    {!context.isCompact ? (
                      <>
                        Download <DownloadOutlined />
                      </>
                    ) : (
                      <DownloadOutlined />
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className={s.reportTable}>
            <Table columns={tableColumns} dataSource={tableData} scroll={{ x: true }} size="small" />
          </div>
          <div style={{ display: 'none' }}>
            <div id="report-container" style={{ width: 1660, padding: 20 }}>
              <div>
                <h2 className="mx-2 text-center">
                  <strong> {context.tenantProfile?.reportHeadingText || 'Report'}</strong>
                </h2>
              </div>
              <div className="text-center">
                <h3>{context.tenantProfile?.reportSubHeadingText || ''}</h3>
              </div>
              <div className="text-center">
                <h4 className="mx-2">{tableHead.reportName}</h4>
              </div>
              <div className="text-center">
                {tableHead.fromDate && tableHead.toDate ? (
                  <p>
                    {tableHead.fromDate} to {tableHead.toDate}
                  </p>
                ) : (
                  <p> {tableHead?.reportDate}</p>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <table className={s.pdfTable}>
                  <thead>
                    <tr>
                      {tableColumns.map((item) => (
                        <th key={item.dataIndex}>{item.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item) => (
                      <tr key={item.key}>
                        {tableColumns.map((column) => (
                          <td key={column.dataIndex}>{item[column.dataIndex]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateReport;
