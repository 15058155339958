import axios from '../axios';

const EVENTATTR_URI = '/eventAttr/';
const DEVICE_MODEL_CONFIG_URI = '/deviceModel/';

export const getAllEventAttrs = (tenantId) => {
  return axios.get(`${EVENTATTR_URI}all`, { params: { tenantId } });
};

export const addEventAttr = (eventAttr) => {
  return axios.post(`${EVENTATTR_URI}`, eventAttr);
};

export const updateEventAttr = (eventAttr) => {
  return axios.put(`${EVENTATTR_URI}`, eventAttr);
};

export const deleteEventAttr = (eventAttrId) => {
  return axios.delete(`${EVENTATTR_URI}${eventAttrId}`);
};

export const getAllTagConfigs = () => {
  return axios.get(`${DEVICE_MODEL_CONFIG_URI}all`);
};

export const addTagConfig = (tagConfig) => {
  return axios.post(`${DEVICE_MODEL_CONFIG_URI}`, tagConfig);
};

export const deleteTagConfig = (tagConfigId) => {
  return axios.delete(`${DEVICE_MODEL_CONFIG_URI}${tagConfigId}`);
};

export const getAllGatewayConfigs = () => {
  return axios.get(`${DEVICE_MODEL_CONFIG_URI}all`);
};

export const addGatewayConfig = (gatewayConfig) => {
  return axios.post(`${DEVICE_MODEL_CONFIG_URI}`, gatewayConfig);
};

export const deleteGatewayConfig = (gatewayConfigId) => {
  return axios.delete(`${DEVICE_MODEL_CONFIG_URI}${gatewayConfigId}`);
};
