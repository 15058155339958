import { Layout, Menu, Dropdown, Spin, Button, Drawer, Switch, Row, Col, Grid, Avatar } from './components';
import { Notifications, PageLoader } from './shared';
import { getMenu } from './menu';
import React, { Suspense, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Context, { state, initialState } from './context';
import { dummyInitialData } from './dummyPermissions';
import { ModuleNames, Pages, RoutePaths, TenantProperties } from './constants';
import Login from './routes/Login';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Switch as RouterSwitch, Route, Link, useHistory, useLocation } from 'react-router-dom';
import * as config from './config';
import { nonModulePrivateRoutes, privateRoutes, publicRoutes } from './routes';
import axios from './axios';
import { get } from 'lodash';
import axiosAlternate from './axiosAlternate';
import { isExpired } from './utils';
import tinycolor from 'tinycolor2';
import { lazyWithRetry } from './customReactLazy';
import { getTenantProfile } from './services';
import { ConfigProvider } from 'antd';
import './App.less';
import './Util.module.less';
import 'antd/dist/antd.variable.min.css';

const Page404 = lazyWithRetry(() => import('./routes/404'));
const Privacy = lazyWithRetry(() => import('./routes/Privacy'));

// app defaults
moment.tz.setDefault('Asia/Kolkata');

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const App = () => {
  const screens = Grid.useBreakpoint();
  const history = useHistory();
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [dashboardMenu, setDashboarMenu] = useState([]);
  const [context, setContext] = useState(state);
  const [routes, setRoutes] = useState(publicRoutes());
  const [collapsed, setCollapsed] = useState(false);
  const [drawerCollapsed, setDrawerCollapsed] = useState(false);
  const [currentMenuCategory, setCurrentMenuCategory] = useState('');
  const [currentMenuItem, setCurrentMenuItem] = useState(location.pathname);

  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: context.tenantProfile?.buttonColor || config.REACT_APP_THEME_PRIMARY_COLOR,
      },
    });
    const el = document.getElementsByTagName('body')[0];
    const defaultFont =
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
    if (el) {
      if (context.tenantProfile.fontFamily) {
        el.style.fontFamily =
          context.tenantProfile.fontFamily === 'Apple System' ? defaultFont : context.tenantProfile.fontFamily;
      }
    }
  }, [context.tenantProfile]);

  useEffect(() => {
    setCurrentMenuItem(exactPath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    setCurrentMenuCategory(localStorage.getItem('openItem') || location.pathname);
    // eslint-disable-next-line
  }, [currentMenuCategory]);

  const exactPath = (str) => {
    const a = str.split('/');
    var pattern = new RegExp(/[0-9]/);
    if (pattern.test(str)) {
      a.pop();
      return a.join('/');
    } else {
      return a.join('/');
    }
  };

  axios.interceptors.request.use(
    (req) => {
      const bearerToken = localStorage.getItem('token') || null;
      if (bearerToken) {
        if (isExpired(bearerToken)) {
          setContext(initialState);
          localStorage.removeItem('token');
          localStorage.removeItem('profile');
          localStorage.removeItem('pagePermissions');
          history.push('/login/1');
        } else {
          console.log('Please dont spy here :)');
        }
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axiosAlternate.interceptors.request.use(
    (req) => {
      const bearerToken = localStorage.getItem('token') || null;
      if (bearerToken) {
        if (isExpired(bearerToken)) {
          setContext(initialState);
          localStorage.removeItem('token');
          localStorage.removeItem('profile');
          localStorage.removeItem('pagePermissions');
          history.push('/login/1');
        } else {
          console.log('Please dont spy here :)');
        }
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  // update tenant properties
  useEffect(() => {
    const tenantProperties = TenantProperties[config.REACT_APP_CUSTOM_HOST];
    document.title = "Escapade";
    var link = document.querySelector("link[id~='icon1']");
    var link2 = document.querySelector("link[id~='icon2']");
    link.href = `${tenantProperties.icon}`;
    link2.href = `${tenantProperties.icon}`;
  }, []);

  useEffect(() => {
    const init = async () => {
      const allowDev = false;
      if (allowDev) {
        if (dummyInitialData.isAuth) setRoutes([...privateRoutes(dummyInitialData.pagePermissions), ...publicRoutes()]);
        else setRoutes(publicRoutes());
        setContext((ps) => ({ ...ps, isLoading: false }));
      } else {
        if (!context.isAuth) return;
        const menuObject = getMenu(context.pagePermissions);
        setContext((ps) => ({ ...ps, isLoading: true }));
        try {
          const tenantProfile = await getTenantProfile(context.profile.tenantId);
          const type = context.techMode ? 'techName' : 'displayName';

          const namingMap = {
            [Pages.AREA]: get(tenantProfile, `data.moduleNames[${Pages.AREA}][${type}]`, ModuleNames.AREA),
            [Pages.ASSET]: get(tenantProfile, `data.moduleNames[${Pages.ASSET}][${type}]`, ModuleNames.ASSET),
            [Pages.BRANCH]: get(tenantProfile, `data.moduleNames[${Pages.BRANCH}][${type}]`, ModuleNames.BRANCH),
            [Pages.GATEWAY]: get(tenantProfile, `data.moduleNames[${Pages.GATEWAY}][${type}]`, ModuleNames.GATEWAY),
            [Pages.TRANSIT]: get(
              tenantProfile,
              `data.moduleNames[${Pages.TRANSIT}][${type}]`,
              ModuleNames.TRANSIT_AREA
            ),
            [Pages.LOCATION]: get(tenantProfile, `data.moduleNames[${Pages.LOCATION}][${type}]`, ModuleNames.LOCATION),
            [Pages.TAG]: get(tenantProfile, `data.moduleNames[${Pages.TAG}][${type}]`, ModuleNames.TAG),
            [Pages.ONBOARD]: get(tenantProfile, `data.moduleNames[${Pages.ONBOARD}][${type}]`, ModuleNames.ONBOARD),
            [Pages.EVENT_ATTRIBUTE]: get(
              tenantProfile,
              `data.moduleNames[${Pages.EVENT_ATTRIBUTE}][${type}]`,
              ModuleNames.EVENT_ATTRIBUTE
            ),
            [Pages.GATEWAY_CONFIG]: get(
              tenantProfile,
              `data.moduleNames[${Pages.GATEWAY_CONFIG}][${type}]`,
              ModuleNames.GATEWAY_CONFIG
            ),
            [Pages.TAG_CONFIG]: get(
              tenantProfile,
              `data.moduleNames[${Pages.TAG_CONFIG}][${type}]`,
              ModuleNames.TAG_CONFIG
            ),
            [Pages.ACCOUNT]: get(tenantProfile, `data.moduleNames[${Pages.ACCOUNT}][${type}]`, ModuleNames.ACCOUNT),
            [Pages.TENANT]: get(tenantProfile, `data.moduleNames[${Pages.TENANT}][${type}]`, ModuleNames.TENANT),
            [Pages.RBAC]: get(tenantProfile, `data.moduleNames[${Pages.RBAC}][${type}]`, ModuleNames.RBAC),
            [Pages.VENDOR]: get(tenantProfile, `data.moduleNames[${Pages.VENDOR}][${type}]`, ModuleNames.VENDOR),
            [Pages.VEHICLE]: get(tenantProfile, `data.moduleNames[${Pages.VEHICLE}][${type}]`, ModuleNames.VEHICLE),
            [Pages.WORKER]: get(tenantProfile, `data.moduleNames[${Pages.WORKER}][${type}]`, ModuleNames.WORKER),
            [Pages.WASHROOM]: get(tenantProfile, `data.moduleNames[${Pages.WASHROOM}][${type}]`, ModuleNames.SANITARY),
            [Pages.ECU]: get(tenantProfile, `data.moduleNames[${Pages.ECU}][${type}]`, ModuleNames.ECU),
            [Pages.VIN]: get(tenantProfile, `data.moduleNames[${Pages.VIN}][${type}]`, ModuleNames.VIN),
            [Pages.VEHICLE_MODEL]: get(
              tenantProfile,
              `data.moduleNames[${Pages.VEHICLE_MODEL}][${type}]`,
              ModuleNames.VEHICLE_MODEL
            ),
            [Pages.ECU_MODEL]: get(
              tenantProfile,
              `data.moduleNames[${Pages.ECU_MODEL}][${type}]`,
              ModuleNames.ECU_MODEL
            ),
            [Pages.PROVISION]: get(
              tenantProfile,
              `data.moduleNames[${Pages.PROVISION}][${type}]`,
              ModuleNames.PROVISION
            ),
            [Pages.DEVICE_MANAGEMENT_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.DEVICE_MANAGEMENT_DASHBOARD}][${type}]`,
              ModuleNames.DEVICE_MANAGEMENT
            ),
            [Pages.ASSET_TRACKING_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.ASSET_TRACKING_DASHBOARD}][${type}]`,
              ModuleNames.ASSET_TRACKING
            ),
            [Pages.SANITARY_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.SANITARY_DASHBOARD}][${type}]`,
              ModuleNames.SANITARY
            ),
            [Pages.RESTAURANT_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.RESTAURANT_DASHBOARD}][${type}]`,
              ModuleNames.RESTAURANT
            ),
            [Pages.PEOPLE_TRACKING_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.PEOPLE_TRACKING_DASHBOARD}][${type}]`,
              ModuleNames.PEOPLE_TRACKING
            ),
            [Pages.VEHICLE_DIAGNOSTICS_DASHBOARD]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.VEHICLE_DIAGNOSTICS_DASHBOARD}][${type}]`,
              ModuleNames.VEHICLE_DIAGNOSTICS
            ),
            [Pages.INDOOR_MAP_SETUP]: get(
              tenantProfile,
              `data.dashboardNames[${Pages.INDOOR_MAP_SETUP}][${type}]`,
              'Setup'
            ),
            [Pages.APPLICATION_MANAGEMENT_VISUAL]: ModuleNames.APPLICATION_MANAGEMENT,
          };
          menuObject.forEach((category) => {
            category.category.modules.forEach((module) => {
              module.subModules.forEach((subModule) => {
                subModule.name = namingMap[subModule.pageName];
              });
            });
          });

          const dashboardMenuObject = nonModulePrivateRoutes(context.pagePermissions);

          dashboardMenuObject.forEach((dashboard) => {
            dashboard.name = namingMap[dashboard.pageName];
          });

          if (!tenantProfile.data?.fontFamily) tenantProfile.data.fontFamily = 'Apple System';
          if (!tenantProfile.data?.tableProperties?.headerAlign)
            tenantProfile.data.tableProperties.headerAlign = 'Left';
          if (!tenantProfile.data?.reportHeadingText) tenantProfile.data.reportHeadingText = ModuleNames.REPORT;
          if (!tenantProfile.data?.reportSubHeadingText) tenantProfile.data.reportSubHeadingText = '';
          if (!tenantProfile.data?.buttonColor) tenantProfile.data.buttonColor = config.REACT_APP_THEME_PRIMARY_COLOR;

          setContext((ps) => ({ ...ps, tenantProfileLoaded: true, tenantProfile: tenantProfile.data }));
          setMenu(menuObject);
          setDashboarMenu(dashboardMenuObject);
        } catch (e) {
          console.log(e);
          setMenu(getMenu(context.pagePermissions));
          setDashboarMenu(nonModulePrivateRoutes(context.pagePermissions));
        } finally {
          if (context.isAuth) setRoutes([...privateRoutes(context.pagePermissions), ...publicRoutes()]);
          else setRoutes(publicRoutes());
          setContext((ps) => ({ ...ps, isLoading: false }));
        }
      }
    };
    init();
    // eslint-disable-next-line
  }, [context.isAuth, context.pagePermissions, context.profile.tenantId, context.techMode]);

  const handleTechModeChange = () => {
    setContext((ps) => ({ ...ps, techMode: !ps.techMode }));
  };

  useEffect(() => {
    setCurrentMenuCategory(dashboardMenu.find((x) => x.path === location.pathname)?.name);
    // eslint-disable-next-line
  }, [dashboardMenu]);

  useEffect(() => {
    if (screens.xxl || screens.xl || screens.lg) setContext((state) => ({ ...state, isCompact: false }));
    else setContext((state) => ({ ...state, isCompact: true }));
  }, [screens]);

  const logout = () => {
    setContext({ ...context, ...initialState });
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('pagePermissions');
    history.push('/login');
  };

  const getLogoDetails = () => {
    const tenantProperties = TenantProperties[config.REACT_APP_CUSTOM_HOST];
    let details = { url: `/${tenantProperties.logo}`, alt: TenantProperties.alt };
    return details;
  };

  const reset = () => {
    setContext((state) => {
      return {
        ...state,
        isAuth: false,
        isReset: true,
      };
    });
    history.push('/login');
    return <Login />;
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="reset_password" onClick={reset}>
        Reset Password
      </Menu.Item>
      <Menu.Item key="privacy">
        <Link to="/privacy">Privacy</Link>
      </Menu.Item>
      <Menu.Item key="cookies">
        <Link to="/privacy"> Cookies </Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  const MenuList = ({ close }) => (
    <>
      <Menu
        onSelect={(e) => menuSelect(close, e)}
        defaultSelectedKeys={[currentMenuItem]}
        defaultOpenKeys={[currentMenuCategory]}
        mode="inline"
        className={!context.isCompact ? 'scrollbarSidebar' : 'scrollbarSidebarCompactView'}
      >
        {dashboardMenu.map((item) => (
          <SubMenu key={item.name} icon={item.icon} title={item.name}>
            {item.modules.map((m) => (
              <Menu.Item key={`${m.path}`}>
                <Link to={`${m.path}`}>{m.name}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
        {menu.map((i) => (
          <SubMenu key={i.category.key} icon={i.category.icon} title={i.category.name} className="leftPaneMenu">
            {i.category.modules.map((m) => (
              <Menu.ItemGroup
                key={`${i.category.key}-${m.name}`}
                title={`${m.label || m.name} ( ${m.subModules.length} )`}
              >
                {m.subModules.map((s) => (
                  <Menu.Item key={`${m.path}${s.path}`}>
                    <Link to={`${m.path}${s.path}`}>{s.name}</Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            ))}
          </SubMenu>
        ))}
        {!collapsed && (
          <Row
            className={!context.isCompact ? 'leftPaneBottom p-3' : 'leftPaneBottomCompactView p-3'}
            justify="center"
            align="middle"
          >
            <Col>
              <Col>
                {!collapsed && (
                  <Switch
                    className="mb-3"
                    onChange={handleTechModeChange}
                    style={{ width: 150 }}
                    checkedChildren="Technical Mode"
                    unCheckedChildren="Technical Mode"
                    checked={context.techMode}
                  />
                )}
              </Col>
              <Col>
                <span> Powered by </span> <img src="/logo.png" alt="spidex-logo" width={80}></img>
              </Col>
            </Col>
          </Row>
        )}
      </Menu>
    </>
  );

  const menuSelect = (close = false, e) => {
    setCurrentMenuCategory(e.keyPath[e.keyPath.length - 1]);
    setCurrentMenuItem(e.keyPath[0]);
    localStorage.setItem('openItem', e.keyPath[e.keyPath.length - 1]);
    if (close) setDrawerCollapsed((state) => !state);
  };

  const getTableBgHeaderColor = () => {
    const value = context.tenantProfile?.tableProperties?.headerBgColor || '#ffffff';
    return `headerBgColor-${value.toLowerCase()}`.replace('#', '');
  };

  const getTableTextHeaderColor = () => {
    const value = context.tenantProfile?.tableProperties?.headerTextColor || '#000000';
    return `headerTextColor-${value.toLowerCase()}`.replace('#', '');
  };

  const getSubmenuTextColor = () => {
    if (!context.tenantProfile?.buttonColor) return '';
    try {
      const color = window
        .getComputedStyle(
          document.querySelector('li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child'),
          null
        )
        .getPropertyValue('background-color');
      if (color) {
        return tinycolor(color).getBrightness() < 180 ? 'selectedMenuItem' : '';
      } else {
        return '';
      }
    } catch {
      return '';
    }
  };

  return (
    <ConfigProvider>
      <Context.Provider value={[context, setContext]}>
        <Spin size="large" spinning={context.isLoading}>
          <Layout
            style={{ height: '100vh' }}
            className={`${getTableBgHeaderColor()} ${getSubmenuTextColor()} ${getTableTextHeaderColor()} `}
          >
            {/* xs,sm,md = compact view | lg,xl,xxl = full view */}
            <Layout>
              {context.isAuth && (
                <Header
                  className={['header', context.isAuth && context.isCompact ? 'siteLayoutBackgroundMarginLeft' : '']}
                >
                  <Row align="middle" justify="space-between">
                    <Col xs={{ span: 10 }} lg={{ span: 6 }}>
                      <Row>
                        <Col>
                          {' '}
                          {context.isCompact && (
                            <>
                              <Button
                                className="compactSliderIcon"
                                type="link"
                                icon={<MenuUnfoldOutlined />}
                                onClick={() => setDrawerCollapsed((state) => !state)}
                              />
                              <Drawer
                                visible={drawerCollapsed}
                                height="100vh"
                                width="100vw"
                                bodyStyle={{ padding: '0' }}
                                title={
                                  <div className="componentMenuLogo">
                                    {context.pagePermissions.filter((x) => x.pageName === Pages.DEVICE_DASHBOARD_PAGE)
                                      .length > 0 ? (
                                      <Link to={RoutePaths.DEVICE_DASHBOARD}>
                                        <img
                                          src={getLogoDetails().url}
                                          alt={getLogoDetails().alt}
                                          className="compactViewLogo"
                                        />
                                      </Link>
                                    ) : (
                                      <img
                                        src={getLogoDetails().url}
                                        alt={getLogoDetails().alt}
                                        className="compactViewLogo"
                                      />
                                    )}
                                  </div>
                                }
                                onClose={() => setDrawerCollapsed((state) => !state)}
                              >
                                <div className="compactMenu">
                                  <MenuList close={true} />
                                </div>
                              </Drawer>
                            </>
                          )}
                        </Col>
                        <Col>
                          <div className={context.isCompact ? 'compactLogo' : 'logo'}>
                            {!context.isCompact ? (
                              <Row className={'slider'}>
                                <Col>
                                  {collapsed && (
                                    <Button
                                      icon={<MenuUnfoldOutlined />}
                                      onClick={() => setCollapsed((state) => !state)}
                                    />
                                  )}
                                  {!collapsed && (
                                    <Button
                                      icon={<MenuFoldOutlined />}
                                      onClick={() => setCollapsed((state) => !state)}
                                    />
                                  )}
                                  {context.pagePermissions.filter((x) => x.pageName === Pages.DEVICE_DASHBOARD_PAGE)
                                    .length > 0 ? (
                                    <Link to={RoutePaths.DEVICE_DASHBOARD}>
                                      <img
                                        src={getLogoDetails(TenantProperties).url}
                                        alt={getLogoDetails(TenantProperties).alt}
                                      />
                                    </Link>
                                  ) : (
                                    <img
                                      src={getLogoDetails(TenantProperties).url}
                                      alt={getLogoDetails(TenantProperties).alt}
                                    />
                                  )}
                                </Col>
                              </Row>
                            ) : (
                              <img
                                src={getLogoDetails(TenantProperties).url}
                                alt={getLogoDetails(TenantProperties).alt}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Row justify="end">
                        <Col>
                          <Notifications />
                        </Col>
                        <Col className="headerDropdown">
                          <Dropdown overlay={dropdownMenu} trigger={['click']}>
                            <Button className="avatar d-inline-block " type="link" onClick={(e) => e.preventDefault()}>
                              {!context.isCompact && context.tenantProfile?.image ? (
                                <div className="d-inline-block mx-2">
                                  <img
                                    className="compactViewLogo"
                                    src={`data:image/jpg;base64,${context.tenantProfile?.image}`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = '/assets/images/notFound.png';
                                      currentTarget.alt = 'Not Found';
                                    }}
                                    alt="Logo"
                                  />
                                </div>
                              ) : (
                                <>{context.profile?.username?.toUpperCase()}</>
                              )}
                              {!context.isCompact && context.tenantProfile?.image ? (
                                <Avatar>{context.profile?.username?.toUpperCase()?.substring(0, 1) || 'A'}</Avatar>
                              ) : (
                                <UserOutlined className="avatarIcon" />
                              )}
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Header>
              )}
              <Layout>
                {context.isAuth && !context.isCompact && (
                  <Sider width={250} collapsed={collapsed} className="mt-0">
                    <MenuList close={false} />
                  </Sider>
                )}

                <Content
                  className={[
                    context.isAuth
                      ? 'siteLayoutBackground'
                      : config.REACT_APP_CUSTOM_HOST === 'default'
                      ? 'siteLayoutBackgroundLogin'
                      : '',
                    context.isAuth ? 'siteLayoutBackground' : '',
                    context.isAuth && context.isCompact ? 'siteLayoutBackgroundMarginLeft' : '',
                  ]}
                >
                  <div className="bottomContainer">
                    <Suspense fallback={<PageLoader />}>
                      <RouterSwitch>
                        <Route exact path="/">
                          <Login />
                        </Route>
                        <Route exact path="/privacy">
                          <Privacy />
                        </Route>
                        {routes.map((r) => (
                          <Route key={r.path} path={r.path} exact={r.exact} component={r.component} />
                        ))}
                        <Route path="*">
                          <Row
                            justify="center"
                            align="middle"
                            className={context.isAuth ? 'pageNotFoundInner' : 'pageNotFound'}
                          >
                            <Col>{context.isLoading ? <PageLoader /> : <Page404 />}</Col>
                          </Row>
                        </Route>
                      </RouterSwitch>
                    </Suspense>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Spin>
      </Context.Provider>
    </ConfigProvider>
  );
};

export default App;
