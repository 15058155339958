export const RoutePaths = {
  LOGIN: '/login',
  HOME: '/dashboard/device',
  ASSET_DASHBOARD: '/dashboard/asset',
  VEHICLE_DIAGNOSTICS_DASHBOARD: '/dashboard/vehicle/diagnostics',
  DEVICE_DASHBOARD: '/dashboard/device',
  WASHROOMS_DASHBOARD: '/dashboard/washrooms',
  RESTAURANT_DASHBOARD: '/dashboard/restaurant',
  ASSET_TRACKING: '/asset-tracking',
  DIGITAL_CARPET_CONFIGURE: '/digital/carpet/configure',
  DIGITAL_CARPET_ONBOARD: '/digital/carpet/onboard',
  USER_MANAGEMENT: '/user/management',
  VENDOR_MANAGEMENT: '/register',
  REPORT: '/generate',
  VEHICLE_REPORT: '/vehicle/report',
  VEHICLE_ANALYTICS: '/vehicle/analytics',
  DEVICE_CONFIGURE: '/device/configure',
  DEVICE_MANAGEMENT: '/device/management/configure',
  DEVICE_MANAGEMENT_ONBOARD: '/device/management/onboard',
  404: '/404',
  PRIVACY: '/privacy',
  ANALYTICS_PEOPLE_TRACKING: '/people/tracking/analytics',
  ANALYTICS_ASSET_TRACKING: '/asset/tracking/analytics',
  ANALYTICS_VEHICLE_DIAGNOSTICS: '/vehicle/diagnostics/analytics',
  VEHICLE_DIAGNOSTICS_SCHEDULES: '/vehicle/diagnostics/schedule',
  VEHICLE_DIAGNOSTICS_CAMPAIGN: '/vehicle/diagnostics/campaign',
  ANALYTICS_DEVICE_MANAGEMENT: '/device/management/analytics',
  ANALYTICS_SANITARY: '/sanitary/analytics',
  APPLICATION_DASHBOARD: '/application/dashboard',
  APPLICATION_MANAGEMENT: '/application/management',
  VISUAL: '/visual',
};
export const ModuleNames = {
  PEOPLE_TRACKING: 'People Tracking',
  VEHICLE_DIAGNOSTICS: 'Vehicle Diagnostics',
  DEVICE_MANAGEMENT: 'Device Management',
  ASSET_TRACKING: 'Asset Tracking',
  SANITARY: 'Sanitary',
  RESTAURANT: 'Restaurant',
  DIGITAL_CARPET: 'Digital Carpet',
  DEVICE_CONFIGURE: 'Device Configure',
  USER_MANAGEMENT: 'User Management',
  MASTERS: 'Masters',
  REPORT: 'Reports',
  CREATE_GEOINFRA: 'Create GeoInfra',
  BRANCH: 'Branch',
  LOCATION: 'Location',
  AREA: 'Area',
  TRANSIT_AREA: 'Transit Area',
  CREATE_DEVICES: 'Create Devices',
  GATEWAY: 'Gateway',
  TAG: 'Tag',
  CREATE_THINGS: 'Create Things',
  ASSET: 'Asset',
  LINK: 'Link',
  ONBOARD: 'Onboard',
  PROVISION: 'Provision',
  CONFIGURE: 'Configure',
  EVENT_ATTRIBUTE: 'Event Attribute',
  GATEWAY_CONFIG: 'Gateway Config',
  TAG_CONFIG: 'Tag Config',
  USER: 'User',
  ACCOUNT: 'Account',
  TENANT: 'Tenant',
  RBAC: 'RBAC',
  REGISTER: 'Register',
  VENDOR: 'Vendor',
  WORKER: 'Worker',
  VEHICLE: 'Vehicle',
  ECU: 'ECU',
  VIN: 'VIN',
  VEHICLE_MODEL: 'Vehicle Model',
  ECU_MODEL: 'ECU Model',
  GENERATE: 'Generate',
  CREATE: 'Create',
  ANALYTICS: 'Analytics',
  APPLICATION_MANAGEMENT: 'App Settings',
  VISUAL: 'Visual',
  DASHBOARD: 'Dashboard',
  INDOOR_MAP_SETUP: 'Setup',
  SCHEDULES: 'Schedules',
  CAMPAIGN: 'Campaign',
};
export const Pages = {
  AREA: 'device management-configure-area',
  ASSET: 'device management-configure-asset',
  BRANCH: 'device management-configure-branch',
  GATEWAY: 'device management-configure-gateway',
  TRANSIT: 'device management-configure-transit',
  LOCATION: 'device management-configure-location',
  TAG: 'device management-configure-tag',
  TAGGED_ASSEST: 'device management-configure-taggedasset',
  ONBOARD: 'device management-onboard-onboard',
  EVENT_ATTRIBUTE: 'device configure-configure-eventattribute',
  GATEWAY_CONFIG: 'device configure-configure-gatewayconfig',
  TAG_CONFIG: 'device configure-configure-tagconfig',
  ACCOUNT: 'user management-user-account',
  TENANT: 'user management-user-tenant',
  RBAC: 'user management-user-rbac',
  VENDOR: 'master-register-vendor',
  VEHICLE: 'master-register-vehicle',
  WORKER: 'master-register-worker',
  WASHROOM: 'master-register-washroom',
  RESTAURANT: 'master-register-restaurant',
  ECU: 'master-register-ecu',
  VIN: 'master-register-vin',
  VEHICLE_MODEL: 'master-register-vehiclemodel',
  ECU_MODEL: 'master-register-ecumodel',
  PROVISION: 'device management-onboard-provision',
  CREATE_REPORT: 'master-register-createreport',
  DEVICE_DASHBOARD_PAGE: 'devicedashboardpage',
  DEVICE_MANAGEMENT_DASHBOARD: 'devicemanagement-dashboard',
  DEVICE_MANAGEMENT_ANALYTICS: 'devicemanagement-analytics',
  ASSET_DASHBOARD_PAGE: 'assetdashboardpage',
  ASSET_TRACKING_PAGE: 'assettrackingpage',
  ASSET_TRACKING_DASHBOARD: 'assettracking-dashboard',
  ASSET_TRACKING_ANALYTICS: 'assettracking-analytics',
  WASHROOMS_DASHBOARD_PAGE: 'washroomsdashboardpage',
  SANITARY_DASHBOARD: 'sanitary-dashboard',
  RESTAURANT_DASHBOARD: 'restaurant-dashboard',
  SANITARY_ANALYTICS: 'sanitary-analytics',
  PEOPLE_TRACKING_DASHBOARD: 'peopletracking-dashboard',
  PEOPLE_TRACKING_ANALYTICS: 'peopletracking-analytics',
  PEOPLE_TRACKING_REPORT: 'peopletracking-report',
  VEHICLE_DIAGNOSTICS_DASHBOARD: 'vehiclediagnostics-dashboard',
  VEHICLE_DIAGNOSTICS_ANALYTICS: 'vehiclediagnostics-analytics',
  VEHICLE_DIAGNOSTICS_REPORT: 'vehiclediagnostics-report',
  VEHICLE_DIAGNOSTICS_SCHEDULES: 'vehiclediagnostics-schedule',
  VEHICLE_DIAGNOSTICS_CAMPAIGN: 'vehiclediagnostics-campaign',
  APPLICATION_MANAGEMENT_VISUAL: 'application-management-visual',
  INDOOR_MAP_SETUP: 'indoor-map-setup',
};

export const PageRoutes = {
  'device management-configure-area': `${RoutePaths.DEVICE_MANAGEMENT}/area`,
  'device management-configure-asset': `${RoutePaths.DEVICE_MANAGEMENT}/asset`,
  'device management-configure-branch': `${RoutePaths.DEVICE_MANAGEMENT}/branch`,
  'device management-configure-gateway': `${RoutePaths.DEVICE_MANAGEMENT}/gateway`,
  'device management-configure-transit': `${RoutePaths.DEVICE_MANAGEMENT}/transit`,
  'device management-configure-location': `${RoutePaths.DEVICE_MANAGEMENT}/location`,
  'device management-configure-tag': `${RoutePaths.DEVICE_MANAGEMENT}/tag`,
  'device management-configure-taggedasset': `${RoutePaths.DEVICE_MANAGEMENT_ONBOARD}/asset/tagged`,
  'device management-onboard-onboard': `${RoutePaths.DEVICE_MANAGEMENT_ONBOARD}/onboard`,
  'device configure-configure-eventattribute': `${RoutePaths.DEVICE_CONFIGURE}/event/attribute`,
  'device configure-configure-gatewayconfig': `${RoutePaths.DEVICE_CONFIGURE}/gateway/config`,
  'device configure-configure-tagconfig': `${RoutePaths.DEVICE_CONFIGURE}/tag/config`,
  'user management-user-account': `${RoutePaths.USER_MANAGEMENT}/account`,
  'user management-user-tenant': `${RoutePaths.USER_MANAGEMENT}/tenant`,
  'user management-user-rbac': `${RoutePaths.USER_MANAGEMENT}/rbac`,
  'master-register-vendor': `${RoutePaths.VENDOR_MANAGEMENT}/vendor`,
  'master-register-vehicle': `${RoutePaths.VENDOR_MANAGEMENT}/vehicle`,
  'master-register-worker': `${RoutePaths.VENDOR_MANAGEMENT}/worker`,
  'master-register-washroom': `${RoutePaths.VENDOR_MANAGEMENT}/washroom`,
  'master-register-ecu': `${RoutePaths.VENDOR_MANAGEMENT}/ecu`,
  'master-register-vin': `${RoutePaths.VENDOR_MANAGEMENT}/vin`,
  'master-register-vehiclemodel': `${RoutePaths.VENDOR_MANAGEMENT}/vehiclemodel`,
  'master-register-ecumodel': `${RoutePaths.VENDOR_MANAGEMENT}/ecumodel`,
  'master-register-createreport': `${RoutePaths.REPORT}/createre`,
  assetdashboardpage: `${RoutePaths.ASSET_DASHBOARD}`,
  devicedashboardpage: `${RoutePaths.DEVICE_DASHBOARD}`,
  restaurantdashboardpage: `${RoutePaths.RESTAURANT_DASHBOARD}`,
  washroomsdashboardpage: `${RoutePaths.WASHROOMS_DASHBOARD}`,
  assettrackingpage: `${RoutePaths.ASSET_TRACKING}`,
  analyticspeopletrackingpage: `${RoutePaths.ANALYTICS_PEOPLE_TRACKING}`,
  vehiclediagnosticspage: `${RoutePaths.VEHICLE_DIAGNOSTICS_DASHBOARD}`,
  analyticsvehiclediagnosticspage: `${RoutePaths.ANALYTICS_VEHICLE_DIAGNOSTICS}`,
  'vehiclediagnostics-schedule': `${RoutePaths.VEHICLE_DIAGNOSTICS_SCHEDULES}`,
  'vehiclediagnostics-campaign': `${RoutePaths.VEHICLE_DIAGNOSTICS_CAMPAIGN}`,
  analyticsassettrackingpage: `${RoutePaths.ANALYTICS_ASSET_TRACKING}`,
  analyticsdevicemanagementpage: `${RoutePaths.ANALYTICS_DEVICE_MANAGEMENT}`,
  analyticssanitarypage: `${RoutePaths.ANALYTICS_SANITARY}`,
  visual: `${RoutePaths.VISUAL}`,
  applicationmanagement: `${RoutePaths.APPLICATION_MANAGEMENT}`,
};

export const CRUD = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
  VIEW: 'view',
};

export const Roles = {
  MEMBER: 'MEMBER',
  ADMIN: 'SUPER_TENANT_ADMIN',
  USER: 'USER',
};

export const RolesPages = {
  [Roles.MEMBER]: [
    { pageName: Pages.BRANCH, permissions: [CRUD.VIEW] },
    { pageName: Pages.LOCATION, permissions: [CRUD.VIEW] },
    { pageName: Pages.AREA, permissions: [CRUD.VIEW] },
    { pageName: Pages.GATEWAY, permissions: [CRUD.VIEW] },
    { pageName: Pages.TRANSIT, permissions: [CRUD.VIEW] },
    { pageName: Pages.TAG, permissions: [CRUD.VIEW] },
    { pageName: Pages.ASSET, permissions: [CRUD.VIEW] },
    { pageName: Pages.TAGGED_ASSEST, permissions: [CRUD.VIEW] },
    { pageName: Pages.PROVISION, permissions: [CRUD.VIEW] },
    { pageName: Pages.ONBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.EVENT_ATTRIBUTE, permissions: [CRUD.VIEW] },
    { pageName: Pages.TAG_CONFIG, permissions: [CRUD.VIEW] },
    { pageName: Pages.GATEWAY_CONFIG, permissions: [CRUD.VIEW] },
    { pageName: Pages.ACCOUNT, permissions: [CRUD.VIEW] },
    { pageName: Pages.TENANT, permissions: [CRUD.VIEW] },
    { pageName: Pages.RBAC, permissions: [CRUD.VIEW] },
    { pageName: Pages.VENDOR, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE, permissions: [CRUD.VIEW] },
    { pageName: Pages.WORKER, permissions: [CRUD.VIEW] },
    { pageName: Pages.WASHROOM, permissions: [CRUD.VIEW] },
    { pageName: Pages.RESTAURANT, permissions: [CRUD.VIEW] },
    { pageName: Pages.ECU, permissions: [CRUD.VIEW] },
    { pageName: Pages.VIN, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_MODEL, permissions: [CRUD.VIEW] },
    { pageName: Pages.ECU_MODEL, permissions: [CRUD.VIEW] },
    { pageName: Pages.CREATE_REPORT, permissions: [CRUD.VIEW] },
    { pageName: Pages.DEVICE_DASHBOARD_PAGE, permissions: [CRUD.VIEW] },
    { pageName: Pages.DEVICE_MANAGEMENT_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.DEVICE_MANAGEMENT_ANALYTICS, permissions: [CRUD.VIEW] },
    { pageName: Pages.ASSET_DASHBOARD_PAGE, permissions: [CRUD.VIEW] },
    { pageName: Pages.ASSET_TRACKING_PAGE, permissions: [CRUD.VIEW] },
    { pageName: Pages.ASSET_TRACKING_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.ASSET_TRACKING_ANALYTICS, permissions: [CRUD.VIEW] },
    { pageName: Pages.WASHROOMS_DASHBOARD_PAGE, permissions: [CRUD.VIEW] },
    { pageName: Pages.SANITARY_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.SANITARY_ANALYTICS, permissions: [CRUD.VIEW] },
    { pageName: Pages.RESTAURANT_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.PEOPLE_TRACKING_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.PEOPLE_TRACKING_ANALYTICS, permissions: [CRUD.VIEW] },
    { pageName: Pages.PEOPLE_TRACKING_REPORT, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_DASHBOARD, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_ANALYTICS, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_REPORT, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_SCHEDULES, permissions: [CRUD.VIEW] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN, permissions: [CRUD.VIEW] },
    { pageName: Pages.APPLICATION_MANAGEMENT_VISUAL, permissions: [CRUD.VIEW] },
    { pageName: Pages.INDOOR_MAP_SETUP, permissions: [CRUD.VIEW] },
  ],
  [Roles.ADMIN]: [
    { pageName: Pages.BRANCH, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.LOCATION, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.AREA, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.GATEWAY, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.TRANSIT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.TAG, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ASSET, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.TAGGED_ASSEST, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.PROVISION, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ONBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.EVENT_ATTRIBUTE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.TAG_CONFIG, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.GATEWAY_CONFIG, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ACCOUNT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.TENANT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.RBAC, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.RESTAURANT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.RESTAURANT_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VENDOR, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.WORKER, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.WASHROOM, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ECU, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VIN, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_MODEL, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ECU_MODEL, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.CREATE_REPORT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.DEVICE_DASHBOARD_PAGE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.DEVICE_MANAGEMENT_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.DEVICE_MANAGEMENT_ANALYTICS, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ASSET_DASHBOARD_PAGE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ASSET_TRACKING_PAGE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ASSET_TRACKING_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.ASSET_TRACKING_ANALYTICS, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.WASHROOMS_DASHBOARD_PAGE, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.SANITARY_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.SANITARY_ANALYTICS, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.PEOPLE_TRACKING_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.PEOPLE_TRACKING_ANALYTICS, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.PEOPLE_TRACKING_REPORT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_DASHBOARD, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_ANALYTICS, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_REPORT, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_SCHEDULES, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.VEHICLE_DIAGNOSTICS_CAMPAIGN, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.APPLICATION_MANAGEMENT_VISUAL, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
    { pageName: Pages.INDOOR_MAP_SETUP, permissions: [CRUD.VIEW, CRUD.DELETE, CRUD.UPDATE, CRUD.ADD] },
  ],
};

/* eslint-disable */
export const InputRegex = {
  MAC_ID: /^[a-fA-F0-9:]+$/,
  Password: /^(?=.*\d)(?=.*[!@#$%^&*_~])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  Name: /^[a-zA-Z0-9 ]*$/,
  Mobile: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
  Email:
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
};

export const SensorTypes = {
  CPU: 'Cpu',
  MEMORY: 'memoryUsage',
  DISK: 'diskUsage',
  INBOUND: 'inboundTraffic',
  OUTBOUND: 'outboundTraffic',
  BATTERY: 'battery',
  HEARTBEAT: 'heartBeat',
  IPADDRESSETH0: 'ipAddressEth0',
  IPADDRESSWLAN0: 'ipAddressWlan0',
  APPSTARTTIME: 'appStartTime',
  GATEWAYUPTIME: 'gatewayUpTime',  
  TEMPERATURE: 'temperature',
  HUMIDITY: 'humidity',
  AMBLIGHT: 'amblight',
  PROXIMITY: 'proximity',
  PRESSURE: 'pressure',
};

export const LocationTypes = {
  ENTRY: 'Entry',
  EXIT: 'Exit',
  FIXED: 'Fixed',
};

export const AssetsTypes = {
  VENDOR: 'Vendor',
  VECHICLE: 'Vechicle',
  WORKER: 'Worker',
  CHAIR: 'Chair',
  WASHROOM: 'Sanitary',
  OTHERS: 'Others',
};

export const DateFormats = {
  TYPE_1: 'yyyy-MM-DD=HH:mm',
  TYPE_6: 'HH:mm yyyy-MM-DD',
  TYPE_7: 'yyyy-MM-DD HH:mm',
  TYPE_2: 'DD-MM-YY hh:mm:ss',
  TYPE_3: 'YYYY-MM-DD',
  TYPE_4: 'DD-MM-YYYY',
  TYPE_5: 'hh:mm:ss',
  TYPE_8: 'DD/MM/YY',
  TYPE_9: 'HH:mm',
};

export const TimeZone = {
  ASIA_KOLKATA: 'Asia/Kolkata',
};

export const CommunicationTypes = {
  BLE: 'BLE',
  RFID_LINT: 'RFID Fixed Reader',
  RFID_HANDHELD: 'RFID-HandHeld',
  MODBUS: 'ModBus',
  CPDC: 'CPDC',
  OTHERS: 'Others',
};

export const state = {
  AN: 'Andaman and Nicobar Islands',
  AP: 'Andhra Pradesh',
  AR: 'Arunachal Pradesh',
  AS: 'Assam',
  BR: 'Bihar',
  CG: 'Chandigarh',
  CH: 'Chhattisgarh',
  DN: 'Dadra and Nagar Haveli',
  DD: 'Daman and Diu',
  DL: 'Delhi',
  GA: 'Goa',
  GJ: 'Gujarat',
  HR: 'Haryana',
  HP: 'Himachal Pradesh',
  JK: 'Jammu and Kashmir',
  JH: 'Jharkhand',
  KA: 'Karnataka',
  KL: 'Kerala',
  LA: 'Ladakh',
  LD: 'Lakshadweep',
  MP: 'Madhya Pradesh',
  MH: 'Maharashtra',
  MN: 'Manipur',
  ML: 'Meghalaya',
  MZ: 'Mizoram',
  NL: 'Nagaland',
  OR: 'Odisha',
  PY: 'Puducherry',
  PB: 'Punjab',
  RJ: 'Rajasthan',
  SK: 'Sikkim',
  TN: 'Tamil Nadu',
  TS: 'Telangana',
  TR: 'Tripura',
  UP: 'Uttar Pradesh',
  UK: 'Uttarakhand',
  WB: 'West Bengal',
};

export const country = {
  IND: 'India',
};

export const TenantProperties = {
  default: {
    name: 'Spidex',
    icon: 'icon.png',
    logo: 'logo.png',
    alt: 'Spidex-logo',
  },
  taggit: {
    name: 'Taggit',
    icon: 'icon-taggit.png',
    logo: 'taggit.png',
    alt: 'Taggit-logo',
  },
  yojee: {
    name: 'Yojee',
    icon: 'icon-yojee.png',
    logo: 'yojee.png',
    alt: 'Yojee',
  },
};

export const sanitaryTypes = {
  TOILET: 'toilet',
  URINAL: 'urinal',
  BATHTUB: 'bathtub',
  SHOWER: 'shower',
  HANDWASH: 'handwash',
};

export const TransitAreaModes = {
  AIR: 'Air',
  ROAD: 'Road',
  SHIP: 'Ship',
};

export const MastersPageSizeDefault = 10;
export const MastersPageSizeOptions = [10, 20, 50, 100];
export const VehicleManufacturers = ['Mahindra', 'Tata', 'Maruti'];
