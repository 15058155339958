import { ApiOutlined, AppstoreOutlined, ToolOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { lazyWithRetry } from './customReactLazy';
import { Pages, CRUD, ModuleNames } from './constants';
const Branch = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Branch'));
const Location = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Location'));
const Area = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Area'));
const Tag = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Tag'));
const Asset = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Asset'));
const TaggedAsset = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/TaggedAsset'));
const Gateway = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/Gateway'));
const GatewayTransit = lazyWithRetry(() => import('./routes/DigitalCarpet/Configure/GatewayTransit'));
const EventAttr = lazyWithRetry(() => import('./routes/DeviceConfigure/Configure/EventAttribute'));
const TagConfig = lazyWithRetry(() => import('./routes/DeviceConfigure/Configure/TagConfig'));
const GatewayConfig = lazyWithRetry(() => import('./routes/DeviceConfigure/Configure/GatewayConfig'));
const Tenant = lazyWithRetry(() => import('./routes/UserManagement/User/Tenant'));
const Account = lazyWithRetry(() => import('./routes/UserManagement/User/Account'));
const Onboard = lazyWithRetry(() => import('./routes/DigitalCarpet/Onboard/Onboard'));
const Rbac = lazyWithRetry(() => import('./routes/UserManagement/User/RBAC'));
const Vendor = lazyWithRetry(() => import('./routes/RegisterManagement/Vendor'));
const Worker = lazyWithRetry(() => import('./routes/RegisterManagement/Worker'));
const Washroom = lazyWithRetry(() => import('./routes/RegisterManagement/Sanitary'));
const Vehicle = lazyWithRetry(() => import('./routes/RegisterManagement/Vehicle'));
const Ecu = lazyWithRetry(() => import('./routes/RegisterManagement/ecu'));
const Vin = lazyWithRetry(() => import('./routes/RegisterManagement/vin'));
const VehicleModel = lazyWithRetry(() => import('./routes/RegisterManagement/VehicleModel'));
const EcuModel = lazyWithRetry(() => import('./routes/RegisterManagement/EcuModel'));
const MapIndoorSetup = lazyWithRetry(() => import('./routes/DigitalCarpet/MapIndoor/Setup'));

const modules = [
  {
    category: {
      name: 'DigitalCarpet ™',
      icon: <ApiOutlined />,
      key: 'DM',
      modules: [
        {
          name: 'Map Indoor',
          label: 'Map Indoor',
          path: '/map/indoor',
          subModules: [
            {
              name: ModuleNames.INDOOR_MAP_SETUP,
              path: '/setup',
              component: MapIndoorSetup,
              pageName: Pages.INDOOR_MAP_SETUP,
              permission: CRUD.VIEW,
            },
          ],
        },
        {
          name: ModuleNames.CONFIGURE,
          label: ModuleNames.CREATE_GEOINFRA,
          path: '/device/management/configure',
          subModules: [
            {
              name: ModuleNames.BRANCH,
              path: '/branch',
              component: Branch,
              pageName: Pages.BRANCH,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.LOCATION,
              path: '/location',
              params: ['/:id?'],
              component: Location,
              pageName: Pages.LOCATION,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.AREA,
              path: '/area',
              params: ['/:id?'],
              component: Area,
              pageName: Pages.AREA,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.TRANSIT_AREA,
              path: '/transit',
              component: GatewayTransit,
              pageName: Pages.TRANSIT,
              permission: CRUD.VIEW,
            },
          ],
        },
        {
          name: 'Devices',
          label: ModuleNames.CREATE_DEVICES,
          path: '/device/management/configure',
          subModules: [
            {
              name: ModuleNames.GATEWAY,
              path: '/gateway',
              params: ['/:id?'],
              component: Gateway,
              pageName: Pages.GATEWAY,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.TAG,
              path: '/tag',
              component: Tag,
              pageName: Pages.TAG,
              permission: CRUD.VIEW,
            },
          ],
        },
        {
          name: 'Assets',
          label: ModuleNames.CREATE_THINGS,
          path: '/device/management/configure',
          subModules: [
            {
              name: ModuleNames.ASSET,
              path: '/asset',
              component: Asset,
              pageName: Pages.ASSET,
              permission: CRUD.VIEW,
            },
          ],
        },
        {
          name: ModuleNames.LINK,
          path: '/device/management/onboard',
          subModules: [
            {
              name: ModuleNames.ONBOARD,
              path: '/asset/tagged',
              component: TaggedAsset,
              pageName: Pages.PROVISION,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.PROVISION,
              path: '/onboard',
              component: Onboard,
              pageName: Pages.ONBOARD,
              permission: CRUD.VIEW,
            },
          ],
        },
      ],
    },
  },
  {
    category: {
      name: ModuleNames.DEVICE_CONFIGURE,
      icon: <ToolOutlined />,
      key: 'DC',
      modules: [
        {
          name: ModuleNames.CONFIGURE,
          path: '/device/configure',
          subModules: [
            {
              name: ModuleNames.EVENT_ATTRIBUTE,
              path: '/event/attribute',
              component: EventAttr,
              pageName: Pages.EVENT_ATTRIBUTE,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.GATEWAY_CONFIG,
              path: '/gateway/config',
              component: GatewayConfig,
              pageName: Pages.GATEWAY_CONFIG,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.TAG_CONFIG,
              path: '/tag/config',
              component: TagConfig,
              pageName: Pages.TAG_CONFIG,
              permission: CRUD.VIEW,
            },
          ],
        },
      ],
    },
  },
  {
    category: {
      name: ModuleNames.USER_MANAGEMENT,
      icon: <UsergroupAddOutlined />,
      key: 'UM',
      modules: [
        {
          name: ModuleNames.USER,
          path: '/user/management',
          subModules: [
            {
              name: ModuleNames.ACCOUNT,
              path: '/account',
              component: Account,
              pageName: Pages.ACCOUNT,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.TENANT,
              path: '/tenant',
              component: Tenant,
              pageName: Pages.TENANT,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.RBAC,
              path: '/rbac',
              component: Rbac,
              pageName: Pages.RBAC,
              permission: CRUD.VIEW,
            },
          ],
        },
      ],
    },
  },
  {
    category: {
      name: ModuleNames.MASTERS,
      icon: <AppstoreOutlined />,
      key: 'VM',
      modules: [
        {
          name: ModuleNames.CREATE,
          path: '/register',
          subModules: [
            {
              name: ModuleNames.VENDOR,
              path: '/vendor',
              component: Vendor,
              pageName: Pages.VENDOR,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.WORKER,
              path: '/worker',
              component: Worker,
              pageName: Pages.WORKER,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.VEHICLE,
              path: '/vehicle',
              component: Vehicle,
              pageName: Pages.VEHICLE,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.SANITARY,
              path: '/washroom',
              component: Washroom,
              pageName: Pages.WASHROOM,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.ECU,
              path: '/ecu',
              component: Ecu,
              pageName: Pages.ECU,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.VIN,
              path: '/vin',
              component: Vin,
              pageName: Pages.VIN,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.VEHICLE_MODEL,
              path: '/vehiclemodel',
              component: VehicleModel,
              pageName: Pages.VEHICLE_MODEL,
              permission: CRUD.VIEW,
            },
            {
              name: ModuleNames.ECU_MODEL,
              path: '/ecumodel',
              component: EcuModel,
              pageName: Pages.ECU_MODEL,
              permission: CRUD.VIEW,
            },
          ],
        },
      ],
    },
  },
];

export const getMenu = (pagePermissions) => {
  return buildForRole(pagePermissions);
};

const buildForRole = (pagePermissions) => {
  const pagePermissionsObject = {};
  pagePermissions.forEach((pagePermission) => {
    pagePermissionsObject[pagePermission.pageName] = pagePermission.permissions;
  });
  let modulesAllowed = [...cloneDeep(modules)];
  modulesAllowed.forEach((module) => {
    module.category.modules.forEach((subModule) => {
      let allowedSubModules = [];
      subModule.subModules.forEach((subModuleItem) => {
        if (subModuleItem.pageName in pagePermissionsObject) {
          if (pagePermissionsObject[subModuleItem.pageName].includes(subModuleItem.permission)) {
            allowedSubModules.push(subModuleItem);
          }
        }
      });
      subModule.subModules = [...allowedSubModules];
    });
  });
  const filteredModules = [
    ...modulesAllowed.filter((x) => x.category.modules.filter((y) => y.subModules.length > 0).length > 0),
  ];
  filteredModules.forEach((module) => {
    module.category.modules = module.category.modules.filter((x) => x.subModules.length > 0);
  });
  return filteredModules;
};
