import React, { useContext, useEffect, useRef, useState } from 'react';
import { BreadcrumbList } from '../../../shared';
import Context from '../../../context';
import * as config from '../../../config';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Select, Form, Input, Popover, Button, DatePicker, Col, message, Row, TimePicker } from '../../../components';
import {
  getAllAreas,
  getAllAssets,
  getAllGateways,
  getAllTaggedAssets,
  washroomGetOne,
  washroomPost,
} from '../../../services';
import moment from 'moment';
import { buildCommonApiValues } from '../../../utils';
import { DateFormats, ModuleNames, Pages } from '../../../constants';
import { get } from 'lodash';
import s from './index.module.less';

const { Option } = Select;

const Washroom = () => {
  const [context, setContext] = useContext(Context);
  const [gateways, setGateways] = useState([]);
  const [assets, setAssets] = useState([]);
  const [tgAssets, setTgAssets] = useState([]);
  const [washrooms, setWashrooms] = useState([]);

  const stompClient = useRef(null);
  const subs = useRef([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const tempSub = subs.current;
    return () => {
      tempSub.forEach((sub) => {
        try {
          sub?.unsubscribe();
        } catch (e) {
          console.log(e);
        }
      });
      stompClient?.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      setContext((state) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      try {
        const resGateways = await getAllGateways(context.profile.tenantId);
        const resAreas = await getAllAreas(context.profile.tenantId);
        const resTgAsset = await getAllTaggedAssets(context.profile.tenantId);
        const resAsset = await getAllAssets(context.profile.tenantId);
        const gatewayActive = resGateways.data.filter((x) => x.deleted === false);
        setGateways(gatewayActive);
        setTgAssets(resTgAsset.data);
        setAssets(resAsset.data.filter((x) => x.deleted === false));
        gatewayActive.forEach((gateway) => {
          gateway.area = resAreas.data.find((x) => x.id === gateway.areaId)?.name || 'NA';
        });
        let socket = new SockJS(`${config.REACT_APP_SPIDEX_SOCKET_URI}/spdxsocket`);
        stompClient.current = Stomp.over(socket);
        stompClient.current.connect(
          (err) => {
            console.log(err);
            message.error('Unable to get the data, try again later');
          },
          () => {
            console.log('socket is Connected');
          }
        );
      } catch (e) {
        console.log(e);
        message.error('Unable to get Gateway details, try again later');
      } finally {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  const onSubmitForm = async () => {
    const values = await form.validateFields();
    const cAt = values.cleanedAtTime;
    values.lastCleaned.set({
      hour: cAt.hour(),
      minute: cAt.minute(),
      second: cAt.second(),
      millisecond: cAt.millisecond(),
    });
    values.locName = gateways.find((x) => x.locId === values.locId).locName;
    const commonValues = buildCommonApiValues(context.profile);
    try {
      await washroomPost({ ...values, ...commonValues });
      message.success('Succesfully Updated washroom data');
      form.resetFields();
      form.setFieldsValue({ locId: values.locId });
    } catch (e) {
      console.log('error', e);
      message.error('Unable to add the values of washroom!');
    }
  };

  const deviceAllList = useRef({});
  const onChangeArea = (id) => {
    setWashrooms([]);
    onClickOnSvg(id);
    const subscribeFn = (id) => {
      const sub = stompClient.current.subscribe(`/data/proximity/${id}`, (message) => {
        const jsonData = JSON.parse(message.body);
        // eslint-disable-next-line no-prototype-builtins
        if (!deviceAllList.current.hasOwnProperty(jsonData.deviceLogId)) {
          deviceAllList.current[jsonData.deviceLogId] = false;
        }
        updateDeviceSubscription();
      });
      subs.current.push(sub);
    };
    if (stompClient?.current?.connected && id) {
      subscribeFn(id);
    }
  };

  const [washroomClean, setWashroomClean] = useState({});
  const onClickOnSvg = async (id) => {
    try {
      const washroom = await washroomGetOne(id);
      setWashroomClean(washroom.data);
    } catch (e) {
      console.log('error', e);
      message.error('Unable to get the washroom data!');
    }
  };

  const [latestWashroomData, setLatestWashroomData] = useState({});
  const updateDeviceSubscription = () => {
    if (!stompClient.current) return;
    Object.keys(deviceAllList.current).forEach((d) => {
      if (!deviceAllList.current[d]) {
        const subscribeFn = () => {
          const sub = stompClient.current.subscribe(`/data/memsDetect/${d}`, (message) => {
            const jsonData = JSON.parse(message.body);
            setLatestWashroomData(jsonData);
          });
          subs.current.push(sub);
        };
        deviceAllList.current[d] = true;
        subscribeFn();
      }
    });
  };

  useEffect(() => {
    setWashrooms((state) => {
      const tgAssetLink = tgAssets.find((x) => x.id === latestWashroomData.deviceLogId);
      const externalRes = assets.find((x) => x.externalId === tgAssetLink?.taggedAssetInfo?.assetExternalId);
      if (!tgAssetLink) return state;
      const newAsset = { ...latestWashroomData };
      newAsset.gender = externalRes?.properties?.gender;
      newAsset.sanitaryCategory = externalRes?.properties?.category;
      newAsset.macId = tgAssetLink?.taggedAssetInfo?.assetExternalId;
      newAsset.deviceId = tgAssetLink?.taggedAssetInfo?.tagExternalId;
      let temp = [...state];
      temp = [...temp.filter((x) => x.macId !== newAsset.macId), newAsset];
      temp.sort((a, b) => {
        if (a.macId < b.macId) return -1;
        if (a.macId > b.macId) return 1;
        return 0;
      });
      return temp;
    });
    // eslint-disable-next-line
  }, [latestWashroomData]);

  const sanitaryBreadcrumbsName = get(
    context.tenantProfile,
    `dashboardNames[${Pages.SANITARY_DASHBOARD}].displayName`,
    ModuleNames.SANITARY
  );

  return (
    <>
      <Row>
        <Col>
          <BreadcrumbList list={[sanitaryBreadcrumbsName]} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form form={form} className={s.form} layout="inline" onFinish={onSubmitForm}>
            <Form.Item
              name="locId"
              rules={[
                {
                  required: true,
                  message: 'Please Select Area!',
                },
              ]}
            >
              <Select placeholder="Select Area" className={s.selectArea} onChange={onChangeArea}>
                {gateways.map((gateway, index) => {
                  return (
                    <Option key={index} value={gateway.locId}>
                      {gateway.areaName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="inCharge"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: 'Please input Incharge Name !',
                },
              ]}
            >
              <Input placeholder="HK Incharge" />
            </Form.Item>
            <Form.Item
              name="lastCleaned"
              rules={[
                {
                  required: true,
                  message: 'Please select Cleaned Date!',
                },
              ]}
            >
              <DatePicker placeholder="Cleaned At" />
            </Form.Item>
            <Form.Item
              name="cleanedAtTime"
              rules={[
                {
                  required: true,
                  message: 'Please select Cleaned Time!',
                },
              ]}
            >
              <TimePicker placeholder="Select Time" defaultOpenValue={moment('00:00:00', DateFormats.TYPE_5)} />
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  className="formButton"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row className={s.cardContainer}>
        {washrooms.map((washroom) => (
          <Col key={washroom.macId} className={`${s.sanitaryCard} ${washroom.macId}`}>
            <Row align="middle" justify="center" className={s.cardTitle}>
              <Col>
                <Popover
                  content={washroom.attributeValue === (1 || 1.0) ? `Unavailable` : `Available`}
                  overlayStyle={{
                    width: 80,
                    minWidth: 100,
                  }}
                  trigger="click"
                >
                  <strong>{washroom.macId}</strong>
                </Popover>
              </Col>
            </Row>
            <Row justify="space-between" className={s.cardImage}>
              <Col>
                {washroom.gender === 'female' ? (
                  <Popover
                    content="Female"
                    overlayStyle={{
                      width: 80,
                      minWidth: 100,
                    }}
                    trigger="click"
                  >
                    <img alt="female" src="/assets/icons/female.svg" className={s.svg30} />
                  </Popover>
                ) : (
                  <Popover
                    content="Male"
                    overlayStyle={{
                      width: 80,
                      minWidth: 100,
                    }}
                    trigger="click"
                  >
                    <img alt="male" src="/assets/icons/male.svg" className={s.svg30} />
                  </Popover>
                )}
              </Col>
              <Col>
                <Popover
                  content="Toilet"
                  overlayStyle={{
                    width: 50,
                    minWidth: 100,
                  }}
                  trigger="click"
                >
                  {washroom.sanitaryCategory === 'urinal' ? (
                    <img
                      alt="urinal"
                      src="/assets/icons/urinal.svg"
                      className={
                        washroom.attributeValue === (1 || 1.0) ? `${s.redSign} ${s.svg35}` : `${s.greenSign} ${s.svg35}`
                      }
                    />
                  ) : washroom.sanitaryCategory === 'bathtub' ? (
                    <img
                      alt="bathtub"
                      src="/assets/icons/bathtub.svg"
                      className={
                        washroom.attributeValue === (1 || 1.0) ? `${s.redSign} ${s.svg65}` : `${s.greenSign} ${s.svg65}`
                      }
                    />
                  ) : washroom.sanitaryCategory === 'handwash' ? (
                    <img
                      alt="handwash"
                      src="/assets/icons/handwash.svg"
                      className={
                        washroom.attributeValue === (1 || 1.0) ? `${s.redSign} ${s.svg65}` : `${s.greenSign} ${s.svg65}`
                      }
                    />
                  ) : washroom.sanitaryCategory === 'shower' ? (
                    <img
                      alt="shower"
                      src="/assets/icons/shower.svg"
                      className={
                        washroom.attributeValue === (1 || 1.0) ? `${s.redSign} ${s.svg65}` : `${s.greenSign} ${s.svg65}`
                      }
                    />
                  ) : (
                    <img
                      alt="sanitary"
                      src="/assets/icons/toilet.svg"
                      className={
                        washroom.attributeValue === (1 || 1.0) ? `${s.redSign} ${s.svg45}` : `${s.greenSign} ${s.svg45}`
                      }
                    />
                  )}
                </Popover>
              </Col>
            </Row>
            <Row justify="space-between" className={s.cardStatus}>
              <Col>
                <strong>Status</strong>
              </Col>
              <Col>
                <img alt="housekeeping" src="/assets/icons/greenSign.svg" className={s.greenSignSvg} />
              </Col>
            </Row>
            <Row justify="space-around" align="middle" className={s.cardInfo}>
              <Col>
                <Popover
                  content={
                    <Row>
                      <Col>Washroom Cleaning</Col>
                      <Col>Last Clean : {moment(washroomClean.lastCleaned).format(DateFormats.TYPE_2)}</Col>
                      <Col>Clean Due : {moment(washroomClean.dueForClean).format(DateFormats.TYPE_2)}</Col>
                    </Row>
                  }
                  overlayStyle={{
                    width: 250,
                    minWidth: 100,
                  }}
                  trigger="click"
                >
                  <img
                    alt="housekeeping"
                    src="/assets/icons/housekeeping.svg"
                    className={`${s.greenSign} ${s.svg30}`}
                  />
                </Popover>
              </Col>
              <Col>
                <Popover
                  content={
                    <Row>
                      <Col>Floor Cleaning</Col>
                      <Col>Last Clean : {moment(washroomClean.lastCleaned).format(DateFormats.TYPE_2)}</Col>
                      <Col>Clean Due : {moment(washroomClean.dueForClean).format(DateFormats.TYPE_2)}</Col>
                    </Row>
                  }
                  overlayStyle={{
                    width: 250,
                    minWidth: 100,
                  }}
                  trigger="click"
                >
                  <img alt="floor" src="/assets/icons/floor.svg" className={`${s.greenSign} ${s.svg30}`} />
                </Popover>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Washroom;
