import axios from '../axios';

const TENENT_PROFILE_URI = '/tenantProfile/';

export const addTenantProfile = (formData) => {
  return axios.post(`${TENENT_PROFILE_URI}`, formData);
};

export const getAllTenantProfile = () => {
  return axios.get(`${TENENT_PROFILE_URI}all`);
};

export const getTenantProfile = (id) => {
  return axios.get(`${TENENT_PROFILE_URI}${id}`);
};

export const deleteTenantProfile = (id) => {
  return axios.delete(`${TENENT_PROFILE_URI}${id}`);
};
