import React, { useContext } from 'react';
import Context from '../../context';
import { Menu } from '../../../src/components';

const PermissionMenu = (props) => {
  const { pagePermission, onMenuClick } = props;
  const [context, ,] = useContext(Context);
  const permissions = context.pagePermissions;

  const permit =
    permissions.filter(
      (p) =>
        pagePermission.filter((page) => p.pageName === page.pageName) &&
        p.permissions.filter((crud) => pagePermission.filter((permission) => crud === permission.permission))
    ).length > 0;

  const pagePermissionChecked = [];

  pagePermission.forEach((permission) => {
    if (permit) {
      pagePermissionChecked.push(permission);
    }
  });

  return (
    <Menu onClick={onMenuClick}>
      {pagePermissionChecked.map((permission) => (
        <Menu.Item key={permission.permission}>{permission.label}</Menu.Item>
      ))}
    </Menu>
  );
};

export default PermissionMenu;
