import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { addTenantProfile, getTenantProfile } from '../../../services';
import { ModuleNames, Pages } from '../../../constants';
import { BreadcrumbList } from '../../../shared';
import { Upload, Form, Input, Button, Row, Col, Select, Card, message, Checkbox, Tooltip } from '../../../components';
import Context from '../../../context';
import { cleanJSON, normFile } from '../../../utils';
import * as config from '../../../config';
import { CompactPicker } from 'react-color';
import s from './index.module.less';

const { Option } = Select;

const ApplicationDashboardVisual = () => {
  const [context, setContext] = useContext(Context);
  const [colorsSelected, setColorsSelected] = useState({ headerBgColor: '#4D4D4D', headerTextColor: '' });
  const [form] = Form.useForm();
  // button color is being currently used as primary theme color and not only for buttons
  const formInitialValues = {
    buttonColor: config.REACT_APP_THEME_PRIMARY_COLOR,
    tableProperties: { headerAlign: 'Left', headerTextColor: '#000000', headerBgColor: '#fafafa' },
    fontFamily: 'Apple System',
    breadcrumbsVisible: true,
  };

  const dummyCustomRequest = (e) => {
    e.onSuccess(null, e.file);
  };
  const onFinish = (values) => {
    finishAdd(values);
  };
  const finishAdd = async (values) => {
    await saveTenantProfile(values);
  };

  const saveTenantProfile = async (formData) => {
    setContext((state) => {
      return {
        ...state,
        isLoading: true,
      };
    });

    formData.tenantId = context.profile.tenantId;
    formData.tableProperties.headerBgColor = colorsSelected.headerBgColor;
    formData.tableProperties.headerTextColor = colorsSelected.headerTextColor;
    addTenantProfile(cleanJSON(formData))
      .then((res) => {
        form.setFieldsValue(res.data);
        message.success('Successfully saved the tenant profile');
      })
      .catch((e) => {
        console.log(e);
        message.error('Unable to save , try again later');
      })
      .finally(() => {
        setContext((state) => {
          return {
            ...state,
            isLoading: false,
          };
        });
      });
  };
  useEffect(() => {
    if (context.profile.tenantId) {
      setContext((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
      getTenantProfile(context.profile.tenantId)
        .then((res) => {
          let data = res.data;
          let dNames = data.dashboardNames;
          let mNames = data.moduleNames;
          if (!dNames[Pages.PEOPLE_TRACKING_DASHBOARD]?.techName)
            dNames[Pages.PEOPLE_TRACKING_DASHBOARD] = {
              ...dNames[Pages.PEOPLE_TRACKING_DASHBOARD],
              techName: ModuleNames.PEOPLE_TRACKING,
            };
          if (!dNames[Pages.PEOPLE_TRACKING_DASHBOARD]?.displayName)
            dNames[Pages.PEOPLE_TRACKING_DASHBOARD] = {
              ...dNames[Pages.PEOPLE_TRACKING_DASHBOARD],
              displayName: ModuleNames.PEOPLE_TRACKING,
            };
          if (!dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD]?.techName)
            dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD] = {
              ...dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD],
              techName: ModuleNames.VEHICLE_DIAGNOSTICS,
            };
          if (!dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD]?.displayName)
            dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD] = {
              ...dNames[Pages.VEHICLE_DIAGNOSTICS_DASHBOARD],
              displayName: ModuleNames.VEHICLE_DIAGNOSTICS,
            };
          if (!dNames[Pages.ASSET_TRACKING_DASHBOARD]?.techName)
            dNames[Pages.ASSET_TRACKING_DASHBOARD] = {
              ...dNames[Pages.ASSET_TRACKING_DASHBOARD],
              techName: ModuleNames.ASSET_TRACKING,
            };
          if (!dNames[Pages.ASSET_TRACKING_DASHBOARD]?.displayName)
            dNames[Pages.ASSET_TRACKING_DASHBOARD] = {
              ...dNames[Pages.ASSET_TRACKING_DASHBOARD],
              displayName: ModuleNames.ASSET_TRACKING,
            };
          if (!dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD]?.techName)
            dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD] = {
              ...dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD],
              techName: ModuleNames.DEVICE_MANAGEMENT,
            };
          if (!dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD]?.displayName)
            dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD] = {
              ...dNames[Pages.DEVICE_MANAGEMENT_DASHBOARD],
              displayName: ModuleNames.DEVICE_MANAGEMENT,
            };
          if (!dNames[Pages.SANITARY_DASHBOARD]?.techName)
            dNames[Pages.SANITARY_DASHBOARD] = {
              ...dNames[Pages.SANITARY_DASHBOARD],
              techName: ModuleNames.SANITARY,
            };
          if (!dNames[Pages.SANITARY_DASHBOARD]?.displayName)
            dNames[Pages.SANITARY_DASHBOARD] = {
              ...dNames[Pages.SANITARY_DASHBOARD],
              displayName: ModuleNames.SANITARY,
            };

          if (!mNames[Pages.AREA]?.techName)
            mNames[Pages.AREA] = {
              ...mNames[Pages.AREA],
              techName: ModuleNames.AREA,
            };
          if (!mNames[Pages.AREA]?.displayName)
            mNames[Pages.AREA] = {
              ...mNames[Pages.AREA],
              displayName: ModuleNames.AREA,
            };
          if (!mNames[Pages.BRANCH]?.techName)
            mNames[Pages.BRANCH] = {
              ...mNames[Pages.BRANCH],
              techName: ModuleNames.BRANCH,
            };
          if (!mNames[Pages.BRANCH]?.displayName)
            mNames[Pages.BRANCH] = {
              ...mNames[Pages.BRANCH],
              displayName: ModuleNames.BRANCH,
            };
          if (!mNames[Pages.LOCATION]?.techName)
            mNames[Pages.LOCATION] = {
              ...mNames[Pages.LOCATION],
              techName: ModuleNames.LOCATION,
            };
          if (!mNames[Pages.LOCATION]?.displayName)
            mNames[Pages.LOCATION] = {
              ...mNames[Pages.LOCATION],
              displayName: ModuleNames.LOCATION,
            };
          if (!mNames[Pages.TRANSIT]?.techName)
            mNames[Pages.TRANSIT] = {
              ...mNames[Pages.TRANSIT],
              techName: ModuleNames.TRANSIT_AREA,
            };
          if (!mNames[Pages.TRANSIT]?.displayName)
            mNames[Pages.TRANSIT] = {
              ...mNames[Pages.TRANSIT],
              displayName: ModuleNames.TRANSIT_AREA,
            };
          if (!mNames[Pages.GATEWAY]?.techName)
            mNames[Pages.GATEWAY] = {
              ...mNames[Pages.GATEWAY],
              techName: ModuleNames.GATEWAY,
            };
          if (!mNames[Pages.GATEWAY]?.displayName)
            mNames[Pages.GATEWAY] = {
              ...mNames[Pages.GATEWAY],
              displayName: ModuleNames.GATEWAY,
            };
          if (!mNames[Pages.TAG]?.techName)
            mNames[Pages.TAG] = {
              ...mNames[Pages.TAG],
              techName: ModuleNames.TAG,
            };
          if (!mNames[Pages.TAG]?.displayName)
            mNames[Pages.TAG] = {
              ...mNames[Pages.TAG],
              displayName: ModuleNames.TAG,
            };

          if (!mNames[Pages.ASSET]?.techName)
            mNames[Pages.ASSET] = {
              ...mNames[Pages.ASSET],
              techName: ModuleNames.ASSET,
            };
          if (!mNames[Pages.ASSET]?.displayName)
            mNames[Pages.ASSET] = {
              ...mNames[Pages.ASSET],
              displayName: ModuleNames.ASSET,
            };
          if (!mNames[Pages.ONBOARD]?.techName)
            mNames[Pages.ONBOARD] = {
              ...mNames[Pages.ONBOARD],
              techName: ModuleNames.ONBOARD,
            };
          if (!mNames[Pages.ONBOARD]?.displayName)
            mNames[Pages.ONBOARD] = {
              ...mNames[Pages.ONBOARD],
              displayName: ModuleNames.ONBOARD,
            };
          if (!mNames[Pages.PROVISION]?.techName)
            mNames[Pages.PROVISION] = {
              ...mNames[Pages.PROVISION],
              techName: ModuleNames.PROVISION,
            };
          if (!mNames[Pages.PROVISION]?.displayName)
            mNames[Pages.PROVISION] = {
              ...mNames[Pages.PROVISION],
              displayName: ModuleNames.PROVISION,
            };
          if (!mNames[Pages.EVENT_ATTRIBUTE]?.techName)
            mNames[Pages.EVENT_ATTRIBUTE] = {
              ...mNames[Pages.EVENT_ATTRIBUTE],
              techName: ModuleNames.EVENT_ATTRIBUTE,
            };
          if (!mNames[Pages.EVENT_ATTRIBUTE]?.displayName)
            mNames[Pages.EVENT_ATTRIBUTE] = {
              ...mNames[Pages.EVENT_ATTRIBUTE],
              displayName: ModuleNames.EVENT_ATTRIBUTE,
            };
          if (!mNames[Pages.GATEWAY_CONFIG]?.techName)
            mNames[Pages.GATEWAY_CONFIG] = {
              ...mNames[Pages.GATEWAY_CONFIG],
              techName: ModuleNames.GATEWAY_CONFIG,
            };
          if (!mNames[Pages.GATEWAY_CONFIG]?.displayName)
            mNames[Pages.GATEWAY_CONFIG] = {
              ...mNames[Pages.GATEWAY_CONFIG],
              displayName: ModuleNames.GATEWAY_CONFIG,
            };
          if (!mNames[Pages.TAG_CONFIG]?.techName)
            mNames[Pages.TAG_CONFIG] = {
              ...mNames[Pages.TAG_CONFIG],
              techName: ModuleNames.TAG_CONFIG,
            };
          if (!mNames[Pages.TAG_CONFIG]?.displayName)
            mNames[Pages.TAG_CONFIG] = {
              ...mNames[Pages.TAG_CONFIG],
              displayName: ModuleNames.TAG_CONFIG,
            };
          if (!mNames[Pages.ACCOUNT]?.techName)
            mNames[Pages.ACCOUNT] = {
              ...mNames[Pages.ACCOUNT],
              techName: ModuleNames.ACCOUNT,
            };
          if (!mNames[Pages.ACCOUNT]?.displayName)
            mNames[Pages.ACCOUNT] = {
              ...mNames[Pages.ACCOUNT],
              displayName: ModuleNames.ACCOUNT,
            };
          if (!mNames[Pages.TENANT]?.techName)
            mNames[Pages.TENANT] = {
              ...mNames[Pages.TENANT],
              techName: ModuleNames.TENANT,
            };
          if (!mNames[Pages.TENANT]?.displayName)
            mNames[Pages.TENANT] = {
              ...mNames[Pages.TENANT],
              displayName: ModuleNames.TENANT,
            };
          if (!mNames[Pages.RBAC]?.techName)
            mNames[Pages.RBAC] = {
              ...mNames[Pages.RBAC],
              techName: ModuleNames.RBAC,
            };
          if (!mNames[Pages.RBAC]?.displayName)
            mNames[Pages.RBAC] = {
              ...mNames[Pages.RBAC],
              displayName: ModuleNames.RBAC,
            };
          if (!mNames[Pages.VENDOR]?.techName)
            mNames[Pages.VENDOR] = {
              ...mNames[Pages.VENDOR],
              techName: ModuleNames.VENDOR,
            };
          if (!mNames[Pages.VENDOR]?.displayName)
            mNames[Pages.VENDOR] = {
              ...mNames[Pages.VENDOR],
              displayName: ModuleNames.VENDOR,
            };
          if (!mNames[Pages.VEHICLE]?.techName)
            mNames[Pages.VEHICLE] = {
              ...mNames[Pages.VEHICLE],
              techName: ModuleNames.VEHICLE,
            };
          if (!mNames[Pages.VEHICLE]?.displayName)
            mNames[Pages.VEHICLE] = {
              ...mNames[Pages.VEHICLE],
              displayName: ModuleNames.VEHICLE,
            };
          if (!mNames[Pages.WORKER]?.techName)
            mNames[Pages.WORKER] = {
              ...mNames[Pages.WORKER],
              techName: ModuleNames.WORKER,
            };
          if (!mNames[Pages.WORKER]?.displayName)
            mNames[Pages.WORKER] = {
              ...mNames[Pages.WORKER],
              displayName: ModuleNames.WORKER,
            };
          if (!mNames[Pages.WASHROOM]?.techName)
            mNames[Pages.WASHROOM] = {
              ...mNames[Pages.WASHROOM],
              techName: ModuleNames.SANITARY,
            };
          if (!mNames[Pages.WASHROOM]?.displayName)
            mNames[Pages.WASHROOM] = {
              ...mNames[Pages.WASHROOM],
              displayName: ModuleNames.SANITARY,
            };
          if (!mNames[Pages.ECU]?.techName)
            mNames[Pages.ECU] = {
              ...mNames[Pages.ECU],
              techName: ModuleNames.ECU,
            };
          if (!mNames[Pages.ECU]?.displayName)
            mNames[Pages.ECU] = {
              ...mNames[Pages.ECU],
              displayName: ModuleNames.ECU,
            };
          if (!mNames[Pages.VIN]?.techName)
            mNames[Pages.VIN] = {
              ...mNames[Pages.VIN],
              techName: ModuleNames.VIN,
            };
          if (!mNames[Pages.VIN]?.displayName)
            mNames[Pages.VIN] = {
              ...mNames[Pages.VIN],
              displayName: ModuleNames.VIN,
            };
          if (!mNames[Pages.VEHICLE_MODEL]?.techName)
            mNames[Pages.VEHICLE_MODEL] = {
              ...mNames[Pages.VEHICLE_MODEL],
              techName: ModuleNames.VEHICLE_MODEL,
            };
          if (!mNames[Pages.VEHICLE_MODEL]?.displayName)
            mNames[Pages.VEHICLE_MODEL] = {
              ...mNames[Pages.VEHICLE_MODEL],
              displayName: ModuleNames.VEHICLE_MODEL,
            };
          if (!mNames[Pages.ECU_MODEL]?.techName)
            mNames[Pages.ECU_MODEL] = {
              ...mNames[Pages.ECU_MODEL],
              techName: ModuleNames.ECU_MODEL,
            };
          if (!mNames[Pages.ECU_MODEL]?.displayName)
            mNames[Pages.ECU_MODEL] = {
              ...mNames[Pages.ECU_MODEL],
              displayName: ModuleNames.ECU_MODEL,
            };

          if (!data.fontFamily) data.fontFamily = 'Apple System';
          if (!data?.tableProperties?.headerAlign) data.tableProperties.headerAlign = 'Left';
          if (!data.reportHeadingText) data.reportHeadingText = ModuleNames.REPORT;
          if (!data.reportSubHeadingText) data.reportSubHeadingText = '';
          if (!data.buttonColor) data.buttonColor = config.REACT_APP_THEME_PRIMARY_COLOR;
          form.setFieldsValue(data);
          setColorsSelected({
            headerBgColor: data.tableProperties.headerBgColor,
            headerTextColor: data.tableProperties.headerTextColor,
          });
        })
        .catch((e) => {
          console.log(e);
          message.error('Unable to get tenant profile details, try again later');
        })
        .finally(() => {
          setContext((state) => {
            return {
              ...state,
              isLoading: false,
            };
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  const props = {
    multiple: false,
    showUploadList: true,
    previewFile: false,
    name: 'image',
    progress: { strokeWidth: 2, showInfo: false },
    accept: 'image/jpg,image/jpeg',
  };
  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12,
    },
  };
  return (
    <>
      <Row justify="start">
        <Col>
          <BreadcrumbList list={['Home', ModuleNames.APPLICATION_MANAGEMENT]} />
        </Col>
      </Row>
      <Form form={form} initialValues={{ ...formInitialValues }} onFinish={onFinish} {...layout}>
        <Row gutter={[8, 16]}>
          <Col xs={24} lg={12}>
            <Card title="Visual" className="mb-2">
              <Row justify="center">
                <Col>
                  <Form.Item shouldUpdate={true} label="Primary Theme Color" name={['buttonColor']} labelCol={12}>
                    <Input type="color" placeholder="buttonColor" />
                  </Form.Item>

                  <Form.Item
                    valuePropName="file"
                    name="image"
                    label="Upload Client Logo"
                    getValueFromEvent={normFile}
                    labelCol={12}
                  >
                    <Upload maxCount={1} customRequest={dummyCustomRequest} {...props}>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                      <Tooltip
                        placement="topLeft"
                        title="Recommended dimensions for image is 5:1 width:height ratio. (JPEG only)"
                        className="pt-2 pl-2"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Upload>
                  </Form.Item>
                  {form.getFieldValue('image') && (
                    <img
                      className={`${s.uploadImage} ml-5 mb-3`}
                      src={`data:image/jpg;base64,${form.getFieldValue('image')}`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = '/assets/images/notFound.png';
                        currentTarget.alt = 'Not Found';
                      }}
                      alt="Logo"
                    />
                  )}
                  <Form.Item label="Font Family" name={['fontFamily']} labelCol={12} wrapperCol={12}>
                    <Select>
                      <Option value="Apple System">Apple System</Option>
                      <Option value="Roboto">Roboto</Option>
                      <Option value="Montserrat">Montserrat</Option>
                      <Option value="Lato">Lato</Option>
                      <Option value="Poppins">Poppins</Option>
                      <Option value="Arima">Arima</Option>
                      <Option value="Noto Sans">Noto Sans</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Breadcrumbs Visible"
                    name="breadcrumbsVisible"
                    labelCol={12}
                    valuePropName="checked"
                  >
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card title="Report" className="mb-2">
              <Row justify="center">
                <Col>
                  <Form.Item
                    shouldUpdate={true}
                    label="Report Heading Text"
                    name={['reportHeadingText']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder=" Please enter report heading text " />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Report Sub heading Text"
                    name={['reportSubHeadingText']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder=" Please enter report sub heading text " />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card title="Table Properties" className="mb-2">
              <Row justify="center">
                <Col>
                  <Form.Item shouldUpdate={true} label="Header Align" name={['tableProperties', 'headerAlign']}>
                    <Select>
                      <Option value="Left">Left</Option>
                      <Option value="Right">Right</Option>
                      <Option value="Center">Center</Option>
                    </Select>
                  </Form.Item>
                  <p>Header Background Color</p>
                  <CompactPicker
                    color={colorsSelected.headerBgColor}
                    colors={[
                      '#FAFAFA',
                      '#999999',
                      '#FFFFFF',
                      '#F44E3B',
                      '#FE9200',
                      '#FCDC00',
                      '#DBDF00',
                      '#A4DD00',
                      '#68CCCA',
                      '#73D8FF',
                      '#AEA1FF',
                      '#FDA1FF',
                      '#808080',
                      '#CCCCCC',
                      '#D33115',
                      '#E27300',
                      '#FCC400',
                      '#B0BC00',
                      '#68BC00',
                      '#16A5A5',
                      '#009CE0',
                      '#7B64FF',
                      '#FA28FF',
                    ]}
                    onChange={(e) => setColorsSelected((ps) => ({ ...ps, headerBgColor: e.hex }))}
                  />
                  <p className="mt-3">Header Text Color</p>
                  <CompactPicker
                    color={colorsSelected.headerTextColor}
                    colors={[
                      '#333333',
                      '#000000',
                      '#FFFFFF',
                      '#F8F8FF',
                      '#696969',
                      '#808080',
                      '#A9A9A9',
                      '#C0C0C0',
                      '#D3D3D3',
                    ]}
                    onChange={(e) => setColorsSelected((ps) => ({ ...ps, headerTextColor: e.hex }))}
                  />
                </Col>
              </Row>
            </Card>

            <Card title="Dashboards">
              <Row justify="center">
                <Col>
                  <h1 className={s.headerTagName}>{ModuleNames.PEOPLE_TRACKING}</h1>
                  <Form.Item
                    className="mb-2"
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['dashboardNames', Pages.PEOPLE_TRACKING_DASHBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['dashboardNames', Pages.PEOPLE_TRACKING_DASHBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.VEHICLE_DIAGNOSTICS}</h1>
                  <Form.Item
                    className="mb-2"
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['dashboardNames', Pages.VEHICLE_DIAGNOSTICS_DASHBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['dashboardNames', Pages.VEHICLE_DIAGNOSTICS_DASHBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.ASSET_TRACKING}</h1>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['dashboardNames', Pages.ASSET_TRACKING_DASHBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['dashboardNames', Pages.ASSET_TRACKING_DASHBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.DEVICE_MANAGEMENT}</h1>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['dashboardNames', Pages.DEVICE_MANAGEMENT_DASHBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['dashboardNames', Pages.DEVICE_MANAGEMENT_DASHBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.SANITARY}</h1>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['dashboardNames', Pages.SANITARY_DASHBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['dashboardNames', Pages.SANITARY_DASHBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Modules">
              <Row justify="center">
                <Col>
                  <h1 className={s.headerTagName}> {ModuleNames.DIGITAL_CARPET}</h1>
                  <h3>Map Indoor {ModuleNames.INDOOR_MAP_SETUP} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.INDOOR_MAP_SETUP, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.INDOOR_MAP_SETUP, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.BRANCH} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.BRANCH, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.BRANCH, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>

                  <h3>{ModuleNames.LOCATION} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.LOCATION, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.LOCATION, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>

                  <h3>{ModuleNames.AREA} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.AREA, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.AREA, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>

                  <h3>{ModuleNames.TRANSIT_AREA} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.TRANSIT, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.TRANSIT, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.GATEWAY} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.GATEWAY, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.GATEWAY, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.TAG} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.TAG, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.TAG, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.ASSET} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.ASSET, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.ASSET, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.ONBOARD} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.ONBOARD, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.ONBOARD, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.PROVISION} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.PROVISION, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.PROVISION, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.DEVICE_CONFIGURE}</h1>
                  <h3>{ModuleNames.EVENT_ATTRIBUTE} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.EVENT_ATTRIBUTE, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.EVENT_ATTRIBUTE, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>

                  <h3>{ModuleNames.GATEWAY_CONFIG} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.GATEWAY_CONFIG, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.GATEWAY_CONFIG, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.TAG_CONFIG} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.TAG_CONFIG, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.TAG_CONFIG, 'displayName']}
                    rules={[
                      {
                        whitespace: true,

                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}>{ModuleNames.USER_MANAGEMENT}</h1>
                  <h3>{ModuleNames.ACCOUNT} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.ACCOUNT, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.ACCOUNT, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.TENANT} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.TENANT, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.TENANT, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.RBAC} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.RBAC, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.RBAC, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h1 className={s.headerTagName}> {ModuleNames.MASTERS}</h1>
                  <h3>{ModuleNames.VENDOR} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.VENDOR, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.VENDOR, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.WORKER} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.WORKER, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.WORKER, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.VEHICLE} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.VEHICLE, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.VEHICLE, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.SANITARY} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.WASHROOM, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.WASHROOM, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.ECU} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.ECU, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.ECU, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.VIN} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.VIN, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.VIN, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.VEHICLE_MODEL} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.VEHICLE_MODEL, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.VEHICLE_MODEL, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                  <h3>{ModuleNames.ECU_MODEL} </h3>
                  <Form.Item
                    className="mb-2 "
                    shouldUpdate={true}
                    label="Tech Name"
                    name={['moduleNames', Pages.ECU_MODEL, 'techName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Tech name" />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={true}
                    label="Display Name"
                    name={['moduleNames', Pages.ECU_MODEL, 'displayName']}
                    rules={[
                      {
                        whitespace: true,
                        min: 1,
                        max: 200,
                      },
                    ]}
                  >
                    <Input placeholder="Please Input Display name" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Form.Item wrapperCol={12}>
          <Row justify="center">
            <Col xs={6} lg={3}>
              <Button block className="commonSaveButton formButton mt-5  text-center" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
export default ApplicationDashboardVisual;
