import axios from 'axios';
import * as config from './config';

const instance = axios.create({
  baseURL: config.REACT_APP_SPIDEX_API_BASE_URL_ALTERNATE,
});
instance.defaults.headers['Content-Type'] = 'application/api.spidex.v1+json';
instance.defaults.headers.common['Accept'] = 'application/api.spidex.v1+json';
instance.interceptors.request.use(
  (req) => {
    const bearerToken = localStorage.getItem('token') || null;
    if (bearerToken) {
      req.headers.Authorization = `Bearer ${bearerToken}`;
    }
    req.data = req.data || {};
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
