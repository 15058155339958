import React, { useEffect, useRef, useState, useContext } from 'react';
import Sunburst from 'sunburst-chart';
import moment from 'moment';
import { EyeTwoTone } from '@ant-design/icons';
import { Loader } from '@googlemaps/js-api-loader';
import {
  getAllAreas,
  getAllBranches,
  getAllGatewayConfigs,
  getAllGateways,
  getAllLocations,
  getTenant,
  getSensorInitialData,
  getTempSensorInitialData,
  getHumiditySensorInitialData,
  getAmblightSensorInitialData,
  getPressureSensorInitialData,
  getGatewaySensorInitialData,
  getTempSensorMetrics,
  getHumiditySensorMetrics,
  getAmblightSensorMetrics,
  getPressureSensorMetrics,
  getSensorHealthByDate,
  getTempSensorByDate,
  getPressureSensorByDate,
  getHumidSensorByDate,
  getAmblSensorByDate,
} from '../../../services';
import * as config from '../../../config';
import Context from '../../../context';
import { BreadcrumbList, PermissionContainer } from '../../../shared';
import { LineChart, Line, XAxis, YAxis, Tooltip, BarChart, Bar, Legend } from 'recharts';
import { CRUD, DateFormats, Pages, SensorTypes, ModuleNames } from '../../../constants';
import {
  Tag,
  Tabs,
  Button,
  Spin,
  Statistic,
  Select,
  Row,
  Col,
  message,
  Checkbox,
  DatePicker,
} from '../../../components';
import { lengthOftheBarChartLine } from '../../../utils';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import { capitalize, get } from 'lodash';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import s from './index.module.less';

const { TabPane } = Tabs;
const { Option } = Select;

const DeviceManagement = () => {
  const [context, setContext] = useContext(Context);
  const [gateways, setGateways] = useState([]);
  const [branchRes, setBranchRes] = useState([]);
  const [locationRes, setLocationRes] = useState([]);
  const [tabLocation, setTabLocation] = useState(null);
  const [locGateways, setLocGateways] = useState([]);
  const [areaRes, setAreaRes] = useState([]);
  const [gatewayRes, setGatewayRes] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [loading, setLoading] = useState();
  const [gateway, setGateway] = useState(null);
  const [gatewayGps, setGatewayGps] = useState({ latitude: 0, longitude: 0 });
  const sensorInitial = {
    [SensorTypes.CPU]: { value: 0, loading: true, selected: true, lineChart: true, lineChartData: [] },
    [SensorTypes.INBOUND]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.OUTBOUND]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.DISK]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.MEMORY]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.BATTERY]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.HEARTBEAT]: { value: 0, loading: true, selected: false, lineChart: false, lineChartData: [] },
    [SensorTypes.APPSTARTTIME]: { value: 0, loading: true, selected: false, lineChart: false, lineChartData: [] },
    [SensorTypes.GATEWAYUPTIME]: { value: 0, loading: true, selected: false, lineChart: false, lineChartData: [] },
    [SensorTypes.IPADDRESSETH0]: { value: 0, loading: true, selected: false, lineChart: false, lineChartData: [] },
    [SensorTypes.IPADDRESSWLAN0]: { value: 0, loading: true, selected: false, lineChart: false, lineChartData: [] },
    [SensorTypes.TEMPERATURE]: { value: 0, loading: true, selected: true, lineChart: true, lineChartData: [] },
    [SensorTypes.HUMIDITY]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.AMBLIGHT]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.PROXIMITY]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
    [SensorTypes.PRESSURE]: { value: 0, loading: true, selected: false, lineChart: true, lineChartData: [] },
  };
  const [sensorData, setSensorData] = useState({ 0: { ...sensorInitial } });
  const sensorMetricInitial = {
    max: { value: 0, loading: false },
    min: { value: 0, loading: false },
    avg: { value: 0, loading: false },
  };
  const [selectedSensorMetrics, setSelectedSensorMetrics] = useState({ ...sensorMetricInitial });
  const chart = useRef(Sunburst());
  const chartDOM = useRef();
  const [gatewayInactive, setGatewayInactive] = useState(false);
  const [lineChartHealthTitle, setLineChartHealthTitle] = useState(SensorTypes.CPU);
  const [lineChartSensorTitle, setLineChartSensorTitle] = useState(SensorTypes.TEMPERATURE);
  const [dataTemp, setDataTemp] = useState(sensorData[gateway?.id || 0][SensorTypes.CPU]?.lineChartData || []);
  const [dataTempForDateRange, setDataTempForDateRange] = useState([]);
  const [dataTempForTempSensor, setDataTempForTempSensor] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const stompClient = useRef(null);
  const subs = useRef([]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const gatewayNav = gateways.find((x) => x.id === params.gateway);
    if (params.gateway && gatewayNav?.id) {
      tabClickLocation(gatewayNav.locId);
      setGateway(gatewayNav);
    }
    // eslint-disable-next-line
  }, [gateways]);

  const colors = {
    TENANT: '#FF6384',
    TENANT_STOKE: '#FF4970',
    BRANCH: '#38A2EB',
    BRANCH_DELETED: '#92BFDE',
    BRANCH_STROKE: '#2197E9',
    LOCATION: '#FFA004',
    LOCATION_DELETED: '#9FCFCF',
    LOCATION_STROKE: '#EA9100',
    AREA: '#FFCC56',
    AREA_DELETED: '#FFE8B3',
    AREA_STROKE: '#FFC43D',
    GATEWAY: '#4DBFC0',
    GATEWAY_DELETED: '#FFC466',
    GATEWAY_STROKE: '#40B3B4',
  };

  useEffect(() => {
    const contextData = async () => {
      try {
        setLoading(true);
        await getAllBranches(context.profile.tenantId).then((res) => {
          setBranchRes(res);
        });
        await getAllLocations(context.profile.tenantId).then((res) => {
          setLocationRes(res);
        });
        await getAllAreas(context.profile.tenantId).then((res) => {
          setAreaRes(res);
        });
        await getAllGateways(context.profile.tenantId).then((res) => {
          setGatewayRes(res);
        });
        await getTenant(context.profile.tenantId).then((res) => {
          setTenant(res);
        });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    contextData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading === false) {
      setContext((state) => {
        return {
          ...state,
          isLoading: true,
        };
      });
      const init = async () => {
        try {
          const dataFormatted = {
            name: tenant.data.name,
            color: colors.TENANT,
            stroke: colors.TENANT_STOKE,
            children: [],
            type: 'root',
          };
          branchRes.data
            .filter((x) => (gatewayInactive ? x : !x.deleted))
            .forEach((b) => {
              dataFormatted.children.push({
                name: b.name,
                id: b.id,
                color: b.deleted ? colors.BRANCH_DELETED : colors.BRANCH,
                stroke: colors.BRANCH_STROKE,
                type: 'branch',
                value: 1,
                children: [],
              });
            });
          locationRes.data
            .filter((x) => (gatewayInactive ? x : !x.deleted))
            .forEach((l) => {
              const branch = dataFormatted.children.find((b) => b.id === l.branchId);
              if (branch) {
                branch.children.push({
                  name: l.name,
                  id: l.id,
                  color: l.deleted ? colors.LOCATION_DELETED : colors.LOCATION,
                  stroke: colors.LOCATION_STROKE,
                  type: 'location',
                  value: 1,
                  children: [],
                });
              }
            });
          areaRes.data
            .filter((x) => (gatewayInactive ? x : !x.deleted))
            .forEach((a) => {
              dataFormatted.children.forEach((b) => {
                const location = b.children.find((l) => l.id === a.locationId);
                if (location) {
                  location.children.push({
                    name: a.name,
                    id: a.id,
                    color: a.deleted ? colors.AREA_DELETED : colors.AREA,
                    stroke: colors.AREA_STROKE,
                    type: 'area',
                    value: 1,
                    children: [],
                  });
                }
              });
            });
          gatewayRes.data
            .filter((x) => (gatewayInactive ? x : !x.deleted))
            .forEach((g) => {
              if (g.categoryType === 'transit' && g.locId) {
                dataFormatted.children.forEach((b) => {
                  const location = b.children.find((l) => l.id === g.locId);
                  if (location) {
                    location.children.push({
                      name: g.name,
                      id: g.id,
                      color: colors.GATEWAY,
                      stroke: colors.GATEWAY_STROKE,
                      type: 'gateway',
                      value: 1,
                      children: [],
                    });
                  }
                });
              } else {
                dataFormatted.children.forEach((b) => {
                  const location = b.children.find((l) => l.id === g.locId);
                  if (location) {
                    const area = location.children.find((a) => a.id === g.areaId);
                    if (area) {
                      area.children.push({
                        name: g.name,
                        id: g.id,
                        color: colors.GATEWAY,
                        stroke: colors.GATEWAY_STROKE,
                        type: 'gateway',
                        value: 1,
                      });
                    }
                  }
                });
              }
            });
          const deviceRes = await getAllGatewayConfigs(context.profile.tenantId);
          const tempData = {};
          gatewayRes.data.forEach((g) => {
            tempData[g.id] = { ...sensorInitial };
            g.modelName = deviceRes.data.find((x) => x.modelId === g.modelId)?.modelName || '';
          });
          setSensorData((state) => ({ ...state, ...tempData }));
          chartDOM.current.innerHTML = '';
          chart.current.data(dataFormatted).color('color').strokeColor('stroke')(chartDOM.current);

          setGateways(gatewayRes.data.filter((gw) => gw.deleted === false));
        } catch (e) {
          console.log(e);
          message.error('Unable to fetch data, try again later.');
        } finally {
          setContext((state) => {
            return {
              ...state,
              isLoading: false,
            };
          });
        }
      };
      init();
    }
    // eslint-disable-next-line
  }, [gatewayInactive, loading]);

  useEffect(() => {
    const tempSub = subs.current;
    return () => {
      tempSub.forEach((sub) => {
        try {
          sub?.unsubscribe();
        } catch (e) {
          console.log(e);
        }
      });
      stompClient?.current?.disconnect();
    };
  }, [isEmpty]);

  const healthSensors = [
    SensorTypes.CPU,
    SensorTypes.MEMORY,
    SensorTypes.DISK,
    SensorTypes.INBOUND,
    SensorTypes.OUTBOUND,
    SensorTypes.BATTERY,
    SensorTypes.HEARTBEAT,
    SensorTypes.APPSTARTTIME,
    SensorTypes.GATEWAYUPTIME,
    SensorTypes.IPADDRESSETH0,
    SensorTypes.IPADDRESSWLAN0,
  ];
  const commonSensors = [SensorTypes.TEMPERATURE, SensorTypes.HUMIDITY, SensorTypes.AMBLIGHT, SensorTypes.PRESSURE];

  const onChangeSensorTabs = (e) => {
    setDataTempForDateRange([]);
    setDataTempForTempSensor([]);
    if (e === '3') {
      webSocketData(gateway, commonSensors);
      setDataTemp(sensorData[gateway?.id || 0][SensorTypes.TEMPERATURE]?.lineChartData || []);
      onClickSensorButton(SensorTypes.TEMPERATURE);
      setLineChartSensorTitle('Temperature');
    } else {
      webSocketData(gateway, healthSensors);
      setDataTemp(sensorData[gateway?.id || 0][SensorTypes.CPU]?.lineChartData || []);
      onClickHealthSensorButton(SensorTypes.CPU);
      setLineChartHealthTitle('CPU');
    }
  };

  useEffect(() => {
    if (gateway) onChangeSensorTabs('1');
    // eslint-disable-next-line
  }, [gateway]);

  useEffect(() => {
    if (!gateway?.id || !context?.profile.tenantId) return;
    const tenantId = context.profile.tenantId;
    let sensorPromises = [
      getAmblightSensorInitialData(tenantId, gateway.id),
      getTempSensorInitialData(tenantId, gateway.id),
      getHumiditySensorInitialData(tenantId, gateway.id),
      getPressureSensorInitialData(tenantId, gateway.id),
    ];
    for (let sensor of healthSensors) {
      sensorPromises.push(getSensorInitialData(tenantId, gateway.id, sensor));
    }
    Promise.allSettled(sensorPromises)
      .then((res) => {
        const formatValue = (data, attributeType) => {
          if(!data)
          {
            return 0;
          }
          if(attributeType === 'float')
          {
            return parseFloat(data.attributeValue).toFixed(2);
          }
          else if(attributeType === 'long')
          {
            return moment( parseInt(data.attributeValue, 10) * 1000)
            .format(DateFormats.TYPE_2)
            .toString();
          }
          else
          {
            return data.attributeValue;
          }
        };
        setSensorData((state) => {
          const temp = { ...state };
          temp[gateway.id] = {
            [SensorTypes.CPU]: {
              value: formatValue(res[4].value.data, 'float'),
              loading: false,
              selected: true,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.INBOUND]: {
              value: formatValue(res[7].value.data, 'float'),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.OUTBOUND]: {
              value: formatValue(res[8].value.data, 'float'),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.DISK]: {
              value: formatValue(res[6].value.data, 'float'),
              loading: false,
              seected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.MEMORY]: {
              value: formatValue(res[5].value.data, 'float'),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.BATTERY]: {
              value: 0,
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.HEARTBEAT]: {
              value: formatValue(res[10] !== undefined ? res[10].value.data : 0, 'long'),
              loading: false,
              selected: false,
              lineChart: false,
              lineChartData: [],
            },
            [SensorTypes.APPSTARTTIME]: {
              value: formatValue(res[11] !== undefined ? res[11].value.data : 0, 'long'),
              loading: false,
              selected: false,
              lineChart: false,
              lineChartData: [],
            },
            [SensorTypes.GATEWAYUPTIME]: {
              value: formatValue(res[12] !== undefined ? res[12].value.data : 0, 'long'),
              loading: false,
              selected: false,
              lineChart: false,
              lineChartData: [],
            },
            [SensorTypes.IPADDRESSETH0]: {
              value: formatValue(res[13] !== undefined ? res[13].value.data : 0, 'string'),
              loading: false,
              selected: false,
              lineChart: false,
              lineChartData: [],
            },
            [SensorTypes.IPADDRESSWLAN0]: {
              value: formatValue(res[14] !== undefined ? res[14].value.data : 0, 'string'),
              loading: false,
              selected: false,
              lineChart: false,
              lineChartData: [],
            },
            [SensorTypes.TEMPERATURE]: {
              value: formatValue(res[1].value.data),
              loading: false,
              selected: true,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.HUMIDITY]: {
              value: formatValue(res[2].value.data),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.AMBLIGHT]: {
              value: formatValue(res[0].value.data),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.PROXIMITY]: {
              value: 0,
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
            [SensorTypes.PRESSURE]: {
              value: formatValue(res[3].value.data),
              loading: false,
              selected: false,
              lineChart: true,
              lineChartData: [],
            },
          };
          return temp;
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [gateway]);

  useEffect(() => {
    const init = async () => {
      if (!gateway?.id || !context?.profile.tenantId) return;
      const tenantId = context.profile.tenantId;
      setSelectedSensorMetrics({
        avg: { value: 0, loading: true },
        min: { value: 0, loading: true },
        max: { value: 0, loading: true },
      });
      try {
        let title = '';
        if (lineChartHealthTitle === 'Inbound') {
          title = SensorTypes.INBOUND;
        } else if (lineChartHealthTitle === 'Outbound') {
          title = SensorTypes.OUTBOUND;
        } else if (lineChartHealthTitle === 'CPU') {
          title = SensorTypes.CPU;
        } else if (lineChartHealthTitle === 'Memory') {
          title = SensorTypes.MEMORY;
        } else if (lineChartHealthTitle === 'Disk') {
          title = SensorTypes.DISK;
        }
        const res = await getGatewaySensorInitialData(tenantId, gateway.id, title);
        const data = res.data;
        setSelectedSensorMetrics({
          avg: { value: data.avg?.toFixed(2), loading: false },
          min: { value: data.min?.toFixed(2), loading: false },
          max: { value: data.max?.toFixed(2), loading: false },
        });
      } catch (e) {
        console.log(e);
      }
    };
    init();
    // eslint-disable-next-line
  }, [lineChartHealthTitle]);

  useEffect(() => {
    const init = async () => {
      const tenantId = context.profile?.tenantId;
      if (!gateway?.id || !tenantId) return;
      let promiseToCall = null;
      const sensorToCall = lineChartSensorTitle.toLowerCase();
      if (sensorToCall === SensorTypes.AMBLIGHT.toLowerCase()) {
        promiseToCall = getAmblightSensorMetrics;
      }
      if (sensorToCall === SensorTypes.TEMPERATURE.toLowerCase()) {
        promiseToCall = getTempSensorMetrics;
      }
      if (sensorToCall === SensorTypes.HUMIDITY.toLowerCase()) {
        promiseToCall = getHumiditySensorMetrics;
      }
      if (sensorToCall === SensorTypes.PRESSURE.toLowerCase()) {
        promiseToCall = getPressureSensorMetrics;
      }
      setSelectedSensorMetrics({
        avg: { value: 0, loading: true },
        min: { value: 0, loading: true },
        max: { value: 0, loading: true },
      });
      try {
        const res = await promiseToCall(tenantId, gateway.id);
        const data = res.data;
        setSelectedSensorMetrics({
          avg: { value: data.avg?.toFixed(2), loading: false },
          min: { value: data.min?.toFixed(2), loading: false },
          max: { value: data.max?.toFixed(2), loading: false },
        });
      } catch (e) {
        console.log(e);
      }
    };
    init();
    // eslint-disable-next-line
  }, [lineChartSensorTitle]);

  const webSocketData = (gateway, sensors) => {
    if (stompClient.current?.connected) {
      const tempSub = subs.current;
      tempSub.forEach((sub) => {
        try {
          sub?.unsubscribe();
        } catch (e) {
          console.log(e);
        }
      });
      stompClient?.current?.disconnect();
    }
    if (gateway?.id) {
      let socket = new SockJS(`${config.REACT_APP_SPIDEX_SOCKET_URI}/spdxsocket`);
      stompClient.current = Stomp.over(socket);
      stompClient.current.connect({}, () => {
        for (let sensor in sensors) {
          const sub = stompClient.current.subscribe(`/data/${sensors[sensor]}/${gateway.id}`, (message) => {
            const jsonData = JSON.parse(message.body);
            setSensorData((state) => {
              const tempState = { ...state };
              if(jsonData.attributeType === 'float')
              {
                tempState[gateway.id][sensors[sensor]].value = parseFloat(jsonData.attributeValue).toFixed(2);
              }
              else if(jsonData.attributeType === 'long')
              {
                tempState[gateway.id][sensors[sensor]].value = moment( parseInt(jsonData.attributeValues[0], 10) * 1000)
                .format(DateFormats.TYPE_2)
                .toString();
              }
              else
              {
                tempState[gateway.id][sensors[sensor]].value = jsonData.attributeValues[0] || 'NA';
              }
              tempState[gateway.id][sensors[sensor]].loading = false;
              tempState[gateway.id][sensors[sensor]].date = moment(jsonData.eventTime * 1000)
                .format(DateFormats.TYPE_4)
                .toString();
              if(tempState[gateway.id][sensors[sensor]].lineChart)
              {
                tempState[gateway.id][sensors[sensor]].lineChartData = [
                  ...tempState[gateway.id][sensors[sensor]].lineChartData,
                  {
                    value:
                      jsonData.attributeType === 'float'
                        ? parseFloat(jsonData.attributeValue).toFixed(2)
                        : jsonData.attributeValue || 'NA',
                    time: moment(jsonData.eventTime * 1000)
                      .format(DateFormats.TYPE_5)
                      .toString(),
                  },
                ];
              }
              return tempState;
            });
          });
          subs.current.push(sub);
        }
      });

      const gw = gateways.find((x) => x.id === gateway.id);
      if (gw) {
        if (gw.locId && gw.categoryType === 'transit') {
          setGatewayGps({ ...(locationRes.data.find((x) => x.id === gw.locId)?.gpsPoint || gatewayGps) });
        }
        if (gw.areaId && gw.categoryType !== 'transit') {
          setGatewayGps({ ...(areaRes.data.find((x) => x.id === gw.areaId)?.gpsPoint || gatewayGps) });
        }
      }
    }
  };

  const tabClickLocation = (e) => {
    const tempTabLoc = locationRes.data.find((loc) => loc.id === e);
    setTabLocation(tempTabLoc);
    const tempLocGateways = gateways.filter((x) => x.locId === e);
    setLocGateways(tempLocGateways);
    setGateway(tempLocGateways[0]);
  };

  const selectedGateway = (gateway) => {
    if (gateway) {
      const gatewayRes = locGateways.find((x) => x.id === gateway);
      setGateway(gatewayRes);
    } else {
      setIsEmpty(true);
    }
  };

  const mapLoaderRef = useRef(null);
  useEffect(() => {
    const loader = new Loader({
      apiKey: config.REACT_APP_MAP_GOOGLE_API_KEY,
      libraries: ['places'],
      version: 'weekly',
    });
    mapLoaderRef.current = loader;
  }, []);

  useEffect(() => {
    if (gateway) {
      mapLoaderRef.current?.load().then((google) => {
        const pos = { lat: parseFloat(gatewayGps.latitude), lng: parseFloat(gatewayGps.longitude) };
        const element = document.getElementById(`google-map-${tabLocation?.id}-${gateway?.id}`);
        if (!element) return;
        let map = new google.maps.Map(element, {
          center: pos,
          zoom: 10,
        });
        new google.maps.Marker({
          position: pos,
          map,
        });
      });
    }
    // eslint-disable-next-line
  }, [gatewayGps]);

  const [selectedSensor, setSelectedSensor] = useState(SensorTypes.CPU);
  const healthSensorBtnClick = (sensor) => {
    setDataTempForDateRange([]);
    setDataTempForTempSensor([]);
    setSelectedSensor(sensor);
    if (sensor === SensorTypes.INBOUND) {
      setLineChartHealthTitle('Inbound');
    } else if (sensor === SensorTypes.OUTBOUND) {
      setLineChartHealthTitle('Outbound');
    } else if (sensor === SensorTypes.CPU) {
      setLineChartHealthTitle('CPU');
    } else if (sensor === SensorTypes.MEMORY) {
      setLineChartHealthTitle('Memory');
    } else if (sensor === SensorTypes.DISK) {
      setLineChartHealthTitle('Disk');
    }
  };

  useEffect(() => {
    const list = sensorData[gateway?.id || 0][selectedSensor]?.lineChartData;
    list.length >= 101 && list.shift();
    setDataTemp(list);
    // eslint-disable-next-line
  }, [sensorData]);

  const sensorBtnClick = (sensor) => {
    setDataTempForDateRange([]);
    setDataTempForTempSensor([]);
    setSelectedSensor(sensor);
    setLineChartSensorTitle(capitalize(sensor));
  };

  const onClickHealthSensorButton = (sensor) => {
    setDataTempForDateRange([]);
    setDataTempForTempSensor([]);
    setSensorData((state) => ({
      ...state,
      [gateway?.id || 0]: {
        ...state[gateway?.id || 0],
        [SensorTypes.CPU]: { ...state[gateway?.id || 0][SensorTypes.CPU], selected: sensor === SensorTypes.CPU },
        [SensorTypes.INBOUND]: {
          ...state[gateway?.id || 0][SensorTypes.INBOUND],
          selected: sensor === SensorTypes.INBOUND,
        },
        [SensorTypes.OUTBOUND]: {
          ...state[gateway?.id || 0][SensorTypes.OUTBOUND],
          selected: sensor === SensorTypes.OUTBOUND,
        },
        [SensorTypes.DISK]: { ...state[gateway?.id || 0][SensorTypes.DISK], selected: sensor === SensorTypes.DISK },
        [SensorTypes.MEMORY]: {
          ...state[gateway?.id || 0][SensorTypes.MEMORY],
          selected: sensor === SensorTypes.MEMORY,
        },
      },
    }));
    healthSensorBtnClick(sensor);
  };

  const onClickSensorButton = (sensor) => {
    setDataTempForDateRange([]);
    setDataTempForTempSensor([]);
    setSensorData((state) => ({
      ...state,
      [gateway?.id || 0]: {
        ...state[gateway?.id || 0],
        [SensorTypes.TEMPERATURE]: {
          ...state[gateway?.id || 0][SensorTypes.TEMPERATURE],
          selected: sensor === SensorTypes.TEMPERATURE,
        },
        [SensorTypes.HUMIDITY]: {
          ...state[gateway?.id || 0][SensorTypes.HUMIDITY],
          selected: sensor === SensorTypes.HUMIDITY,
        },
        [SensorTypes.AMBLIGHT]: {
          ...state[gateway?.id || 0][SensorTypes.AMBLIGHT],
          selected: sensor === SensorTypes.AMBLIGHT,
        },
        [SensorTypes.PRESSURE]: {
          ...state[gateway?.id || 0][SensorTypes.PRESSURE],
          selected: sensor === SensorTypes.PRESSURE,
        },
      },
    }));
    sensorBtnClick(sensor);
  };

  const [dateRangeInitialValues, setDateRangeInitialValues] = useState([]);
  //For healthsensors date picker wise graph
  const onDateChange = (_, date) => {
    try {
      if (!date[0] && !date[1]) return;
    } catch (e) {
      return;
    }
    setDateRangeInitialValues([moment(date[0], DateFormats.TYPE_7), moment(date[1], DateFormats.TYPE_7)]);
  };

  useEffect(() => {
    setDateRangeInitialValues([moment().subtract(5, 'hours'), moment()]);
  }, [gateway, selectedSensor]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!dateRangeInitialValues[0] && !dateRangeInitialValues[1]) return;
      } catch (e) {
        return;
      }
      const fromDate = dateRangeInitialValues[0].format(DateFormats.TYPE_7);
      const toDate = dateRangeInitialValues[1].format(DateFormats.TYPE_7);
      if (!gateway?.id || !context?.profile.tenantId) return;
      const tenantId = context.profile.tenantId;
      try {
        let title = '';
        if (lineChartHealthTitle === 'Inbound') {
          title = SensorTypes.INBOUND;
        } else if (lineChartHealthTitle === 'Outbound') {
          title = SensorTypes.OUTBOUND;
        } else if (lineChartHealthTitle === 'CPU') {
          title = SensorTypes.CPU;
        } else if (lineChartHealthTitle === 'Memory') {
          title = SensorTypes.MEMORY;
        } else if (lineChartHealthTitle === 'Disk') {
          title = SensorTypes.DISK;
        }
        const res = await getSensorHealthByDate(tenantId, title, toDate, fromDate, gateway.id);
        const data = res.data;
        data.forEach((d) => {
          d.time = new Date(d.reportedTime * 1000).toLocaleTimeString();
        });
        setDataTempForDateRange(data);
      } catch (e) {
        console.log(e);
        return;
      }
    };
    init();
    // eslint-disable-next-line
  }, [dateRangeInitialValues]);
  //clearing datepicker wise grapgh data
  const clearData = () => {
    setDataTempForDateRange([]);
  };

  const [dateTempRangeInitialValues, setDateTempRangeInitialValues] = useState([]);
  //Temperature,Ambilight,humidity,pressure Sensor data
  const onDateChangeForSensors = (_, date) => {
    try {
      if (!date[0] && !date[1]) return;
    } catch (e) {
      return;
    }
    setDateTempRangeInitialValues([moment(date[0], DateFormats.TYPE_7), moment(date[1], DateFormats.TYPE_7)]);
  };

  useEffect(() => {
    setDateTempRangeInitialValues([moment().subtract(5, 'hours'), moment()]);
    // eslint-disable-next-line
  }, [gateway, selectedSensor]);

  useEffect(() => {
    const init = async () => {
      try {
        if (!dateTempRangeInitialValues[0] && !dateTempRangeInitialValues[1]) return;
      } catch (e) {
        return;
      }
      const fromDate = dateTempRangeInitialValues[0].format(DateFormats.TYPE_7);
      const toDate = dateTempRangeInitialValues[1].format(DateFormats.TYPE_7);
      const tenantId = context.profile?.tenantId;
      if (!gateway?.id || !tenantId) return;
      let promiseToCall = null;
      const sensorToCall = lineChartSensorTitle.toLowerCase();
      if (sensorToCall === SensorTypes.AMBLIGHT.toLowerCase()) {
        promiseToCall = getAmblSensorByDate;
      }
      if (sensorToCall === SensorTypes.TEMPERATURE.toLowerCase()) {
        promiseToCall = getTempSensorByDate;
      }
      if (sensorToCall === SensorTypes.HUMIDITY.toLowerCase()) {
        promiseToCall = getHumidSensorByDate;
      }
      if (sensorToCall === SensorTypes.PRESSURE.toLowerCase()) {
        promiseToCall = getPressureSensorByDate;
      }
      try {
        const res = await promiseToCall(tenantId, toDate, fromDate, gateway.id);
        const data = res.data;
        data.forEach((d) => {
          d.time = new Date(d.reportedTime * 1000).toLocaleTimeString();
        });
        setDataTempForTempSensor(data);
      } catch (e) {
        console.log(e);
        return;
      }
    };
    init();
    // eslint-disable-next-line
  }, [dateTempRangeInitialValues]);

  const clearTempSensorData = () => {
    setDataTempForTempSensor([]);
  };

  const sensorIconStyles = { height: 20, width: 20, marginRight: 10 };

  const deviceManagementBreadcrumbsName = get(
    context.tenantProfile,
    `dashboardNames[${Pages.DEVICE_MANAGEMENT_DASHBOARD}].displayName`,
    ModuleNames.DEVICE_MANAGEMENT
  );

  return (
    <>
      <Row justify="space-between">
        <Col>
          <BreadcrumbList list={[deviceManagementBreadcrumbsName]} />
        </Col>
        <Col>
          <Row justify="start">
            <Col className={s.dashboardCheckbox}>
              <Checkbox onChange={() => setGatewayInactive(!gatewayInactive)}>Inactive</Checkbox>
            </Col>
            {!context.isCompact && <Col>Key:&nbsp;</Col>}
            <Col>
              <Row>
                <Col>
                  <Tag color={`${colors.TENANT}`}>Tenant</Tag>
                </Col>
                <Col>
                  <Tag color={`${colors.BRANCH}`}>Branch</Tag>
                </Col>
                <Col>
                  <Tag color={`${colors.LOCATION}`}>Location</Tag>
                </Col>
                <Col>
                  <Tag color={`${colors.AREA}`}>Area</Tag>
                </Col>
                <Col>
                  <Tag color={`${colors.GATEWAY}`}>Gateway</Tag>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={s.burstArea} justify="center" align="middle" gutter={[4, 4]}>
        <Col xs={24} lg={24}>
          <Tabs
            tabBarStyle={{ color: '#FFA004' }}
            defaultActiveKey="overview"
            activeKey={tabLocation ? tabLocation.id : 'overview'}
            onChange={(e) => tabClickLocation(e === 'overview' ? null : e)}
          >
            <TabPane tab="Overview" key="overview">
              <div className={s.burstDiv} ref={chartDOM}></div>
            </TabPane>
            {locationRes.data
              ?.filter((x) => (gatewayInactive ? x : !x.deleted))
              .map((g) => (
                <TabPane tab={g.name} key={g.id}>
                  <Row justify="center" className={s.mapView}>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col xs={{ span: 24 }} md={{ span: 8 }} className={s.data}>
                          <Statistic title="Name" value={g?.name} />
                          {locGateways.length > 0 ? (
                            <Select
                              className={s.gatewaySelection}
                              defaultValue={gateway.id || locGateways[0]?.id}
                              onChange={selectedGateway}
                              allowClear={false}
                            >
                              {locGateways.map((locG) => (
                                <Option key={locG.id} value={locG.id}>
                                  {locG.name}
                                </Option>
                              ))}
                            </Select>
                          ) : (
                            <h3>No gateway for this Location!!</h3>
                          )}
                          <Statistic title="MAC ID" value={gateway?.externalId} />
                          <Statistic title="Model" value={gateway?.modelName} />
                          <br />
                          <PermissionContainer page={Pages.ASSET_TRACKING_PAGE} permission={CRUD.VIEW}>
                            <Button>
                              <Link to={`/asset-tracking?gateway=${gateway?.id}`}> View Assets </Link>
                            </Button>
                          </PermissionContainer>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 16 }}>
                          <div className={s.map} id={`google-map-${g.id}-${gateway?.id}`}></div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey="1" centered type="card" className={s.sensorTab} onChange={onChangeSensorTabs}>
                    <TabPane tab="Info" key="1">
                      <Row>
                        <Col span={8} offset={2}>
                          <Statistic title="Heart Beat" value={sensorData[gateway?.id || 0][SensorTypes.HEARTBEAT]?.value || 0} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} offset={2}>
                          <Statistic title="App Start Time" value={sensorData[gateway?.id || 0][SensorTypes.APPSTARTTIME]?.value || 0} />
                        </Col>
                        <Col span={8} offset={2}>
                          <Statistic title="Gateway Up Time" value={sensorData[gateway?.id || 0][SensorTypes.GATEWAYUPTIME]?.value || 0} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} offset={2}>
                          <Statistic title="Ip Address Eth0" value={sensorData[gateway?.id || 0][SensorTypes.IPADDRESSETH0]?.value || 0} />
                        </Col>
                        <Col span={8} offset={2}>
                          <Statistic title="Ip Address Wlan0" value={sensorData[gateway?.id || 0][SensorTypes.IPADDRESSWLAN0]?.value || 0} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Health Attributes" key="2">
                      <Row justify="space-around" align="middle" gutter={[4, 16]} className="mt-5">
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/inbound.svg" alt="Inbound" style={{ ...sensorIconStyles }} />{' '}
                            Inbound
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickHealthSensorButton(SensorTypes.INBOUND)}
                              type={sensorData[gateway?.id || 0][SensorTypes.INBOUND]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.INBOUND]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.INBOUND]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/outbound.svg" alt="Outbound" style={{ ...sensorIconStyles }} />{' '}
                            Outbound
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickHealthSensorButton(SensorTypes.OUTBOUND)}
                              type={sensorData[gateway?.id || 0][SensorTypes.OUTBOUND]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.OUTBOUND]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.OUTBOUND]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/cpu.svg" alt="CPU" style={{ ...sensorIconStyles }} /> CPU
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickHealthSensorButton(SensorTypes.CPU)}
                              type={sensorData[gateway?.id || 0][SensorTypes.CPU]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.CPU]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.CPU]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/memory.svg" alt="Memory" style={{ ...sensorIconStyles }} /> Memory
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickHealthSensorButton(SensorTypes.MEMORY)}
                              type={sensorData[gateway?.id || 0][SensorTypes.MEMORY]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.MEMORY]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.MEMORY]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/disk.svg" alt="Disk" style={{ ...sensorIconStyles }} /> Disk
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickHealthSensorButton(SensorTypes.DISK)}
                              type={sensorData[gateway?.id || 0][SensorTypes.DISK]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.DISK]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.DISK]?.value || 0} />
                          </Spin>
                        </Col>
                      </Row>
                      <Row justify="center" align="middle" className="graphContainer p-3">
                        <Col span={2} offset={1}>
                          <div className="d-flex align-items-center">
                            <Row>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.min.loading}>
                                  <Statistic title="Min" value={selectedSensorMetrics.min.value} />
                                </Spin>
                              </Col>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.max.loading}>
                                  <Statistic title="Max" value={selectedSensorMetrics.max.value} />
                                </Spin>
                              </Col>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.avg.loading}>
                                  <Statistic title="Avg" value={selectedSensorMetrics.avg.value} />
                                </Spin>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} lg={{ span: 19, offset: 1 }}>
                          <Row justify="start" gutter={16}>
                            <Col className="ml-5">
                              <h3>
                                Timeline <Tag color="default">{lineChartHealthTitle}</Tag>
                              </h3>
                            </Col>
                            <Col>
                              <h3>
                                Date
                                <Tag color="default" className="ml-1">
                                  {sensorData[gateway?.id || 0][selectedSensor]?.date || '---'}
                                </Tag>
                              </h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="lineChartContainer">
                                <LineChart
                                  width={context.isCompact ? 800 : document.body.clientWidth * 0.6}
                                  height={240}
                                  data={dataTemp}
                                  margin={{ bottom: 40, right: 40, top: 20 }}
                                  textAnchor="end"
                                >
                                  <XAxis dataKey="time" angle={35} interval="preserveEnd" dy={20} dx={15} />
                                  <YAxis interval="preserveEnd" />
                                  <Tooltip />
                                  <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#3e98c7"
                                    activeDot={{ r: 8 }}
                                  />
                                </LineChart>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="start" align="middle" className="graphContainer p-3">
                        <Col xs={24} lg={{ span: 22, offset: 1 }}>
                          <Row justify="start" gutter={16}>
                            <Col className="ml-5">
                              <DatePicker.RangePicker
                                showTime={{
                                  format: 'HH:mm',
                                }}
                                format={DateFormats.TYPE_7}
                                onChange={onDateChange}
                                defaultValue={dateRangeInitialValues}
                              />{' '}
                              <Button type="link" danger onClick={clearData}>
                                Clear
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="lineChartContainer">
                                <BarChart
                                  width={lengthOftheBarChartLine(dataTempForDateRange)}
                                  height={240}
                                  data={dataTempForDateRange}
                                  margin={{ bottom: 40, right: 40, top: 20 }}
                                  textAnchor="end"
                                >
                                  <XAxis dataKey="time" angle={55} interval="preserveEnd" dy={20} dx={15} />
                                  <YAxis interval="preserveEnd" />
                                  <Tooltip cursor={{ fill: 'transparent' }} />
                                  <Legend layout="vertical" align="left" verticalAlign="top" />
                                  <Bar dataKey="min" stackId="a" fill="#8884d8" />
                                  <Bar dataKey="avg" stackId="a" fill="#82ca9d" />
                                  <Bar dataKey="max" stackId="a" fill="#3e98c7" />
                                </BarChart>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Sensors" key="3">
                      <Row justify="space-around" align="middle" gutter={[4, 16]} className="mt-5">
                        <Col>
                          <h3 className="customH3">
                            <img
                              src="/assets/icons/temperature.svg"
                              alt="Temperature"
                              style={{ ...sensorIconStyles }}
                            />{' '}
                            Temperature
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickSensorButton(SensorTypes.TEMPERATURE)}
                              type={
                                sensorData[gateway?.id || 0][SensorTypes.TEMPERATURE]?.selected ? 'outline' : 'text'
                              }
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.TEMPERATURE]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.TEMPERATURE]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/humidity.svg" alt="Humidity" style={{ ...sensorIconStyles }} />{' '}
                            Humidity
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickSensorButton(SensorTypes.HUMIDITY)}
                              type={sensorData[gateway?.id || 0][SensorTypes.HUMIDITY]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.HUMIDITY]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.HUMIDITY]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/amblight.svg" alt="Amblight" style={{ ...sensorIconStyles }} />{' '}
                            Amblight
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickSensorButton(SensorTypes.AMBLIGHT)}
                              type={sensorData[gateway?.id || 0][SensorTypes.AMBLIGHT]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.AMBLIGHT]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.AMBLIGHT]?.value || 0} />
                          </Spin>
                        </Col>
                        <Col>
                          <h3 className="customH3">
                            <img src="/assets/icons/pressure.svg" alt="Pressure" style={{ ...sensorIconStyles }} />{' '}
                            Pressure
                            <Button
                              className="buttonMargin"
                              onClick={() => onClickSensorButton(SensorTypes.PRESSURE)}
                              type={sensorData[gateway?.id || 0][SensorTypes.PRESSURE]?.selected ? 'outline' : 'text'}
                            >
                              <EyeTwoTone />
                            </Button>
                          </h3>
                          <Spin spinning={sensorData[gateway?.id || 0][SensorTypes.PRESSURE]?.loading}>
                            <Statistic value={sensorData[gateway?.id || 0][SensorTypes.PRESSURE]?.value || 0} />
                          </Spin>
                        </Col>
                      </Row>
                      <Row justify="center" className="graphContainer p-3">
                        <Col span={2} offset={1}>
                          <div className="d-flex align-items-center">
                            <Row>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.min.loading}>
                                  <Statistic title="Min" value={selectedSensorMetrics.min.value} />
                                </Spin>
                              </Col>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.max.loading}>
                                  <Statistic title="Max" value={selectedSensorMetrics.max.value} />
                                </Spin>
                              </Col>
                              <Col span={24}>
                                <Spin spinning={selectedSensorMetrics.avg.loading}>
                                  <Statistic title="Avg" value={selectedSensorMetrics.avg.value} />
                                </Spin>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={24} lg={{ span: 19, offset: 1 }}>
                          <Row justify="start" gutter={16}>
                            <Col>
                              <h3 className="ml-5">
                                Timeline <Tag color="default">{capitalize(lineChartSensorTitle)}</Tag>
                              </h3>
                            </Col>
                            <Col>
                              <h3>
                                Date
                                <Tag color="default" className="ml-1">
                                  {sensorData[gateway?.id || 0][selectedSensor]?.date || '---'}
                                </Tag>
                              </h3>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="lineChartContainer">
                                <LineChart
                                  width={context.isCompact ? 800 : document.body.clientWidth * 0.6}
                                  height={240}
                                  data={dataTemp}
                                  margin={{ bottom: 40, right: 40, top: 20 }}
                                  textAnchor="end"
                                >
                                  <XAxis dataKey="time" angle={35} interval="preserveEnd" dy={20} dx={15} />
                                  <YAxis interval="preserveEnd" />
                                  <Tooltip />
                                  <Line
                                    isAnimationActive={false}
                                    type="monotone"
                                    dataKey="value"
                                    stroke="#3e98c7"
                                    activeDot={{ r: 8 }}
                                  />
                                </LineChart>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="start" align="middle" className="graphContainer p-3">
                        <Col xs={24} lg={{ span: 22, offset: 1 }}>
                          <Row justify="start" gutter={16}>
                            <Col className="ml-5">
                              <DatePicker.RangePicker
                                showTime={{
                                  format: 'HH:mm',
                                }}
                                format={DateFormats.TYPE_7}
                                defaultValue={dateTempRangeInitialValues}
                                onChange={onDateChangeForSensors}
                              />
                              <Button type="link" danger onClick={clearTempSensorData}>
                                Clear
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="lineChartContainer">
                                <BarChart
                                  width={lengthOftheBarChartLine(dataTempForTempSensor)}
                                  height={240}
                                  data={dataTempForTempSensor}
                                  margin={{ bottom: 40, right: 40, top: 20 }}
                                  textAnchor="end"
                                >
                                  <XAxis dataKey="time" angle={55} interval="preserveEnd" dy={20} dx={15} />
                                  <YAxis interval="preserveEnd" />
                                  <Tooltip cursor={{ fill: 'transparent' }} />
                                  <Legend layout="vertical" align="left" verticalAlign="top" />
                                  <Bar dataKey="min" stackId="a" fill="#8884d8" />
                                  <Bar dataKey="avg" stackId="a" fill="#82ca9d" />
                                  <Bar dataKey="max" stackId="a" fill="#3e98c7" />
                                </BarChart>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Command" key="4">
                      <h2>Command</h2>
                    </TabPane>
                    <TabPane tab="Configure" key="5">
                      <h2>Configure</h2>
                    </TabPane>
                  </Tabs>
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default DeviceManagement;
