import { createContext } from 'react';
import jwt from 'jsonwebtoken';

const localToken = localStorage.getItem('token');
const profile = localStorage.getItem('profile');
const pagePermissions = localStorage.getItem('pagePermissions');

export const initialState = {
  isAuth: false,
  isLoading: false,
  isReset: false,
  profile: {},
  token: {},
  pagePermissions: [],
  isCompact: window.document.body.offsetWidth < 992 ? true : false,
  isDesktop: window.document.body.offsetWidth >= 992 ? true : false,
  techMode: false,
  tenantProfileLoaded: false,
  tenantProfile: {},
};

export const state = {
  ...initialState,
  isAuth: localToken ? true : false,
  isLoading: false,
  isReset: false,
  profile: profile ? JSON.parse(profile) : {},
  pagePermissions: pagePermissions ? JSON.parse(pagePermissions) : [],
  token: localToken ? jwt.decode(localToken) : {},
};

const Context = createContext(state);

export default Context;
