import { Col, Row } from 'antd';
import React from 'react';
import AnalyticsImage from '../../../assets/images/analytics.png';

const AnalyticsDashboard = () => {
  return (
    <Row justify="center">
      <Col>
        <img src={AnalyticsImage} alt="Analytics" style={{ width: '100%' }} />
      </Col>
    </Row>
  );
};

export default AnalyticsDashboard;
