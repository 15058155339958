import React from 'react';
import { Typography, Drawer } from '../../components';
import { calcDrawerWidth } from '../../utils';

const { Title } = Typography;

const Index = (props) => {
  return (
    <Drawer
      getContainer={false}
      width={calcDrawerWidth()}
      visible={props.visible}
      className="commonDrawer"
      onClose={props.closeAdd}
      bodyStyle={{ padding: 0 }}
    >
      <Title className="title"> {props.title} </Title>
      <div className="content">{props.children}</div>
    </Drawer>
  );
};

export default Index;
